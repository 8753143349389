<template>
  <div>
    <v-dialog
      v-model="oiInvoiceModel"
      :max-width="900"
      @input="closeOrderDetails"
      scrollable
      persistent
    >
      <v-card ref="recieptPrint">
        <v-card-text class="pa-0 ma-0">
          <div class="receipt_details">
            <v-row class="ma-0">
              <v-col class="text-left pt-5 pb-5" cols="7" md="7">
                <div class="invoice-title">
                  <v-btn icon class="inv-sm-circle">
                    <v-icon>mdi-bookmark-outline</v-icon>
                  </v-btn>
                  Items Listing
                  <span class="display-block"> </span>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <div class="receipt_details_body pa-3">
            <div class="pl-3 pr-3">
              <table class="new_reciept_details_item_table">
                <tr>
                  <th>#</th>
                  <th>Order#</th>
                  <th>Customer Name</th>
                  <th>Type</th>
                  <th>Product Name</th>
                  <th>Quantity</th>
                </tr>
                <template v-for="(order, index) in orderItems">
                  <tr :key="`p-${index}`">
                    <td width="100px">
                      <div class="md-table-cell-container md-ripple md-disabled d-flex justify-center align-center">
                        <v-checkbox
                          style="margin-top: -8px"
                          v-model="order.isSettle"
                          color="#66c8c8"
                          :value="order.isSettle"
                          @change="changeCB(order, index)"
                          hide-details
                        ></v-checkbox>
                      </div>
                    </td>
                    <td>
                      <span v-if="order.invoice_seq_no">{{ order.invoice_seq_no }}</span>
                    </td>
                    <td>
                      <span v-if="order.customer_name">{{ order.customer_name }}</span>
                    </td>
                    <td>
                      <span v-if="order.product_type">{{ order.product_type }}</span>
                    </td>
                    <td>
                      <span v-if="order.product_name">{{ order.product_name }}</span>
                    </td>
                    <td v-if="checkIfOrder(order)" style="max-width:100px; width:70px; padding-right:10px;">
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        type="number"
                        v-model="order.refund_quantity"
                        :max="order.quantity"
                        min="1"
                        :readonly="isQtyReadOnly(order)"
                        @change="updateQuantity(order,index)"
                      ></v-text-field>
                    </td>
                    <td v-else>
                      <span v-if="order.quantity">{{ order.refund_quantity }} </span>
                    </td>
                  </tr>
                  <tr :key="`c-${index}`" v-if="showChildItemRow">
                    <td
                      v-if="order.tickets && order.tickets.length && order.tickets[0].ticket_code"
                      :colspan="numberOfColumns"
                    >
                      <v-expand-x-transition>
                        <table class="new_reciept_details_item_table child">
                          <tr>
                            <th>#</th>
                            <th>Customer Name</th>
                            <th>Ticket Code</th>
                          </tr>
                          <tr v-for="(items, index1) in order.tickets" :key="`tt-${index1}`">
                            <td width="100px">
                              <div
                                class="md-table-cell-container md-ripple md-disabled d-flex justify-center align-center"
                                v-if="order.isSettle == true"
                              >
                                <v-checkbox
                                  style="margin-top: -8px"
                                  v-model="items.isSettle"
                                  color="#66c8c8"
                                  :value="items.isSettle"
                                  hide-details
                                ></v-checkbox>
                              </div>
                            </td>
                            <td>{{ items.customer_name ? items.customer_name : "-" }}
                            </td>
                            <td>{{ items.ticket_code }}</td>
                          </tr>
                        </table>
                      </v-expand-x-transition>
                    </td>
                  </tr>
                </template>
                <tr v-if="orderItems && !orderItems.length">
                  <th colspan="4">Orders Not Found</th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn class="ma-2 close-btn" text @click="closeOrderDetails"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="ma-2 white--text blue-color" text @click="refundInvoice"
            >Refund</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    orderItems: { type: Array, default: () => [] },
    invoiceId: { type: Number, default: null },
  },
  mounted() {
    this.numberOfColumns = document.getElementById("new_reciept_details_item_table").rows[0].cells.length + 1;
  },
  data() {
    return {
      oiInvoiceModel: false,
      orderId: null,
      selectedOrders: [],
      refundOrderData: [],
      activeAccordion: null,
      numberOfColumns: 0,
    };
  },
  watch: {
    invoiceId: {
      immediate: true,
      handler(val) {
        if (val == "" || val == null) {
          this.oiInvoiceModel = false;
        } else {
          this.oiInvoiceModel = true;
        }
      },
    },
  },
  methods: {
    toggleAccordion(index) {
      if (this.activeAccordion === index) {
        this.activeAccordion = null;
        console.log("index: null " + index);
      } else {
        this.activeAccordion = index;
        console.log("index: " + index);
      }
    },
    validateInput(item) {
      if (item.quantity < item.refund_quantity) {
        item.refund_quantity = item.quantity;
        this.$forceUpdate();
      }
    },
    updateQuantity(item) {
      if (item.quantity < item.refund_quantity) {
        item.refund_quantity = item.quantity;
        this.$forceUpdate();
      }
      if(!item.rental_product_id && item.product_type == "Facility" && item.per_capacity == 0){
        this.selectedOrders.map( (order) => {
          if(order.isSettle && order.order_item_id == item.order_item_id && order.per_capacity == item.per_capacity && order.product_type == item.product_type){
            order.refund_quantity = item.refund_quantity;
          }
        })
      }else if(item.rental_product_id && item.product_type == "Facility" && item.per_capacity == 1){
        this.selectedOrders.map( (order) => {
          if(order.isSettle && order.order_item_id == item.order_item_id && order.per_capacity == item.per_capacity && order.product_type == item.product_type){
            order.refund_quantity = item.refund_quantity;
          }
        })
      }
      // if (item.quantity == item.refund_quantity) {
      //   this.selectedOrders.forEach(element => {
      //     if (element.whole_item && element.product_type == "Facility" && element.whole_item.facility_tickets && element.whole_item.facility_tickets.length) {
      //       element.whole_item.facility_tickets.forEach(ticket => {
      //         console.log("settle")
      //         ticket.isSettle = true;
      //       })
      //     }
      //   });
      //   this.$forceUpdate();
      // }
    },

    checkIfOrder(order) {
      let o = JSON.stringify(order);
      return this.selectedOrders.some((item) => JSON.stringify(item).order_item_id === o.order_item_id);
    },
    changeCB(order, toggleIndex = null) {
      let value = order.isSettle;
      if (value) {
        /** check if selected order is time based booking and has multiple orders */
        if(order.rental_product_id && order.rental_product_id > 0 && order.per_capacity == 0 && order.product_type == "Facility"){
          this.orderItems.map( (item) => {
            if(item.facility_booking_id == order.facility_booking_id && order.order_id == item.order_id){
              item.isSettle = true;
              const isItemAlreadySelected = this.selectedOrders.some((i) => i.order_item_id === item.order_item_id);
              if (!isItemAlreadySelected) {
                this.selectedOrders.push({...item});
              }
            }
          });
        }else{
          this.selectedOrders.push({...order});
        }
      } else {
        let o = {...order};
        let filterOrders = [];
        if(o.rental_product_id && o.rental_product_id > 0 && o.per_capacity == 0 && o.product_type == "Facility"){
          filterOrders = this.selectedOrders.filter(
            (item) => {
              if(item.facility_booking_id == o.facility_booking_id && o.order_id == item.order_id){

                this.orderItems.map( (oi) => {
                  if(oi.order_id == o.order_id){
                    oi.isSettle = null;
                  }
                });

                return o.order_id !== item.order_id;


              }else{
                return o.order_id !== item.order_id;
              }
            }
          );
          // Check if filteredData is empty
          if (filterOrders.length === 0) {
              filterOrders = []; // Set filteredData to an empty array
          }
        }else{
          filterOrders = this.selectedOrders.filter(
            (v) => v.order_item_id !== o.order_item_id
          );
        }
        this.selectedOrders = [...filterOrders];
      }
      if (toggleIndex != null) {
        this.toggleAccordion(toggleIndex);
      }
    },
    showChildItemRow(order) {
      if (
        order.tickets &&
        (order.product_type == "Facility" || order.product_type == "Event")
      ) {
        return true;
      }
      return false;
    },
    refundInvoice() {
      /** validate order refund selection */
      if (!this.validateData()) {

        this.showError("Please select an item");

        return false;
      }
      if (this.selectedOrders.length > 0) {
        //Filter out tickets with "isSettle" set to true
        const filteredOrders = {
          orders: this.selectedOrders.map((order) => ({
            ...order,
            tickets: order.tickets.filter(
              (ticket) => ticket.isSettle && ticket.isSettle === true
            ),
          })),
        };
        // console.log(filteredOrders);
        // let orders = {
        //   orders: this.selectedOrders,
        // };
        this.showLoader("Loading..");
        this.$http
          .post("venues/invoices/refund-partial-price", filteredOrders)
          .then((response) => {
            if (response.status == 200) {
              this.hideLoader();
              this.showSuccess("Price loaded");
              let data = {
                invoiceId: this.invoiceId,
                items: filteredOrders,
                price: response.data.data,
                is_settled:response.data.is_settled
              };
              this.$emit("refund", data);
            } else {
              this.showError("Unable to load refund data");
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
      } else {
        this.showError("Select atleast 1 item");
      }
    },
    getOrderDetails(id) {
      this.$emit("openOrderDetails", id);
    },
    closeOrderDetails() {
      this.$emit("close");
    },
    validateData() {
      if (this.selectedOrders.length > 0) {
        let keepValidate = true;
        for (let element of this.selectedOrders) {
          if (element.refund_quantity > element.quantity) {
            this.showError("Please fill all required fields");
            return false;
          }
          keepValidate = true;
          // console.log(element);
          if (element.tickets && element.tickets.length > 0 && (element.per_capacity === 1 || element.per_capacity === null) && (element.product_type == 'Facility' || element.product_type == 'Event')) {
            if (element.product_type == 'Facility' && !element.rental_product_id) {
              keepValidate = false;
            }
            if (keepValidate) {
              let ticketSelected = 0;
              for (let ticket of element.tickets) {
                if (ticket.isSettle) {
                  ticketSelected++;
                }
              }
              if (
                ticketSelected !==
                element.participant_count * element.refund_quantity
              ) {
                this.showError(
                  "You must select " +
                  element.participant_count * element.refund_quantity +
                  " tickets of " +
                  element.product_name
                );
                return false;
              }
            }
          }
        }
      } else {
        return false;
      }
      return true;
    },
    isQtyReadOnly(data) {
      if (data) {
        if(data.offer_pkg_id){
            return true;
        }
        if (data.facility_booking_id && data.facility_booking_id > 0) {
          if (data.rental_product_id && data.rental_product_id > 0 && data.per_capacity == 0) {
            return true;
          } else {
            return false;
          }
        } else if (data.workshop_booking_id && data.workshop_booking_id > 0) {
          console.log("yess")
          return true;
        }
      }
      return false;
    }
  },
};
</script>
<style scoped>
.invoice-title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
table.new_reciept_details_item_table {
  width: 100%;
}
table.new_reciept_details_item_table tr th,
table.new_reciept_details_item_table tr td {
  text-align: center;
  border-bottom: 1px solid #000;
}

.new_reciept_details_item_table {
  border-collapse: collapse;
  border-radius: 8px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #dbdbdb; /* this draws the table border  */
  overflow: hidden;
}
table.new_reciept_details_item_table.child {
  border-collapse: separate;
  border-radius: 0px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #dbdbdb; /* this draws the table border  */
}
.new_reciept_details_item_table th,
.new_reciept_details_item_table td,
.reciept_details_total_table th,
.reciept_details_total_table td {
  border: transparent !important;
  color: #112a45;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 10px 0px;
}

.new_reciept_details_item_table tr th {
  text-align: center;
  padding: 10px 0px;
}
.new_reciept_details_item_table tr:first-child th {
  background-color: #f7f7f5;
}
.new_reciept_details_item_table .order-number {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}
table.new_reciept_details_item_table.child {
  width: 80%;
  margin: 0 auto;
}
table.new_reciept_details_item_table.child th {
  background-color: #112a45;
  color: #f7f7f5;
}

.inv-sm-circle {
  color: #112a45;
  background: rgba(17, 42, 69, 0.1);
}
.text-black {
  color: #000;
}
span.display-block {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-left: 40px;
}
.receipt_details_body {
  background: #fff;
  padding: 8px;
}

.venue-info-table {
  width: 100%;
  margin: 0px auto;
}

.v-title {
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  color: #000;
  margin: 0px;
  padding: 0px;
}
</style>
