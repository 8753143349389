<template>
  <div>
    <v-dialog
      v-model="orderDialog"
      :max-width="isPayment ? '70%' : 700"
      @input="closeOrderDetails"
      scrollable
      persistent
    >
      <payment-model
        v-if="orderDetails.type == 'payment' && isPayment"
        @payed="
          isPrintPdf = true;
          $emit('paymentDone'), (isPayment = false);
        "
        :wallet="wallet"
        @cancel="cancelOrder"
        :orderId="id"
        @close="closeOrderDetails"
      ></payment-model>

      <receipt-model
        v-if="
          (!isPayment && orderDetails.type == 'receipt') ||
            (!isPayment && orderDetails.type == 'payment')
        "
        @close="closeOrderDetails"
        @pay="openPayment"
        @refund="$emit('paymentDone')"
        @cancel="cancelOrder"
        @void="voidTransaction"
        @openMainOrderDetails="openMainOrderDetails"
        @openInvoice="openInvoice"
        @openPayModel="openPayInvoice"
        :isPrintPdf="isPrintPdf"
        @sendPaymentLink="sendPaymentLink"
      >
        <template v-slot:carosal v-if="ids && ids.length > 1">
          <v-btn
            fab
            absolute
            right
            small
            color="white"
            dark
            style="margin-top:232px; color:blue"
            :disabled="currentIndex == ids.length - 1"
            @click="changeTickets('next')"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>

          <v-btn
            fab
            absolute
            left
            small
            color="white"
            dark
            style="margin-top:232px; color:blue"
            :disabled="currentIndex == 0"
            @click="changeTickets('prev')"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </template>
      </receipt-model>
    </v-dialog>
    <InvoiceModel
      v-if="invoiceModel.invoiceId"
      v-bind="invoiceModel"
      :invoiceData="invoiceData"
      @openMainOrderDetails="openMainOrderDetails"
      @openPayInvoice="openPayInvoice"
      @close="closeInvoiceModel"
      @openFullRefundInvoice="openFullRefundModel"
      @openPartialRefundInvoice="openPartialRefundInvoice"
      @sendPaymentLink="sendPaymentLink"
    >
      <template v-slot:carosal v-if="isSchedulePage && nextPrevInvIds && nextPrevInvIds.length > 1">
        <v-btn
          fab
          absolute
          right
          small
          color="white"
          dark
          class="center-btn right-btn"
          :disabled="currentIndex == nextPrevInvIds.length - 1"
          @click="changeInvoice('next')"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>

        <v-btn
          fab
          absolute
          left
          small
          color="white"
          dark
          class="center-btn left-btn"
          :disabled="currentIndex == 0"
          @click="changeInvoice('prev')"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </template>
      <template v-slot:carosal v-else-if="!isSchedulePage && invIds && invIds.length > 1">
        <v-btn
          fab
          absolute
          right
          small
          color="white"
          dark
          class="center-btn right-btn"
          :disabled="currentIndex == invIds.length - 1"
          @click="changeInvoice('next')"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>

        <v-btn
          fab
          absolute
          left
          small
          color="white"
          dark
          class="center-btn left-btn"
          :disabled="currentIndex == 0"
          @click="changeInvoice('prev')"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </template>
    </InvoiceModel>
    <EditInvoiceModel
      v-if="eInvoiceModel.invoiceId"
      v-bind="eInvoiceModel"
      :eInvoiceData="eInvoiceData"
      @close="closeInvoiceModel"
      @openMainOrderDetails="openMainOrderDetails"
      @openOlModel="openOlModel"
      @invoiceReload="invoiceReload"
    />

    <OrderListingModel
      v-if="olModel.invoiceId"
      v-bind="olModel"
      :olData="ordersData"
      @openMainOrderDetails="openMainOrderDetails"
      @close="closeOlModel"
      @invoiceReload="invoiceReload"
    />

    <PaymentModelNew
      v-if="paymentModel.invoiceId && payInvoiceData"
      v-bind="paymentModel"
      :payInvoiceData="payInvoiceData"
      @close="closePaymentModel"
      @payed="openInvoice"
    />

    <RefundNew
      v-if="refundModel.invoiceId && refundInvoiceData"
      v-bind="refundModel"
      :refundInvoiceData="refundInvoiceData"
      @close="closeRefundModel"
      @refund="refundInvoice"
    />

    <InvoiceOrderItemModel
      v-if="oiInvoiceModel.invoiceId"
      v-bind="oiInvoiceModel"
      :orderItems="orderItemsData"
      @close="closeInvoiceModel"
      @refund="openRefundModel"
    />

    <CreditInvoicePayment
      v-if="creditInvId && payInvoiceData"
      v-bind="creditSettleModel"
      :payInvoiceData="payInvoiceData"
      @close="closePaymentModel"
      @payed="openInvoice"
    ></CreditInvoicePayment>
  </div>
</template>

<script>
// import PaymentModel from "./PaymentModel.vue";
import ReceiptModel from "./ReceiptModel.vue";
import InvoiceModel from "@/components/Invoice/InvoiceModel.vue";
import InvoiceOrderItemModel from "@/components/Invoice/InvoiceOrderItemModel.vue";
import EditInvoiceModel from "@/components/Invoice/EditInvoiceModel.vue";
import OrderListingModel from "@/components/Invoice/OrderListingModel.vue";
import PaymentModelNew from "@/components/Invoice/PaymentModelNew.vue";
import RefundNew from "@/components/Invoice/RefundNew.vue";
import CreditInvoicePayment from "../../components/Invoice/CreditInvoicePayment.vue";
export default {
  components: {
    // "payment-model": PaymentModel,
    "receipt-model": ReceiptModel,
    InvoiceModel,
    EditInvoiceModel,
    OrderListingModel,
    PaymentModelNew,
    InvoiceOrderItemModel,
    RefundNew,
    CreditInvoicePayment,
  },
  props: {
    id: { type: Number, default: null },
    ids: { type: Array, default: null },
    invId: { type: Number, default: null },
    invIds: { type: Array, default: function() { return [] }  },
    eInvId: { type: Number, default: null },
    payInvId: { type: Number, default: null },
    creditInvId: { type: Number, default: null },
    isSchedulePage: { type: Boolean, default: false },
  },
  data() {
    return {
      orderId: null,
      orderDialog: false,
      currentIndex: 0,
      isPayment: false,
      isPrintPdf: false,
      wallet: {
        products: null,
        cash: null,
      },
      invoiceData: {},
      eInvoiceData: {},
      ordersData: [],
      orderItemsData: [],
      invoiceId: null,
      invoiceModel: { invoiceId: null, type: "details", orderIds: [] },
      eInvoiceModel: { invoiceId: null, type: "details", orderIds: [] },
      oiInvoiceModel: { invoiceId: null, type: "details", orderIds: [] },
      olModel: { invoiceId: null, type: "details", orderIds: [] },
      paymentModel: { invoiceId: null, type: "details", orderIds: [] },
      creditSettleModel: { invoiceId: null, type: "details", orderIds: [] },
      payInvoiceData: {},
      refundModel: { invoiceId: null, type: "full", amount: 0,is_settled:0 },
      refundInvoiceData: {},
      isShowOnlineRefund: false,
      currentInvoiceId: null,
      currentParentsIds: null,
      nextPrevInvIds:[],
    };
  },
  computed: {
    orderDetails() {
      return this.$store.getters.getOrderDetails;
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val == "" || val == null) {
          this.orderId = null;
          this.orderDialog = false;
          return;
        }
        this.orderId = val;
        this.getOrderDetails();
      },
    },
    ids: {
      immediate: true,
      handler(val) {
        if (val == "" || val == null) {
          this.orderId = null;
          this.orderDialog = false;
          return;
        }
        this.currentIndex = 0;
        this.orderId = val[this.currentIndex];
        this.getOrderDetails();
      },
    },
    invId: {
      immediate: true,
      handler(val) {
        if (val == "" || val == null) {
          this.invoiceModel.invoiceId = null;
          return;
        }
        // console.log("val 1");
        // console.log(val);
        this.openInvoice(val, "view");
      },
    },
    invIds: {
      immediate: true,
      handler(val) {
        if (val == "" || val == null) {
          this.invoiceModel.invoiceId = null;
          return;
        }
        this.currentIndex = 0;
        // console.log("val");
        // console.log(val);
        this.openInvoice(val[this.currentIndex], "view");
      },
    },
    eInvId: {
      immediate: true,
      handler(val) {
        if (val == "" || val == null) {
          this.invoiceModel.invoiceId = null;
          return;
        }
        this.openInvoice(val, "edit");
      },
    },
    payInvId: {
      immediate: true,
      handler(val) {
        if (val == "" || val == null) {
          this.paymentModel.invoiceId = null;
          return;
        }
        this.openPayInvoice(val);
      },
    },
    creditInvId: {
      immediate: true,
      handler(val) {
        // console.log("watch : " + val);
        if (val == "" || val == null) {
          this.creditSettleModel.invoiceId = null;
          return;
        }
        this.openCreditPayInvoice(val);
      },
    },
  },
  methods: {
    openMainOrderDetails(id) {
      this.$emit("openMainOrderDetails", id);
    },
    openPayment() {
      this.isPayment = true;
    },
    getCustomerWallets() {
      this.showLoader("Loading");
      this.$http
        .get(
          `venues/customers/products-wallet/get-customer-all/${this.orderId}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.hideLoader();
            let data = response.data;
            this.wallet.cash = data.cash;
            this.wallet.products = data.products;
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    getOrderDetails() {
      this.showLoader("Loading..");
      this.$store
        .dispatch("loadOrderDetails", this.orderId)
        .then((response) => {
          if (response.status == 200) {
            if (
              response.data.data.invoice_generated == 1 &&
              response.data.data.invoice_item
            ) {
              let invoice_id = response.data.data.invoice_item.invoice_id;
              if (this.isSchedulePage) {
                this.nextPrevInvIds = [];
                this.currentIndex = 0;
                if (response.data.data.invoice_item.parent_invoice_ids) {
                  let ids =  response.data.data.invoice_item.parent_invoice_ids.split(",").reverse();
                  invoice_id = parseInt(ids[0]);

                }
              }
              this.openInvoice(invoice_id,"view");
            } else {
              this.orderDialog = true;
            }
            this.hideLoader();
          }
        });
      // this.getCustomerWallets();
    },
    closeOrderDetails() {
      this.isPayment = false;
      this.isPrintPdf = false;
      this.$emit("close");
    },

    cancelOrder() {
      this.showLoader("Wait");
      this.$http
        .delete(`venues/orders/${this.orderId}`)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.showSuccess("Cancel success");

            this.closeOrderDetails();
            this.$emit("paymentDone");
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },

    voidTransaction(data) {
      this.showLoader("Wait");
      this.$http
        .post(`venues/orders/void/${this.orderId}`, { note: data.note })
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.showSuccess("Void success");

            this.closeOrderDetails();
            this.$emit("paymentDone");
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },

    changeTickets(dir) {
      if (dir == "next") {
        this.currentIndex++;
        this.orderId = this.ids[this.currentIndex];
        this.getOrderDetails();
      } else {
        this.currentIndex--;
        this.orderId = this.ids[this.currentIndex];
        this.getOrderDetails();
      }

      this.$forceUpdate();
    },
    sendPaymentLink(data) {
      this.showLoader("Wait");
      if (data.customer_id && data.invoice_id) {
        this.$http.post(`venues/invoices/e-invoice/${data.invoice_id}/${data.customer_id}`).then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            if (response.data && response.data.status) {
               this.showSuccess("Invoice link sent Successfully");
            } else {
              this.showError(response.data.message);
            }
            this.closeOrderDetails();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
      }

    },

    changeInvoice(dir) {
      if (this.isSchedulePage) {
        if (dir == "next") {
          this.currentIndex++;
          this.openInvoice(this.nextPrevInvIds[this.currentIndex], "view");
        } else {
          this.currentIndex--;
          this.openInvoice(this.nextPrevInvIds[this.currentIndex], "view");
        }
      } else {
        if (dir == "next") {
          this.currentIndex++;
          // this.invId = this.invIds[this.currentIndex];
          this.openInvoice(this.invIds[this.currentIndex], "view");
        } else {
          this.currentIndex--;
          // this.invId = this.invIds[this.currentIndex];
          this.openInvoice(this.invIds[this.currentIndex], "view");
        }

      }

      this.$forceUpdate();
    },
    openInvoice(invoice_id, type = "view") {
      this.orderDialog = false;
      this.showLoader("Loading..");
      this.$http
        .get("venues/invoices/" + invoice_id)
        .then((response) => {
          if (response.status === 200) {
            this.hideLoader();
            let data = response.data.data;
            if (type === "view") {
              this.invoiceData = data;

              this.invoiceModel.orderIds = [];
              this.paymentModel.invoiceId = null;

              if (this.isSchedulePage) {
                if (data) {
                  if (data.parent_invoice_ids) {
                    let ids = data.parent_invoice_ids.split(",").reverse();
                    ids.forEach((id) => {
                      if (this.nextPrevInvIds.indexOf(Number(id)) === -1) {
                        this.nextPrevInvIds.push(Number(id));
                      }
                    });
                  } else {
                    this.nextPrevInvIds = [];
                    this.currentIndex = 0;
                    this.invoiceModel.invoiceId = data.id;
                  }
                }
              }
              this.invoiceModel.invoiceId = invoice_id;
              this.$forceUpdate();
            } else if (type == "edit") {
              this.invoiceModel.invoiceId = null;
              this.paymentModel.invoiceId = null;
              this.eInvoiceData = data;
              this.eInvoiceModel.invoiceId = data.id;
              this.paymentModel.invoiceId = null;
            }
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    openPayInvoice(invoice_id) {
      // console.log("open pay invoice");
      this.invoiceModel.invoiceId = null;
      let data = null;
      if(this.invoiceData && this.invoiceData.venue_id){
          data = {
            id: invoice_id,
            venue_id: this.invoiceData.venue_id,
          }
      }else{
        data = invoice_id;
      }
      this.showLoader("Loading..");
      this.$store
        .dispatch("loadInvoiceDetails", data)
        .then((response) => {
          if (response.status === 200) {
            this.hideLoader();
            let data = response.data.data;
            this.payInvoiceData = data;
            this.payInvoiceData.wallet_redeem_amount = 0;
            this.payInvoiceData.invoiceId = data.id;
            this.paymentModel.invoiceId = data.id;
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    openCreditPayInvoice(invoice_id) {
      // console.log("open credit pay invoice");
      this.creditSettleModel.invoiceId = null;
      this.showLoader("Loading..");
      this.$store
        .dispatch("loadInvoiceDetails", invoice_id)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            let data = response.data.data;
            this.payInvoiceData = data;
            this.payInvoiceData.invoiceId = data.id;
            this.creditSettleModel.invoiceId = data.id;
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    openPartialRefundInvoice(invoice_id) {
      // this.invoiceModel.invoiceId = null;
      this.paymentModel.invoiceId = null;
      this.oiInvoiceModel.invoiceId = null;
      this.showLoader("Loading..");
      this.$http
        .get("venues/invoices/refund-partial/" + invoice_id)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            let data = response.data.data;
            this.orderItemsData = data;
            this.oiInvoiceModel.invoiceId = data[0].id;
          } else {
            this.showError("Unable to load items");
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    openFullRefundInvoice(invoice_id) {
      // this.invoiceModel.invoiceId = null;
      this.paymentModel.invoiceId = null;
      this.oiInvoiceModel.invoiceId = null;
      this.showLoader("Loading..");
      this.$http
        .get("venues/invoices/refund-partial/" + invoice_id)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            let data = response.data.data;
            this.orderItemsData = data;
            this.oiInvoiceModel.invoiceId = data[0].id;
          } else {
            this.showError("Unable to load items");
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    openRefundModel(data) {
      this.refundModel.invoiceId = data.invoiceId;
      this.refundModel.amount = data.price;
      this.refundModel.type = "partial";
      this.refundModel.is_settled = data.is_settled;
      this.refundInvoiceData = data.items;
      this.refundInvoiceData.isShowOnlineRefund = false;
    },
    openFullRefundModel(data) {
      if (this.invoiceData && this.invoiceData.orders) {
        this.refundInvoiceData.isShowOnlineRefund = false;
        if(this.invoiceData.orders.length == 1){
            if (this.invoiceData.orders[0].source === 'B2C') {
              this.refundInvoiceData.isShowOnlineRefund = true;
            }
        }
      }
      this.refundModel.invoiceId = data.invoiceId;
      this.refundModel.amount = data.price;
      this.refundModel.type = "full";
      this.refundModel.is_settled = data.is_settled;
    },
    closeRefundModel() {
      this.refundModel.invoiceId = null;
      this.refundModel.amount = null;
      this.refundModel.type = "full";
      this.$emit("close");
    },
    openOlModel(invoice_id) {
      // console.log("open Ol Model");
      // this.invoiceModel.invoiceId = null;
      // this.eInvoiceModel.invoiceId = null;
      this.olModel.invoiceId = invoice_id;
    },
    payPopup(invoiceId) {
      this.paymentModel.invoiceId = invoiceId;
    },
    invoiceReload(invoice_id) {
      this.$emit("invoiceReload", invoice_id);
    },
    closeInvoiceModel() {
      this.oiInvoiceModel.invoiceId = null;
      this.invoiceModel.invoiceId = null;
      this.eInvoiceModel.invoiceId = null;
      this.paymentModel.invoiceId = null;
      this.olModel.invoiceId = null;
      this.nextPrevInvIds = [];
      this.$emit("paymentDone");
      this.$emit("close");
    },
    closePaymentModel() {
      this.invoiceModel.invoiceId = null;
      this.paymentModel.invoiceId = null;
      this.creditSettleModel.invoiceId = null;
      this.nextPrevInvIds = [];
      this.$emit("close");
    },
    closeOlModel() {
      // console.log("close ol model");
      // console.log(this.eInvoiceModel);
      this.olModel.invoiceId = null;
      this.nextPrevInvIds = [];
      // this.$emit("close");
    },
    refundInvoice(data) {
      this.closeRefundModel();
      this.closeInvoiceModel();
      this.$emit("close");
      this.openInvoice(data, "view");
    },
  },
};
</script>

<style>
.center-btn {
  top: 50%;
  transform: translate(0%, -50%);
  color: blue !important;
  /* box-shadow: 5px 2px 8px rgba(0, 0, 0, 0.2) !important; */
}
.left-btn {
  left: -75px !important;
}
.right-btn {
  right: -75px !important;
}
</style>
