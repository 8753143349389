<template>
  <div>
    <v-dialog
        v-model="multiPaymentDialog"
        :max-width="'62%'"
        @input="close"
        scrollable
        persistent
    >
      <v-card tile class="payment-model">
        <v-card-text>
          <v-row class="pt-6">
            <v-col md="7">
              <v-card outlined>
                <v-card-title class="fw-700 heading-title">
                  <span>PAYMENT METHOD</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pm-body">
                  <v-row>
                    <v-col cols="12">
                      <v-card outlined tile>
                        <v-btn
                            block
                            style="background-color:#F2F2F2;"
                            class="fw-500 fs-14"
                        >Total {{ parseFloat(paymentTotal) | toCurrency }} / {{ calculatedTotal }}
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-form ref="form">
                        <v-row>
                          <v-col cols="12" md="12" v-if="multiInvoiceData && multiInvoiceData.length > 0">
                            <template>
                              <MultiInvoicePaymentMethod
                                  v-for="(method, index) in payments"
                                  v-bind="method"
                                  :index="index"
                                  :key="`p_${index}`"
                                  :multiInvoiceData="multiInvoiceData"
                                  :totalAmountMultiInvoice="calculatedTotal"
                                  :isMultiInvoicePayment='true'
                                  updateCommitType="updateMultiInvoicePaymentMethod"
                                  @remove="removePaymentMethod"
                                  @refresh="refreshFormValidation"
                                  @validate="validate"
                              ></MultiInvoicePaymentMethod>
                            </template>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions v-if="!payments.find((x) => x.payment_method == 'Complementary') && isEnabledMultiPayment()">
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="text-blue text-underline"
                      text
                      @click="addNewPaymentMethod"
                      :prefix="currencyCode"
                  >+ Add New Method
                  </v-btn>
                </v-card-actions>
              </v-card>
              <div class="bottom-fix" style="margin-top:30px;">
                <v-row>
                  <v-col cols="12">
                    <v-btn outlined class="" absolute bottom @click="close()"
                    >Close
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col md="5">
              <v-card outlined class="order-summary">
                <v-card-title class="fw-700 heading-title">
                  <span>ORDER SUMMARY</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-2" v-if="multiInvoiceData">
                  <v-expansion-panels>
                    <template v-for="(invoiceData,ind) in multiInvoiceData">
                      <v-expansion-panel
                          v-for="(order, index) in invoiceData.orders"
                          :key="`o_${ind}_${index}`"
                          class="tab-seprate"
                      >
                        <v-expansion-panel-header class="tab-heading">
                          {{ order.customer ? order.customer.first_name+" "+order.customer.last_name : "NA" }}
                          {{ order.items ? getOrderTypeName(order.items[0]) : "" }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="tab-content ma-2">
                          <v-list
                              three-line
                              class="overflow-y-auto"
                              v-if="order.items"
                              :key="`oi_${index}`"
                          >
                            <v-list-item
                                v-for="(item, i) in order.items"
                                :key="i"
                                ripple
                                @click="() => {}"
                            >
                              <v-list-item-avatar title height="40" width="40">
                                <view-image
                                    :image="item.product.image"
                                    :height="40"
                                    :width="40"
                                ></view-image>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title class="text-body product-name">
                                  {{ item.product.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <div class="d-flex justify-space-between text-caption">
                                    <div class="font-weight-bold">
                                      {{ (item.price / item.quantity).toFixed(2) }} x {{ item.quantity }}
                                    </div>
                                    <div class="font-weight-bold">
                                      Tax
                                      {{ item.tax | toCurrency }}
                                    </div>
                                    <div class="font-weight-bold">
                                      {{ item.total | toCurrency }}
                                    </div>
                                  </div>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="order.discount"
                                ripple
                                :key="`o_${order.id}`"
                                @click="() => {}"
                            >
                              <v-list-item-content>
                                <v-list-item-title class="text-body product-name green--text" v-if="order.discount">
                                  {{
                                    order.discount.member_id ? "Membership Discount" : order.discount.promotion_id ? "Promotion Discount" : ""
                                  }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <div class="d-flex justify-space-between text-caption">
                                    <div class="font-weight-bold">
                                      <span class="text-decoration-line-through">
                                        {{ order.discount.actual_price | toCurrency }}</span>
                                      {{ order.price | toCurrency }}
                                    </div>
                                    <div class="font-weight-bold">
                                      Tax
                                      <span class="text-decoration-line-through">
                                        {{ order.discount.actual_tax | toCurrency }}</span>
                                      {{ order.tax | toCurrency }}
                                    </div>
                                    <div class="font-weight-bold">
                                      <span class="text-decoration-line-through">
                                        {{ order.discount.actual_total | toCurrency }}</span>
                                      {{ order.total | toCurrency }}
                                    </div>
                                  </div>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </template>
                      </v-expansion-panels>

                </v-card-text>
                <div class="cart-text-bottom">
                  <v-row>
                    <v-col cols="6" class="smaller-col">
                      <div class="subheading">Sub Total</div>
                    </v-col>
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading">
                        {{ subTotal | toCurrency }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="smaller-col">
                      <div class="subheading">Tax Amount</div>
                    </v-col>
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading">
                        {{ totalTax | toCurrency }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pb-6" v-if="this.specialDiscount">
                    <v-col cols="6" class="smaller-col">
                      <div class="subheading fw-700 green--text">Special Discount</div>
                    </v-col>
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading fw-700">
                        {{ discount?discount.price:0 | toCurrency }} + {{ discount?discount.tax:0 | toCurrency }} =
                        {{ discount?discount.total:0 | toCurrency }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pb-6">
                    <v-col cols="6" class="smaller-col">
                      <div class="subheading fw-700">Grand Total</div>
                    </v-col>
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading fw-700">
                          <span class="text-decoration-line-through pt-1" v-if="discount">
                          {{ total }}
                        </span>
                        {{ calculatedTotal | toCurrency }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                      dense
                      v-if="
                      !invoiceDetails.promotion_code &&
                        !payments.find(
                          (x) => x.payment_method == 'Complementary'
                        )
                    "
                  >
                    <v-col cols="6" class="smaller-col">
                      <v-text-field
                          class="placeholder-small"
                          v-model="discountAmount"
                          label="Special discount"
                          outlined
                          :prefix="currencyCode"
                          :rules="[
                            (v) => {
                              if (v) {
                                if (isNaN(v)) {
                                  return 'Amount must be a number';
                                }
                              }
                              return true;
                            },
                          ]"
                          @keyup="specialDiscountChange('amount')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="smaller-col">
                      <v-text-field
                          class="placeholder-small"
                          v-model="discountPercentage"
                          suffix="%"
                          :rules="[
                            (v) => {
                              if (v) {
                                if (isNaN(v)) {
                                  return 'Amount must be a number';
                                }
                                if (v > 100) {
                                  return 'Percentage should be less than 100';
                                }
                              }
                              return true;
                            },
                          ]"
                          label="Percentage"
                          outlined
                          @keyup="specialDiscountChange('percentage')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-textarea
                          v-model="paymentNote"
                          label="Payment note"
                          rows="1"
                          outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </div>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-row dense class="pa-2">
                    <v-col cols="12" style="padding-bottom:24px;">
                      <v-select
                          :items="payerCustomerList"
                          v-model="payer"
                          item-value="customerId"
                          item-text="customerName"
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          return-object
                          label="Payer"
                          hide-details="auto"
                          dense
                          :rules="[(v) => !!v || 'Payer is required']"
                          required
                          @change="changePayer()"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                          block
                          class="block white--text blue-color large"
                          @click="payInvoice"
                          :disabled="!payer"
                      >PAY
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <confirm-model
            v-bind="confirmModel"
            @confirm="confirmActions"
            @close="confirmModel.id = null"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MultiInvoicePaymentMethod from "@/components/Invoice/MultiInvoicePaymentMethod.vue";
export default {
  components: {
    MultiInvoicePaymentMethod,
  },
  props: {
    multiPaymentDialog: { type: Boolean, default: false },
    amount: {type: Number, default: null},
    multiInvoiceData:{ type: Array, default: () => []},
  },
  data() {
    return {
      paymentDialog: false,
      invoice: {},
      confirmInputModel: {},
      voidNote: "",
      balance: 0,
      confirmModel: {},
      paymentNote: null,
      discountAmount: null,
      prevdiscountAmount: null,
      discountPercentage: null,
      isEditing: true,
      payer: null,
      multiInvoiceDataBeforeDiscount: [],
      specialDiscount: null,
      discount: null,
    };
  },

  watch: {
    multiPaymentDialog(val){
      if(val){
        console.log("load ",val);
        this.multiInvoiceDataBeforeDiscount = JSON.stringify(JSON.stringify(this.multiInvoiceData));
        if(this.multiInvoiceData && this.multiInvoiceData.length > 0){
          this.multiInvoiceData.forEach( ( invoiceData ) => {
            invoiceData.orders.forEach((order, index) => {
              if (order.customer) {
                if (index === 0) {
                  this.payer = {
                    customerId: order.customer.id,
                    customerName: order.customer.first_name.concat(" ",order.customer.last_name),
                    cashWallet: order.customer.wallet,
                    email: order.customer.email,
                    mobile: order.customer.mobile,
                  }
                  this.changePayer();
                }
              }
            });
          })
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch("loadVenueSalesConfig");
  },
  computed: {
    salesConfig() {
      return this.$store.getters.getSalesConfig;
    },
    invoiceDetails() {
      return this.$store.getters.getInvoiceDetails;
    },
    payments() {
      return this.$store.getters.getMultiInvoicePayments;
    },
    paymentTotal() {
      return this.$store.getters.getMultiInvoicePaymentTotal;
    },

    payerCustomerList() {
      let payerList = [];
      if (this.multiInvoiceData) {
        this.multiInvoiceData.forEach( (invoiceData) => {
          if(invoiceData.orders){
            invoiceData.orders.forEach((order) => {
              if (order.customer) {
                payerList.push({
                  customerId: order.customer.id,
                  customerName: order.customer.first_name.concat(" ",order.customer.last_name),
                  cashWallet: order.customer.wallet,
                  email: order.customer.email,
                  mobile: order.customer.mobile,
                });
              }
            });
          }

        })

      }
      return payerList;
    },

    subTotal(){
      return this.multiInvoiceData.reduce( (p,c) => p + c.price,0);
    },
    totalTax(){
      return this.multiInvoiceData.reduce( (p,c) => p + c.tax,0);
    },
    total(){
      return this.multiInvoiceData.reduce( (p,c) => p + c.total,0);
    },
    calculatedTotal(){
      let result = 0;
      if(this.discountAmount) {
        let d = this.discountAmount;
        return parseFloat(d);
      }else if(this.discountPercentage) {
        if( this.discountPercentage <= 100 ){
          result = this.total - ( (this.total * this.discountPercentage) / 100 );
        }else{
          result = this.total;
        }
      }else{
        result = this.total;
      }
      return result;
    },
  },
  methods: {
    addNewPaymentMethod() {
      if (this.calculatedTotal < this.paymentTotal) {
        this.showError("Total payment amount is greater than order amount");
        return;
      }
      this.$store.dispatch("addNewMultiInvoicePaymentMethod")
          .then(() => {})
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    removePaymentMethod(data) {
      this.confirmModel = {
        id: data.index,
        title: "Do you want to remove this Payment Method?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "remove_payment_method",
        data: {id: data.id, index: data.index},
      };
    },
    refreshFormValidation() {
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.$el.removeAttribute("novalidate");
      this.$refs.form.validate();
      this.$refs.form.$el.setAttribute("validate", "validate");
    },
    cancel() {
      this.confirmModel = {
        id: this.invoiceDetails.id,
        title: "Do you want to cancel this order?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "cancel",
      };
    },
    confirmActions(data) {
      if (data.type === "remove_payment_method") {
        setTimeout(() => {
          this.$store.commit("removeMultiInvoicePaymentMethod", data.data.index);
        });
      } else if (data.type === "cancel") {
        this.$emit("cancel");
      }
      this.confirmModel.id = null;
    },

    specialDiscountChange(type) {
      this.$store.dispatch("resetPaymentMethod").then(() => {
        this.$refs.form.resetValidation();
      }).catch((error) => {
        this.errorChecker(error);
      });
      this.$forceUpdate();
      let total = this.total;
      let totalTax = this.totalTax;
      let subTotal = this.subTotal;
      if (type === "percentage") {
          this.discountAmount = null;
          if (this.discountPercentage > 0 && this.discountPercentage <= 100) {
            let dt = ( (total * this.discountPercentage) / 100);
            let tax = (dt * 5 ) / 100;
            this.specialDiscount = true;
            this.discount = {
                price: dt - tax,
                tax : tax,
                total : dt,
            }
          }else{
            this.discountPercentage = null;
            this.specialDiscount = null;
            this.discount = null;
          }
      } else if (type === "amount") {
          this.discountPercentage = null;
          if (this.discountAmount) {
            if (this.discountAmount && this.discountAmount <= total) {
              let dt = this.discountAmount;
              let tax = (dt * 5 ) / 100;
              let sdt = dt - tax;
              this.specialDiscount = true;
              this.discount = {
                price: subTotal - sdt,
                tax : totalTax - tax,
                total : total - dt,
              }
            }else {
              this.showError("Payment amount not matching with order amount!");
              this.discountAmount = null;
              this.specialDiscount = null;
              this.discount = null;
            }
          }else{
            this.discountAmount = null;
            this.specialDiscount = null;
            this.discount = null;
          }
      }
    },

    payInvoice() {
      this.showLoader("Payment processing");
      const invoiceIds = this.multiInvoiceData.map(i => i.id);
      let data = {
          batch_id: this.multiInvoiceData[0].batch_id,
          payments: this.payments,
          customer_id: this.payer.customerId,
          invoice_ids: invoiceIds,
      };
      if(this.payments.find((x) => x.payment_method === "Complementary")){
          data.isComplementary = true;
      }
      if (this.paymentNote) {
        data.notes = this.paymentNote;
      }
      if (this.specialDiscount && (this.discountAmount || this.discountPercentage)) {
        data.discount = parseFloat(this.discount.total);
      }
      if (!this.$refs.form.validate()) {
        this.hideLoader();
        this.showError("Please fill all fields");
        return;
      }
      /** Check if duplicate card with same details exist restrict them */
      let isValid = true;
      if (this.payments.length) {
        let duplicatesCard = [];
        this.payments.forEach((el, i) => {
          if (!el.payment_method == "Card"){
            return null;
          }
          this.payments.forEach((element, index) => {
            if (i === index) {
              return null;
            }
            if (
                element.card_type_id === el.card_type_id &&
                element.payment_code === el.payment_code &&
                element.payment_method === el.payment_method &&
                element.card_type_id != null &&
                element.payment_code != null
            ) {
              if (!duplicatesCard.includes(el)) duplicatesCard.push(el);
            }
          });
        });
        if (duplicatesCard.length) {
          this.hideLoader();
          this.showError("Duplicate cards not allowed");
          return;
        }
      }
      if(!isValid){
        return;
      }
      this.$http
          .post("venues/shop/multi-invoices/pay", data)
          .then((response) => {
            this.hideLoader();
            if (response.status === 200) {
              this.showSuccess("Payment success");
              this.discountPercentage = null;
              this.discountAmount = null;
              this.specialDiscount = null;
              this.discount = null;
              this.$emit("payed");
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },

    changePayer() {
      this.invoice.customerId = this.payer.customerId;
      this.invoice.customerName = this.payer.customerName;
      let data = {
        id: this.payer.customerId,
        name: this.payer.customerName,
        wallet: this.payer.cashWallet,
        mobile: this.payer.mobile,
        email: this.payer.email,
      };
      this.$store.dispatch("setCustomer", data);
    },
    getOrderTypeName(orderItem = null) {
      if (orderItem && orderItem.product && orderItem.product.product_type_id) {
        switch (orderItem.product.product_type_id) {
          case 2:
            return "(Membership)";
          case 3:
            return "(Event)";
          case 4:
            return "(Academy)";
          case 5:
            return "(Trainer)";
          case 6:
            return "(Facility)";
          case 9:
            return "(Retail)";
          default:
            return "";
        }
      }
    },
    close() {
      this.discountPercentage = null;
      this.discountAmount = null;
      this.specialDiscount = null;
      this.discount = null;
      this.$emit("close");
    },
    isEnabledMultiPayment() {
      if (this.salesConfig) {
        return this.salesConfig.enable_multi_payment === 1;
      }
      return true;
    }
  },
};
</script>

<style scoped>
.heading-title {
  font-size: 16px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.text_line {
  border-bottom: 3px solid rgb(0, 176, 175);
}

.order-summary {
  background-color: rgba(17, 42, 69, 0.03);
}

.placeholder-small ::v-deep label {
  font-weight: 400;
  font-size: 12px;
}

.placeholder-small ::v-deep .v-text-field__prefix,
.placeholder-small ::v-deep .v-text-field__suffix {
  font-size: 14px;
}

.order-summary .v-expansion-panel.tab-seprate {
  margin-top: 12px;
}

.order-summary button.v-expansion-panel-header.tab-heading {
  color: #112a45;
  font-weight: 600;
  font-size: 12px;
  padding: 0px 12px;
  min-height: 44px;
}

.order-summary .v-list-item__title.product-name {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #112a45;
}

.order-summary .v-expansion-panel-content.tab-content .v-list-item {
  padding: 0px;
  min-height: 44px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.order-summary .v-expansion-panel-content.tab-content .v-list-item:last-child {
  border-bottom: 0px;
}

.order-summary .v-list-item__content {
  max-height: 65px;
}

.order-summary
.v-expansion-panel-content.tab-content
::v-deep
.v-expansion-panel-content__wrap {
  padding: 0px 12px;
}

.order-summary .smaller-col {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.order-summary .cart-text-bottom {
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}

.payment-model .v-card__text.pm-body {
  max-height: 400px;
  overflow-y: auto;
}
</style>
