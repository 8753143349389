<template>
  <v-dialog
    persistent
    v-model="show"
    max-height="20%"
    scrollable
    :max-width="order.total > 0 && order.status != 'Credit' ? '900px' : '400px'"
  >
    <v-card tile>
      <v-form ref="form">
        <v-card-text>
          <v-row class="pt-6">
            <v-col md="8" v-if="order.total > 0 && order.status != 'Credit'">
              <v-card outlined>
                <v-card-title
                  ><span class="text_line">SELECT PAYMENT METHOD</span>
                  <v-spacer></v-spacer>
                  <v-btn outlined
                    >Total {{ paymentTotal() | toCurrency }} /
                    {{ totalPaymentAmount() | toCurrency }}</v-btn
                  >
                </v-card-title>
                <v-card-text
                  v-for="(payment, index) in refund.payments"
                  :key="`p_${index}`"
                >
                  <v-row>
                    <v-col cols="6" md="6">
                      <template>
                        <v-select
                          label="Payment Method"
                          outlined
                          background-color="#fff"
                          item-text="name"
                          item-value="id"
                          @change="paymentChange($event, index)"
                          return-object
                          v-model="payment.payment_method"
                          :items="paymentMethods"
                          required
                          :rules="[(v) => !!v || 'Payment Method is required']"
                        ></v-select>
                      </template>
                    </v-col>
                    <v-col
                      md="6"
                      v-if="
                        payment.payment_method &&
                          payment.payment_method.name == 'Card'
                      "
                    >
                      <v-select
                        label="Card Type"
                        outlined
                        background-color="#fff"
                        item-text="name"
                        item-value="id"
                        v-model="payment.card_type_id"
                        required
                        :rules="[(v) => !!v || 'Card Type is required']"
                        :items="cardTypes"
                      ></v-select>
                    </v-col>
                    <v-col
                      md="6"
                      v-if="
                        payment.payment_method &&
                          payment.payment_method.name == 'Card'
                      "
                    >
                      <v-text-field
                        outlined
                        required
                        background-color="#fff"
                        v-model="payment.payment_code"
                        :rules="[
                          (v) => !!v || 'Authorization Code is required',
                        ]"
                        label="Authorization Code"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="6" md="6">
                      <template>
                        <v-text-field
                          outlined
                          background-color="#fff"
                          :value="payment.amount"
                          v-model="payment.amount"
                          label="Payment Amount"
                          :prefix="currencyCode"
                          append-icon="mdi-cash-plus"
                          @click:append="autoFillAmount(index)"
                          required
                          :rules="[
                            (v) => {
                              if (v <= 0)
                                return 'Price should greater than zero';
                              if (v && isNaN(v)) return 'Price must be Number';
                              if (!v) return 'Price is required';
                              return true;
                            },
                          ]"
                        ></v-text-field>
                      </template>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="warning"
                      outlined
                      v-if="index != 0"
                      @click="refund.payments.splice(index, 1)"
                    >
                      Remove <v-icon small right>mdi-delete</v-icon></v-btn
                    >
                  </v-card-actions>
                </v-card-text>
                <v-card-actions v-if="!repeatRefundAmount && isEnabledMultiPayment()">
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="addNewPaymentMethod"
                    v-if="isShowAddNewPaymentBtn"
                  >
                    Add New Method <v-icon small right>mdi-plus</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
              <v-btn
                dark
                class="red"
                outlined
                absolute
                bottom
                @click="$emit('close'), $emit('reload')"
                >Close</v-btn
              >
            </v-col>
            <v-col :md="order.total > 0 ? 4 : 12">
              <v-card outlined>
                <v-card-title
                  ><span class="text_line">REASON</span></v-card-title
                >
                <v-card-text> </v-card-text>
                <v-divider></v-divider>
                <div class="pa-2">
                  <v-textarea
                    v-model="refund.reason"
                    rows="4"
                    label="Refund reason"
                    outlined
                  ></v-textarea>
                </div>
                <v-divider></v-divider>
                <v-card-actions
                  v-if="order.total > 0 && order.status != 'Credit'"
                >
                  <v-btn
                    v-if="checkWritePermission($modules.sales.refund.slug)"
                    block
                    large
                    color="rgb(0, 176, 175)"
                    dark
                    tile
                    @click="confirmRefund"
                    >Refund</v-btn
                  >
                </v-card-actions>
                <v-card-actions v-else>
                  <v-col md="6">
                    <v-btn
                      block
                      large
                      color="rgb(0, 176, 175)"
                      dark
                      tile
                      class="ma-2 white--text red-color"
                      @click="confirmRefund"
                      >Cancel Order</v-btn
                    >
                  </v-col>
                  <v-col md="6">
                    <v-btn
                      block
                      large
                      color="rgb(0, 176, 175)"
                      dark
                      tile
                      @click="closeRefund"
                      >Close</v-btn
                    >
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters({
      order: "getOrderDetails",
      payments: "getOrderPayments",
      total: "getOrderPaymentTotal",
    }),
    paymentMethods() {
      let p_methods = this.$store.getters.getPaymentMethods.data.filter(
        (ele) => {
          return ele.name !== "Card";
        }
      );
      // if (
      //   this.order.source != "B2C" &&
      //   this.order.source != "Mobile" &&
      //   this.order.status == "Paid"
      // ) {
      //   p_methods = p_methods.filter(function (value) {
      //     return value.id != 17;
      //   });
      // }
      if (!this.order.isShowOnlineRefund) {
        p_methods = p_methods.filter(function(value) {
          return value.id != 17;
        });
      }
      return p_methods;
    },
    cardTypes() {
      return this.$store.getters.getCardTypes.data;
    },
    salesConfig() {
      return this.$store.getters.getSalesConfig;
    },
  },
  props: {
    show: { type: Boolean, default: false },
    repeatRefundAmount: { type: Number, default: null },
    repeatBookingdata: { type: Array, default: null },
    workshopRefundAmount: { type: Number, default: null },
    workshopCustomerAttendanceId: { type: Number, default: null },
  },
  data() {
    return {
      refund: { payments: [{}] },
      confirmModel: {},
      isShowAddNewPaymentBtn: true,
    };
  },
  destroyed() {
    this.$store.dispatch("loadPaymentMethods", "normal");
  },

  mounted() {
    if (this.$store.getters.getCardTypes.status == false) {
      this.$store.dispatch("loadCardTypes");
    }
    if (!this.$store.getters.getSalesConfig) {
      this.$store.dispatch("loadVenueSalesConfig");
    }
    this.$store.dispatch("loadPaymentMethods", "refund");

    if (this.repeatRefundAmount) {
      this.order.total = this.repeatRefundAmount;
      this.$forceUpdate();
    }

    if (this.workshopRefundAmount) {
      this.order.total = this.workshopRefundAmount;
      this.$forceUpdate();
    }
  },
  methods: {
    closeRefund() {
      this.$emit("close");
    },
    paymentTotal() {
      return this.refund.payments.reduce(
        (a, b) => a + parseFloat(b.amount ? b.amount : 0),
        0
      );
    },
    autoFillAmount(index) {
      this.refund.payments[index].amount = null;
      let amount = this.refund.payments[index].amount;
      let balance =
        this.totalPaymentAmount() -
        this.paymentTotal() +
        (amount > 0 ? amount : 0);
      this.refund.payments[index].amount = balance.toFixed(2);
      this.$forceUpdate();
    },
    paymentChange(val, index) {
      /** payment_method_id = 10 means card payment
       *  We restrict the same payment methods select multiple time except card
       */
      let fIndex = this.refund.payments.findIndex(
        (element) =>
          element.payment_method_id === val.id &&
          element.payment_method_id !== 10
      );
      if (fIndex >= 0) {
        this.refund.payments[index].payment_method = null;
        this.showError(`${val.name} is already selected`);
        return;
      }
      /** payment_method_id = 17 means Online payment method id and type refund
       *  If we select online payment method for refund we will restrict to select other payment method
       *  Online payment refund only applicable for those order which are created by Mobile or Customer Portal
       */
      if (val.id === 17) {
        this.refund.payments = [{}];
        let refundPaymentMethods = this.$store.getters.getPaymentMethods.data;
        refundPaymentMethods = refundPaymentMethods.filter(function(value) {
          return value.id == 17;
        });
        this.refund.payments[0] = {
          amount: this.total,
          payment_method_id: val.id,
          method: val.name,
          payment_method: refundPaymentMethods[0],
        };
        this.isShowAddNewPaymentBtn = false;
      } else {
        this.isShowAddNewPaymentBtn = true;
        this.refund.payments[index].payment_method_id = val.id;
        this.refund.payments[index].payment_method.name = val.name;
        this.refund.payments[index].method = val.name;
        this.$forceUpdate();
      }
    },
    confirmRefund() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader("Refund processing....");
      if (this.order.total != 0 && this.order.status != "Credit") {
        let totalPayment = this.refund.payments.reduce(
          (a, b) => a + parseFloat(b.amount),
          0
        );
        if (totalPayment == 0) {
          this.hideLoader();
          this.showError("Please enter amount!");
          return;
        }
        if (
          this.totalPaymentAmount() > totalPayment ||
          this.totalPaymentAmount() < totalPayment
        ) {
          this.hideLoader();
          this.showError("Payment amount not matching with order amount!");
          return 0;
        }
      } else {
        delete this.refund.payments;
      }
      //this.showLoader();

      let url = "venues/orders/refund";

      if (this.repeatRefundAmount) {
        url = "venues/facilities/bookings/repeat/cancel-and-refund";
        this.refund.booking_ids = this.repeatBookingdata;
      } else if (this.workshopRefundAmount) {
        url = "venues/workshops/schedules/booking/refund-class";
        this.refund.workshop_customer_attendance_id = this.workshopCustomerAttendanceId;
      } else {
        this.refund.order_id = this.order.id;
      }

      this.$http
        .post(url, this.refund)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Refund Successfully Initiated");
            this.$emit("refunded");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    addNewPaymentMethod() {
      if (this.paymentTotal()) {
        this.refund.payments.push({ amount: 0 });
      }
    },
    removePaymentMethod(data) {
      this.confirmModel = {
        id: data.index.toString(),
        title: "Do you want to remove this Payment Method?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "remove_payment_method",
        data: { id: data.id, index: data.index },
      };
    },
    confirmActions(data) {
      if (data.type == "remove_payment_method") {
        this.$store.commit("removePaymentMethod", data.index);
      }
    },
    totalPaymentAmount() {
      if (this.payments && this.payments.length) {
        let totalPayment;
        if (this.repeatRefundAmount) {
          totalPayment = this.repeatRefundAmount;
        } else if (this.workshopRefundAmount) {
          totalPayment = this.workshopRefundAmount;
        } else {
          totalPayment = this.order.refund_amount;
        }

        let findComplementary = this.payments.find(
          (x) => x.payment_method == "Complementary"
        );

        if (findComplementary) {
          let totalAfterComplementary = this.payments
            .filter((item) => item.payment_method != "Complementary")
            .reduce((a, b) => a - b.amount, 0);
          totalPayment = totalAfterComplementary;
          return Math.abs(totalPayment);
        }
        // if (!isNaN(this.order.credit_amount)) {
        //   return totalPayment - this.order.credit_amount;
        // } else {
        return parseFloat(totalPayment);
        //}
      }
      return 0;
    },
    isEnabledMultiPayment() {
      if (this.salesConfig) {
        if (this.salesConfig.enable_multi_payment == 1) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
  },
};
</script>
<style scoped>
.text_line {
  border-bottom: 3px solid rgb(0, 176, 175);
}
</style>
