<template>
  <div class="">
    <table
      class="new_reciept_details_item_table"
      id="new_reciept_details_item_table"
    >
      <tr>
        <th v-if="isCheckbox">
          <div class="md-table-cell-container md-ripple md-disabled d-flex justify-center align-center">
            <v-checkbox
              style="margin-top: -8px"
              v-model="isAllCheck"
              color="#66c8c8"
              @change="changeAllCB"
              hide-details
            ></v-checkbox>
          </div>
        </th>
        <th>Order Number</th>
        <th>Customer Name</th>
        <th v-if="!isEdit && !isCheckbox">Price</th>
        <th v-if="!isEdit && !isCheckbox">Vat Amount</th>
        <th>Total Price</th>
        <th v-if="isEdit">Action</th>
        <th v-if="!isEdit && !isCheckbox && isInvoiceHasOrderDiscount">Discount</th>
      </tr>
      <template v-for="(order, index) in orders">
        <tr :key="`parent-${index}`" :class="order.order && (order.order.order_status_id === 8 || order.order.order_status_id === 17 || order.order.order_status_id === 20)?'partial-refund-order':''">
          <td v-if="!isEdit && isCheckbox" width="100px">
            <div
              class="md-table-cell-container md-ripple md-disabled d-flex justify-center align-center"
            >
              <v-checkbox
                style="margin-top: -8px"
                v-model="order.isSettle"
                color="#66c8c8"
                :value="order.isSettle"
                @change="changeCB(order.isSettle, order.id)"
                hide-details
              ></v-checkbox>
            </div>
          </td>
          <td
            v-if="order.order && order.order.id"
            @click="toggleAccordion(index)"
          >
            <div
              v-if="order.order && order.order.invoice_seq_no"
              class="order-number"
            >
              {{ order.order.invoice_seq_no }}
            </div>
            <div
              v-if="
                order.order &&
                  !order.order.invoice_seq_no &&
                  order.order.order_seq_no
              "
              class="order-number"
            >
              {{ order.order.order_seq_no }}
            </div>
            <div v-if="order.invoice_seq_no" class="order-number">
              {{ order.invoice_seq_no }}
            </div>
          </td>
          <td v-else @click="getOrderDetails(order.id)">
            <div
              v-if="order.order && order.invoice_seq_no"
              class="order-number"
            >
              {{ order.order.invoice_seq_no }}
            </div>
            <div v-if="order.invoice_seq_no" class="order-number">
              {{ order.invoice_seq_no }}
            </div>
          </td>
          <td>
            <span v-if="order.order && order.order.customer"
              >{{ order.order.customer.first_name }}
              {{ order.order.customer.last_name }}</span
            >
            <span v-else-if="order.customer"
              >{{ order.customer.first_name }}
              {{ order.customer.last_name }}
            </span>
          </td>
          <td v-if="!isEdit && !isCheckbox">
            <span
              class="text-decoration-line-through display-block"
              v-if="
                order.order &&
                  order.order.discount &&
                  order.order.discount.actual_price
              "
              >{{ order.order.discount.actual_price | toCurrency }}</span>
            <span>
              {{ Math.abs(Number(order.price)) | toCurrency }}
            </span>
          </td>
          <td v-if="!isEdit && !isCheckbox">
            <span
              class="text-decoration-line-through display-block"
              v-if="
                order.order &&
                  order.order.discount &&
                  order.order.discount.actual_tax
              "
              >{{ order.order.discount.actual_tax | toCurrency}}</span
            >
            {{ Math.abs(order.tax) | toCurrency }}
          </td>
          <td>
            <span
              class="text-decoration-line-through display-block"
              v-if="
                order.order &&
                  order.order.discount &&
                  order.order.discount.actual_total
              "
              >{{ order.order.discount.actual_total | toCurrency }}</span
            >
            {{ Math.abs(order.total) | toCurrency }}
          </td>
          <td v-if="isEdit">
            <v-btn
              class="ma-2 btn-sm-outline-red"
              text
              @click="
                removeOrderFromInvoice(
                  order.order && order.order.id ? order.order.id : order.id
                )
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="red-text" v-bind="attrs" v-on="on"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Remove order from invoice.</span>
              </v-tooltip>
              Remove</v-btn
            >
          </td>
          <td v-if="!isEdit && !isCheckbox && isInvoiceHasOrderDiscount">
            <span class="text-bold display-block" v-if="order.order && order.order.discount">
              {{ getDiscountType(order.order.discount) }}
            </span>
            <span class="text-bold" v-if="order.order && order.order.discount && order.order.discount.promotion && order.order.discount.promotion.promotion_code">
              {{ order.order.discount.promotion.promotion_code}}
            </span>
            <span class="text-bold" v-else-if="order.order && order.order.discount && order.order.discount.member">
              {{ order.order.discount.member.display_number }}
            </span>
            <span class="text-bold" v-else-if="order.order && order.order.discount && order.order.discount.offer_package">
              {{ order.order.discount.offer_package.package_name }}
            </span>
            <span class="text-bold" v-else>-</span>
          </td>
          <!-- <td v-if="!isEdit && !isCheckbox && isInvoiceHasOrderDiscount">
            <span class="text-bold" v-if="order.order && order.order.discount && order.order.discount.promotion && order.order.discount.promotion.promotion_code">
              {{ order.order.discount.promotion.promotion_code}}
            </span>
            <span class="text-bold" v-else-if="order.order && order.order.discount && order.order.discount.member">
              {{ order.order.discount.member.display_number }}
            </span>
            <span class="text-bold" v-else>-</span>
          </td> -->
        </tr>
        <tr :key="'child-' + index" v-show="activeAccordion === index">
          <td
            v-if="order.order && order.order.items"
            :colspan="numberOfColumns"
          >
            <v-expand-x-transition>
              <table class="new_reciept_details_item_table child">
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>VAT Rate</th>
                  <th>VAT Amount</th>
                  <th>Total</th>
                </tr>
                <tr v-for="(items, index1) in order.order.items" :key="index1">
                  <td>
                    {{ items.product.name }}
                  </td>
                  <td>
                    {{ Math.abs(items.quantity).toFixed(2) }}
                  </td>
                  <!-- <td>{{ Math.abs(Number(items.price)) | toCurrency }}</td> -->
                  <td
                    v-if="order.order.status_id && order.order.status_id == 21"
                  >
                    {{ Math.abs(Number(items.product.price)) | toCurrency }}
                  </td>
                  <td v-else>
                    {{ Math.abs(Number(items.price)) | toCurrency }}
                  </td>
                  <td>{{ items.product.tax_type.name }}</td>

                  <td
                    v-if="order.order.status_id && order.order.status_id == 21"
                  >
                    {{
                      Math.abs(
                        Number(items.product.tax_amount * items.quantity)
                      ) | toCurrency
                    }}
                  </td>
                  <td v-else>{{ Math.abs(items.tax) | toCurrency }}</td>
                  <td
                    v-if="order.order.status_id && order.order.status_id == 21"
                  >
                    {{
                      Math.abs(
                        Number(items.product.total_price * items.quantity)
                      ) | toCurrency
                    }}
                  </td>
                  <td v-else>{{ Math.abs(items.total) | toCurrency }}</td>
                </tr>
              </table>
            </v-expand-x-transition>
          </td>
          <td v-else-if="order && order.items" :colspan="numberOfColumns">
            <v-expand-x-transition>
              <table>
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>VAT Rate</th>
                  <th>VAT Amount</th>
                  <th>Total</th>
                </tr>
                <tr v-for="(items, index1) in order.items" :key="index1">
                  <td>
                    {{ items.product.name }}
                  </td>
                  <td>
                    {{ Math.abs(items.quantity).toFixed(2) }}
                  </td>
                  <td>{{ Math.abs(Number(items.price)) | toCurrency }}</td>
                  <td>{{ items.product.tax_type.name }}</td>
                  <td>{{ Math.abs(items.tax) | toCurrency }}</td>
                  <td>{{ Math.abs(items.total) | toCurrency }}</td>
                </tr>
              </table>
            </v-expand-x-transition>
          </td>
        </tr>
      </template>
      <tr v-if="orders && !orders.length">
        <th colspan="4">Orders Not Found</th>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    orders: { type: Array, default: () => [] },
    isEdit: { type: Boolean, default: false },
    isCheckbox: { type: Boolean, default: false },
    pageNumber: { type: Number, default: 1},
    isInvoiceHasOrderDiscount: { type: Boolean, default: false},
  },
  data() {
    return {
      orderId: null,
      selectedOrders: [],
      activeAccordion: null,
      numberOfColumns: 0,
      isAllCheck: false,
    };
  },
  watch: {
    pageNumber: {
      immediate: true,
      handler(val) {
        if (val && this.orders && this.orders.length) {
          let c = 0;
          this.isAllCheck = false;
          this.orders.forEach(function (order, i) {
            if (order.isSettle) {
              console.log(i);
              c++;
            }
          });
          if (c == 10) {
            this.isAllCheck = true;
          } else {
            this.isAllCheck = false;
          }
        }
      },
    },
  },
  mounted() {
    // Calculate the number of columns in the table
    // console.log(document.getElementById("new_reciept_details_item_table").rows[0].cells);
    // this.numberOfColumns = document.querySelector("table").rows[0].cells.length;
    this.numberOfColumns = document.getElementById(
      "new_reciept_details_item_table"
    ).rows[0].cells.length;
  },
  methods: {
    toggleAccordion(index) {
      if (this.activeAccordion === index) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = index;
      }
    },
    changeCB(cbvalue, order_id) {
      if (cbvalue) {
        this.selectedOrders.push(order_id);
      } else {
        let filterOrders = this.selectedOrders.filter(
          (value) => value != order_id
        );
        this.selectedOrders = filterOrders;
      }
      this.$emit("addOrderToInvoice", this.selectedOrders);
    },
    getOrderDetails(id) {
      this.$emit("openOrderDetails", id);
    },
    removeOrderFromInvoice(orderId) {
      let selectedOrd = [];
      this.selectedOrders = [];
      this.orders.map((item) => {
        if (item.order_id == orderId) {
          /** to fixed  */
          selectedOrd.push(orderId);
        }
      });
      this.selectedOrders = orderId;
      this.$emit("removeOrderFromInvoice", orderId);
    },
    changeAllCB() {
      if (this.isAllCheck) {
        this.orders.map((order) => {
          order.isSettle = true;
          this.selectedOrders.push(order.id);
        });
      } else {
        if (this.selectedOrders.length > 0) {
          this.orders.map((order) => {
            let index = this.selectedOrders.indexOf(order.id);
            if (index != -1) {
              order.isSettle = false;
              this.selectedOrders.splice(index, 1);
            }
          });
        }
      }
    this.selectedOrders = this.selectedOrders.filter((value, index, self) => self.indexOf(value) === index);
      this.$emit("addOrderToInvoice", this.selectedOrders);
    },
    getDiscountType(discountObj){
      return discountObj.promotion_id
            ? 'Promotion'
            : discountObj.member_id
              ? 'Membership'
              : discountObj.offer_pkg_id
                ? 'Offer'
                : 'NA';
    },

  },
};
</script>
<style scoped>
table.new_reciept_details_item_table {
  width: 100%;
}
table.new_reciept_details_item_table tr th,
table.new_reciept_details_item_table tr td {
  text-align: center;
  border-bottom: 1px solid #000;
}

.new_reciept_details_item_table {
  border-collapse: collapse;
  border-radius: 8px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #dbdbdb; /* this draws the table border  */
  overflow: hidden;
}
.new_reciept_details_item_table th,
.new_reciept_details_item_table td,
.reciept_details_total_table th,
.reciept_details_total_table td {
  border: transparent !important;
  color: #112a45;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 10px 0px;
}

.new_reciept_details_item_table tr th {
  text-align: center;
  padding: 10px 0px;
}
.new_reciept_details_item_table tr:first-child th {
  background-color: #f7f7f5;
}
.new_reciept_details_item_table .order-number {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

table.new_reciept_details_item_table.child {
  margin: 0 3%;
  width: 93%;
}
table.new_reciept_details_item_table.child th {
  background-color: #112a45;
  color: #f7f7f5;
}
table.new_reciept_details_item_table tr.partial-refund-order{
  background: #ffa0a0;
}
.display-block{
  display: block;
}
</style>
