<template>
  <div>
    <v-dialog
      v-model="paymentDialog"
      :max-width="'62%'"
      @input="close"
      scrollable
      persistent
    >
      <v-card tile class="payment-model">
        <v-card-text>
          <v-row class="pt-6">
            <v-col md="7">
              <v-card outlined>
                <v-card-title class="fw-700 heading-title">
                  <span>PAYMENT METHOD</span></v-card-title
                >
                <v-divider></v-divider>
                <v-card-text class="pm-body">
                  <v-row>
                    <v-col cols="12">
                      <v-card outlined tile>
                        <v-btn
                          block
                          style="background-color:#F2F2F2;"
                          class="fw-500 fs-14"
                          >Total {{ parseFloat(paymentTotal) | toCurrency }} /
                          {{
                            discountPercentage && discountAmount
                              ? discountAmount -
                                payInvoiceData.wallet_redeem_amount
                              : amount
                              ? amount - payInvoiceData.wallet_redeem_amount
                              : invoiceDetails.credit_amount
                              ? invoiceDetails.credit_amount
                              : (invoiceDetails.total -
                                  payInvoiceData.wallet_redeem_amount)
                                | toCurrency
                          }}</v-btn
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-form ref="form">
                        <v-row>
                          <v-col cols="12" md="12" v-if="payInvoiceData">
                            <template>
                              <PaymentMethodNew
                                v-for="(method, index) in payments"
                                v-bind="method"
                                :index="index"
                                :key="`p_${index}`"
                                :payInvoiceData="payInvoiceData"
                                :wallet="wallet"
                                @remove="removePaymentMethod"
                                @refresh="refreshFormValidation"
                                @validate="validate"
                              ></PaymentMethodNew>
                            </template>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions
                  v-if="
                    !payments.find((x) => x.payment_method == 'Complementary') && isEnabledMultiPayment()
                  "
                >
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="text-blue text-underline"
                    text
                    @click="addNewPaymentMethod"
                    :prefix="currencyCode"
                    >+ Add New Method</v-btn
                  >
                </v-card-actions>
              </v-card>
              <!-- <v-row>
                <v-col style="display: flex; margin-top: 15px">
                <v-spacer></v-spacer>

                <v-btn outlined center
                    >Total {{ parseFloat(paymentTotal) | toCurrency }} /
                    {{
                    discountPercentage && discountAmount
                        ? discountAmount
                        : amount
                        ? amount
                        : orderDetails.credit_amount
                        ? orderDetails.credit_amount
                        : orderDetails.total | toCurrency
                    }}</v-btn
                >
                <v-spacer></v-spacer>
                </v-col>
            </v-row> -->
              <div class="bottom-fix" style="margin-top:30px;">
                <v-row>
                  <v-col cols="12">
                    <v-btn outlined class="" absolute bottom @click="close()"
                      >Close</v-btn
                    >
                    <!-- <v-btn
                      v-if="!order_ids"
                      dark
                      class="red"
                      outlined
                      absolute
                      bottom
                      @click="cancel()"
                      style="margin-left: 100px"
                      >Cancel Reservation
                    </v-btn> -->
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col md="5">
              <v-card outlined class="order-summary">
                <v-card-title class="fw-700 heading-title"
                  ><span>ORDER SUMMARY</span></v-card-title
                >
                <v-divider></v-divider>
                <v-card-text class="pa-2" v-if="payInvoiceData">
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="(order, index) in payInvoiceData.orders"
                      :key="`o_${index}`"
                      class="tab-seprate"
                    >
                      <v-expansion-panel-header class="tab-heading">
                        {{ order.customer ? order.customer.name : "NA" }}
                        {{
                          order.items ? getOrderTypeName(order.items[0]) : ""
                        }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="tab-content">
                        <v-list
                          three-line
                          class="overflow-y-auto"
                          v-if="order.items"
                          :key="`oi_${index}`"
                        >
                          <v-list-item
                            v-for="(item, i) in order.items"
                            :key="i"
                            ripple
                            @click="() => {}"
                          >
                            <v-list-item-avatar title height="40" width="40">
                              <view-image
                                :image="item.product.image"
                                :height="40"
                                :width="40"
                              ></view-image>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="text-body product-name"
                                >{{ item.product.name }}</v-list-item-title
                              >
                              <v-list-item-subtitle>
                                <div
                                  class="d-flex justify-space-between text-caption"
                                >
                                  <div class="font-weight-bold">
                                    <!-- // item.product.product_type_id == 6 || item.product.product_type_id == 3 
                                        // ? item.product.price
                                        // : item.price -->
                                    {{ (item.price / item.quantity).toFixed(2) }}
                                    x {{ item.quantity }}
                                  </div>
                                  <div class="font-weight-bold">
                                    Tax
                                    <!-- {{
                                      (item.discount
                                        ? item.discount.actual_tax
                                        : item.tax) | toCurrency
                                    }} -->
                                    {{ item.tax | toCurrency }}
                                  </div>
                                  <div class="font-weight-bold">
                                    {{ item.total | toCurrency }}
                                    <!-- {{ currencyCode }}
                                    {{
                                      item.discount
                                        ? item.discount.actual_total
                                        : item.total
                                    }} -->
                                  </div>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <!-- -->
                          <v-list-item
                            v-if="order.discount"
                            ripple
                            :key="`o_${order.id}`"
                            @click="() => {}"
                          >
                            <v-list-item-content>
                              <v-list-item-title
                                class="text-body product-name green--text"
                                v-if="order.discount"
                              >
                                {{
                                  order.discount.member_id
                                    ? "Membership Discount"
                                    : order.discount.promotion_id
                                    ? "Promotion Discount"
                                    : ""
                                }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <div
                                  class="d-flex justify-space-between text-caption"
                                >
                                  <div class="font-weight-bold">
                                    <span
                                      class="text-decoration-line-through"
                                      >{{
                                        order.discount.actual_price | toCurrency
                                      }}</span
                                    >
                                    {{ order.price | toCurrency }}
                                  </div>
                                  <div class="font-weight-bold">
                                    Tax
                                    <span
                                      class="text-decoration-line-through"
                                      >{{
                                        order.discount.actual_tax | toCurrency
                                      }}</span
                                    >
                                    {{ order.tax | toCurrency }}
                                  </div>
                                  <div class="font-weight-bold">
                                    <span
                                      class="text-decoration-line-through"
                                      >{{
                                        order.discount.actual_total | toCurrency
                                      }}</span
                                    >
                                    {{ order.total | toCurrency }}
                                  </div>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
                <div class="cart-text-bottom">
                  <v-row>
                    <v-col cols="6" class="smaller-col"
                      ><div class="subheading">Sub Total</div></v-col
                    >
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading">
                        {{
                          (invoiceDetails.discount
                            ? invoiceDetails.actual_price
                            : invoiceDetails.price) | toCurrency
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="smaller-col"
                      ><div class="subheading">Tax Amount</div></v-col
                    >
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading">
                        {{
                          (invoiceDetails.discount
                            ? invoiceDetails.actual_tax
                            : invoiceDetails.tax) | toCurrency
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="invoiceDetails.credit_amount">
                    <v-col cols="6" class="smaller-col"
                      ><div class="subheading fw-700">Paid Amount</div></v-col
                    >
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading fw-700">
                        -{{
                          (invoiceDetails.credit_amount
                            ? invoiceDetails.total -
                              invoiceDetails.credit_amount
                            : 0) | toCurrency
                        }}
                        -{{
                          (invoiceDetails.credit_amount
                            ? invoiceDetails.total -
                              invoiceDetails.credit_amount
                            : 0) | toCurrency
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      invoiceDetails.discount &&
                        invoiceDetails.actual_price - invoiceDetails.price != 0
                    "
                  >
                    <v-col cols="6" class="smaller-col"
                      ><div class="subheading fw-700 green--text">
                        Discount - {{ invoiceDetails.promotion_name }}
                      </div></v-col
                    >
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading fw-700">
                        {{
                          (invoiceDetails.actual_price - invoiceDetails.price)
                            | toCurrency
                        }}
                        +
                        {{
                          (invoiceDetails.actual_tax - invoiceDetails.tax)
                            | toCurrency
                        }}
                        =
                        {{
                          (invoiceDetails.actual_total - invoiceDetails.total)
                            | toCurrency
                        }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="
                      payInvoiceData.wallet_redeem_amount &&
                        payInvoiceData.wallet_redeem_amount > 0
                    "
                  >
                    <v-col cols="6" class="smaller-col"
                      ><div class="subheading fw-700 green--text">
                        Product Wallet Redeem
                      </div></v-col
                    >
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading fw-700">
                        {{ payInvoiceData.wallet_redeem_amount | toCurrency }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="pb-6">
                    <v-col cols="6" class="smaller-col"
                      ><div class="subheading fw-700">Grand Total</div></v-col
                    >
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading fw-700">
                        <span
                          class="text-decoration-line-through pt-1"
                          v-if="
                            invoiceDetails.discount &&
                              invoiceDetails.actual_price -
                                invoiceDetails.price !=
                                0
                          "
                        >
                          {{ invoiceDetails.actual_total | toCurrency }}
                        </span>
                        {{
                          amount
                            ? amount >= advance_amount
                              ? amount - advance_amount
                              : amount
                            : (invoiceDetails.credit_amount
                                ? invoiceDetails.credit_amount
                                : invoiceDetails.total -
                                  payInvoiceData.wallet_redeem_amount)
                              | toCurrency
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    v-if="
                      !invoiceDetails.promotion_code &&
                        !payments.find(
                          (x) => x.payment_method == 'Complementary'
                        ) &&
                        payInvoiceData.wallet_redeem_amount <= 0
                    "
                  >
                    <v-col cols="6" class="smaller-col">
                      <v-text-field
                        class="placeholder-small"
                        v-model="discountAmount"
                        label="Special discount"
                        outlined
                        :prefix="currencyCode"
                        :rules="[
                          (v) => {
                            if (v) {
                              if (isNaN(v)) {
                                return 'Amount must be a number';
                              }
                            }
                            return true;
                          },
                        ]"
                        @keyup="specialDiscountChange('amount')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="smaller-col">
                      <v-text-field
                        class="placeholder-small"
                        v-model="discountPercentage"
                        suffix="%"
                        :rules="[
                          (v) => {
                            if (v) {
                              if (isNaN(v)) {
                                return 'Amount must be a number';
                              }
                              if (v > 100) {
                                return 'Percentage should be less than 100';
                              }
                            }
                            return true;
                          },
                        ]"
                        label="Percentage"
                        outlined
                        @keyup="specialDiscountChange('percentage')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-textarea
                        v-model="paymentNote"
                        label="Payment note"
                        rows="1"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </div>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-row dense class="pa-2">
                    <v-col cols="12" style="padding-bottom:24px;">
                      <v-select
                        :items="payerCustomerList"
                        v-model="payer"
                        item-value="customerId"
                        item-text="customerName"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        return-object
                        label="Payer"
                        hide-details="auto"
                        dense
                        :rules="[(v) => !!v || 'Payer is required']"
                        required
                        @change="changePayer()"
                      ></v-select>
                    </v-col>

                    <v-col cols="6" v-if="payer" style="padding-bottom:24px;">
                        <v-btn class="fw-400 fs-14" style="font-size: 13px; text-transform: capitalize;padding:6px;" block>
                          <v-icon small left>mdi-wallet</v-icon>Cash:{{ wallet.cash | toCurrency }}
                        </v-btn>
                    </v-col>
                    <v-col cols="6" v-if="payer">
                        <v-btn
                          class="fw-400 fs-14"
                          style="background-color:#F2F2F2;font-size: 13px; text-transform: capitalize;padding:6px;"
                          block
                          :loading="loadingProducts"
                          :disabled="loadingProducts"
                          @click="showWalletProducts"
                          ><v-icon small left>mdi-wallet-travel</v-icon>Products:{{ wallet.products }}</v-btn>
                    </v-col>
                    <v-col cols="12" v-if="payer && wallet.points" style="padding-bottom:24px;">
                        <v-btn class="fw-400 fs-14" style="font-size: 13px; text-transform: capitalize;padding:6px;" block>
                          <v-icon small left>mdi-gift</v-icon>Total QPoints:{{ wallet.points.toFixed(2) }} | AED {{ wallet.points_val.toFixed(2) }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12"
                      ><v-btn
                        block
                        class="block white--text blue-color large"
                        @click="payInvoice"
                        :disabled="!payer"
                        >PAY</v-btn
                      ></v-col
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <confirm-model
          v-bind="confirmModel"
          @confirm="confirmActions"
          @close="confirmModel.id = null"
        />

        <customer-product-wallet
          v-bind="walletModel"
          :totalAmount="
            amount
              ? amount >= advance_amount
                ? amount - advance_amount
                : amount
              : invoiceDetails.credit_amount
              ? invoiceDetails.credit_amount
              : invoiceDetails.total
          "
          @refreshWallet="refreshWallet"
          @updateProductWalletRedeem="updateProductWalletRedeem"
          @close="
            (walletModel.products = []),
              (walletModel.showModal = false),
              (walletModel.customer_id = null),
              (walletModel.invoice_id = null)
          "
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PaymentMethodNew from "@/components/Order/PaymentMethodNew.vue";
import CustomerProductWallet from "@/components/Order/CustomerProductWallet.vue";
export default {
  components: {
    PaymentMethodNew,
    "customer-product-wallet": CustomerProductWallet,
  },
  props: {
    "order-id": { type: Number, default: null },
    order_ids: { type: Array, default: null },
    advance_payment_id: { type: Number, default: null },
    advance_amount: { type: Number, default: null },
    amount: { type: Number, default: null },
    invoiceId: { type: Number, default: null },
    payInvoiceData: { type: Object, default: () => {} },
  },
  data() {
    return {
      paymentDialog: false,
      invoice: {},
      confirmInputModel: {},
      voidNote: "",
      balance: 0,
      confirmModel: {},
      paymentNote: null,
      discountAmount: null,
      prevdiscountAmount: null,
      discountPercentage: null,
      walletModel: {
        showModal: false,
        customer_id: null,
        products: [],
        invoice_id: null,
      },
      loadingProducts: false,
      isEditing: true,
      is_wallet_redeem: false,
      payer: null,
      wallet: { cash: 0, products: 0, points: 0, points_val: 0 },
      wallet_redeem_amount: 0,
      products_redeemed: {},
    };
  },

  watch: {
    invoiceId: {
      immediate: true,
      handler(val) {
        if (val == "" || val == null) {
          this.paymentDialog = false;
        } else {
          this.paymentDialog = true;
          // this.getInvoiceDetails();
        }
      },
    },
    invoiceDetails(val) {
      if (val.wallet_redemption && val.wallet_redemption.length > 0) {
        this.is_wallet_redeem = true;
      } else {
        this.is_wallet_redeem = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch("loadVenueSalesConfig");
    if (
      this.invoiceDetails &&
      this.invoiceDetails.wallet_redemption &&
      this.invoiceDetails.wallet_redemption.length > 0
    ) {
      this.is_wallet_redeem = true;
    } else {
      this.is_wallet_redeem = false;
    }
    if (this.invoiceDetails && this.invoiceDetails.invoice_notes) {
      this.paymentNote = this.invoiceDetails.invoice_notes;
    }
    if (this.payInvoiceData && this.payInvoiceData.orders) {
      this.payInvoiceData.orders.forEach((order,index) => {
        if (order.customer) {
          if(index == 0){
            this.payer = {
              customerId: order.customer.id,
              customerName: order.customer.name,
              cashWallet: order.customer.wallet,
              email: order.customer.email,
              mobile: order.customer.mobile,
            }
            this.changePayer();
          }
        }
      });
    }

    // window.addEventListener("beforeunload", this.stopRefresh);
  },
  beforeDestroy() {
    // window.removeEventListener("beforeunload", this.stopRefresh);
  },
  computed: {
    salesConfig() {
      return this.$store.getters.getSalesConfig;
    },
    products() {
      return this.$store.getters.getOrderItems;
    },
    invoiceDetails() {
      return this.$store.getters.getInvoiceDetails;
    },
    payments() {
      return this.$store.getters.getInvoicePayments;
    },
    paymentTotal() {
      return this.$store.getters.getInvoicePaymentTotal;
    },
    payerCustomerList() {
      let payerList = [];
      if (this.payInvoiceData && this.payInvoiceData.orders) {
        this.payInvoiceData.orders.forEach((order) => {
          if (order.customer) {
            payerList.push({
              customerId: order.customer.id,
              customerName: order.customer.name,
              cashWallet: order.customer.wallet,
              email: order.customer.email,
              mobile: order.customer.mobile,
            });
            // if(index == 0){
            //   this.payer = {
            //     customerId: order.customer.id,
            //     customerName: order.customer.name,
            //     cashWallet: order.customer.wallet,
            //     email: order.customer.email,
            //     mobile: order.customer.mobile,
            //   }
            //   this.changePayer();
            // }
          }
        });
      }
      return payerList;
    },
  },
  methods: {
    refreshWallet() {
      this.$emit("refreshWallet");
    },

    showWalletProducts() {
      if (this.discountAmount || this.discountPercentage) {
        this.showError("Promotion already applied, cannot redeem products");
        return;
      }

      this.payInvoiceData.wallet_redeem_amount = 0;
      this.showLoader("Loading...");

      let data = null;
      data = {
        invoice_id: this.invoiceId,
        customer_id: this.payer.customerId,
      };

      this.$http
        .post(`venues/customers/products-wallet/get-customer-invoice-all`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.hideLoader();
            let data = response.data.data;
            this.walletModel.products = [];
            data.forEach((d) => {
              this.walletModel.products.push({
                id: d.id,
                name: d.product.name,
                product_id: d.product.id,
                quantity: d.quantity,
                type: d.product.product_type,
                status: d.status_id,
              });
            });
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
      this.walletModel.invoice_id = this.invoiceId;
      this.walletModel.customer_id = this.invoice.customerId;
      this.walletModel.showModal = true;
    },
    stopRefresh(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    addNewPaymentMethod() {
      let currentOrderTotal =
        this.discountPercentage && this.discountAmount
          ? this.discountAmount
          : this.payInvoiceData.total
          ? this.payInvoiceData.total
          : this.payInvoiceData.credit_amount
          ? this.payInvoiceData.credit_amount
          : this.payInvoiceData.total;
      if (currentOrderTotal < this.paymentTotal) {
        this.showError("Total payment amount is greater than order amount");
        return;
      }
      this.$store
        .dispatch("addNewPaymentMethod")
        .then(() => {})
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    removePaymentMethod(data) {
      this.confirmModel = {
        id: data.index,
        title: "Do you want to remove this Payment Method?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "remove_payment_method",
        data: { id: data.id, index: data.index },
      };
    },
    refreshFormValidation() {
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.$el.removeAttribute("novalidate");
      this.$refs.form.validate();
      this.$refs.form.$el.setAttribute("validate", "validate");
    },
    cancel() {
      this.confirmModel = {
        id: this.invoiceDetails.id,
        title: "Do you want to cancel this order?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "cancel",
      };
    },
    confirmActions(data) {
      if (data.type == "remove_payment_method") {
        setTimeout(() => {
          this.$store.commit("removePaymentMethod", data.data.index);
        });
      } else if (data.type == "cancel") {
        this.$emit("cancel");
      }
      this.confirmModel.id = null;
    },
    specialDiscountChange(type) {
      this.$store
        .dispatch("resetPaymentMethod")
        .then(() => {
          this.$refs.form.resetValidation();
        })
        .catch((error) => {
          this.errorChecker(error);
        });

      this.$forceUpdate();

      if (type == "percentage") {
        this.discountAmount = null;
        if (this.order_ids && this.order_ids.length > 0) {
          this.discountAmountForCompanySettle("percentage");
        } else {
          if (this.discountPercentage <= 100) {
            this.$store.commit(
              "addSpecialDiscountPercentage",
              this.discountPercentage
            );
          }
        }
      } else if (type == "amount") {
        this.discountPercentage = null;
        if (this.order_ids && this.order_ids.length > 0) {
          this.discountAmountForCompanySettle("amount");
        } else {
          //Regex to limit input to 2 decimal places
          let stringValue = this.discountAmount.toString();
          let regex = /^[0-9]*(\.[0-9]{0,2})?$/;
          if (!stringValue.match(regex)) {
            this.discountAmount = parseFloat(this.prevdiscountAmount);
          }
          this.prevdiscountAmount = parseFloat(this.discountAmount);

          if (
            this.discountAmount <
            this.$store.getters.getInvoiceBeforeDiscount.total
          ) {
            this.$store.commit("addSpecialDiscount", this.discountAmount);
          } else {
            this.showError("Payment amount not matching with order amount!");
            this.discountAmount = null;
            this.$store.commit("addSpecialDiscount", 0);
          }
        }
      }
      if (
        this.invoiceDetails.wallet_redemption &&
        this.invoiceDetails.wallet_redemption.length > 0
      ) {
        this.is_wallet_redeem = true;
      } else {
        this.is_wallet_redeem = false;
      }
      //console.log(this.orderDetails);
    },

    discountAmountForCompanySettle(type) {
      if (type == "percentage") {
        if (isNaN(this.discountPercentage)) {
          return;
        }
        if (this.discountPercentage < 0) {
          this.discountPercentage = 0;
        }
        if (this.discountPercentage >= 100) {
          this.discountPercentage = 100;
          this.discountAmount = 0;
        } else {
          this.discountAmount =
            this.amount -
            ((this.amount / 100) * this.discountPercentage).toFixed(2);
        }
      } else {
        if (this.discountAmount == "" || this.discountAmount == null) {
          this.discountAmount = null;
          this.discountPercentage = null;
          return;
        }

        if (isNaN(this.discountAmount)) {
          this.discountAmount = this.amount;
          this.discountPercentage = 100;
        }
        if (this.discountAmount == 0) {
          this.discountPercentage = 100;
        } else if (this.discountAmount == this.amount) {
          this.discountPercentage = 0;
        } else {
          this.discountPercentage = (
            (this.discountAmount / this.amount) *
            100
          ).toFixed(2);
        }
      }
    },

    payInvoice() {
      this.showLoader("Payment processing");
      let data = {};
      if (this.order_ids) {
        data = {
          order_id: this.order_ids,
          advance_payment_id: this.advance_payment_id? this.advance_payment_id: null,
          payments: this.payments,
        };
      } else {
        data = {
          invoice_id: this.invoiceDetails.id,
          venueId: this.invoiceDetails.venue_id,
          payments: this.payments,
          customer_id: this.payer.customerId,
        };

        if (this.payments.find((x) => x.payment_method == "Complementary")) {
          data.isComplementary = true;
        }
      }
      if (this.paymentNote) {
        data.notes = this.paymentNote;
      }
      data.products_redeemed = this.products_redeemed;
      if (this.discountAmount) {
        data.discount = parseFloat(this.discountAmount);
      }
      if (this.discountPercentage && !this.order_ids) {
        data.discount = this.invoiceDetails.total;
      }
      if (!this.$refs.form.validate()) {
        this.hideLoader();
        this.showError("Please fill all fields");
        return;
      }
      /** Check if duplicate card with same details exist restrict them */
      let isValid = true;
      if (this.payments.length) {
        let duplicatesCard = [];
        this.payments.forEach((el, i) => {
          if(el.payment_method === "QPoints"){
            if(el.amount > this.wallet.points_val && (el.amount - this.wallet.points_val) > 0.05 ){
              isValid = false;
              this.hideLoader();
              this.showError("QPoints value exceeding, available point value: "+this.wallet.points_val);
              return null;
            }
          }else if(el.payment_method === "Wallet"){
            if(el.amount > this.wallet.cash){
              isValid = false;
              this.hideLoader();
              this.showError("Wallet amount exceeding, available balance: "+this.wallet.cash);
              return null;
            }
          }
          if (!el.payment_method === "Card"){
            return null;
          }
          this.payments.forEach((element, index) => {
            if (i === index) { 
              return null;
            }
            if (
              element.card_type_id === el.card_type_id &&
              element.payment_code === el.payment_code &&
              element.payment_method === el.payment_method &&
              element.card_type_id != null &&
              element.payment_code != null
            ) {
              if (!duplicatesCard.includes(el)) duplicatesCard.push(el);
            }
          });
        });
        if (duplicatesCard.length) {
          this.hideLoader();
          this.showError("Duplicate cards not allowed");
          return;
        }
      }
      if(! isValid){
        return;
      }
      this.$http
        .post("venues/invoices/pay", data)
        .then((response) => {
          this.hideLoader();
          if (response.status === 200) {
            this.showSuccess("Payment success");
            this.$emit("payed", this.invoiceDetails.id);
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },

    payLater() {
      if (this.paymentNote) {
        let data = {
          invoice_id: this.invoiceDetails.id,
          notes: this.paymentNote,
        };
        this.showLoader();
        this.$http
          .post("venues/orders/pay-later", data)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.showSuccess("Payment note updated!");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
      this.$emit("close");
    },

    changePayer() {
      this.invoice.customerId = this.payer.customerId;
      this.invoice.customerName = this.payer.customerName;
      this.wallet.cash = this.payer.cashWallet;
      let data = {
        id: this.payer.customerId,
        name: this.payer.customerName,
        wallet: this.payer.cashWallet,
        mobile: this.payer.mobile,
        email: this.payer.email,
      };
      this.loadProductWallet();
      this.$store.dispatch("setCustomer", data);
    },

    loadProductWallet() {
      this.wallet.products = 0;
      this.wallet.points = 0;
      this.wallet.points_val = 0;
      this.loadingProducts = true;
      let data = null;
      data = {
        invoice_id: this.invoiceId,
        customer_id: this.payer.customerId,
      };

      this.$http
        .post(`venues/customers/products-wallet/get-customer-all`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.hideLoader();
            let data = response.data.products;
            this.wallet.products = data;
            this.loadingProducts = false;
            this.payInvoiceData.wallet_redeem_amount = 0;
            this.products_redeemed = {};
            this.wallet.points = response.data.points;
            this.wallet.points_val = response.data.points_val;
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
          this.loadingProducts = false;
        });
    },
    updateProductWalletRedeem(amount, products_redeemed) {
      this.products_redeemed = products_redeemed;
      this.updateRedeemAmount(amount);
    },
    updateRedeemAmount(amount) {
      this.payInvoiceData.wallet_redeem_amount = amount;
    },
    getOrderTypeName(orderItem = null) {
      if (orderItem && orderItem.product && orderItem.product.product_type) {
        return "(" + orderItem.product.product_type.name + ")";
      }
      return "";
    },
    close() {
      this.$emit("close");
    },
    isEnabledMultiPayment() {
      if (this.salesConfig) {
        if (this.salesConfig.enable_multi_payment == 1) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
  },
};
</script>

<style scoped>
.heading-title {
  font-size: 16px;
}

.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.text_line {
  border-bottom: 3px solid rgb(0, 176, 175);
}
.order-summary {
  background-color: rgba(17, 42, 69, 0.03);
}
.placeholder-small ::v-deep label {
  font-weight: 400;
  font-size: 12px;
}
.placeholder-small ::v-deep .v-text-field__prefix,
.placeholder-small ::v-deep .v-text-field__suffix {
  font-size: 14px;
}
.order-summary .v-expansion-panel.tab-seprate {
  margin-top: 12px;
}
.order-summary button.v-expansion-panel-header.tab-heading {
  color: #112a45;
  font-weight: 600;
  font-size: 12px;
  padding: 0px 12px;
  min-height: 44px;
}
.order-summary .v-list-item__title.product-name {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #112a45;
}
.order-summary .v-expansion-panel-content.tab-content .v-list-item {
  padding: 0px;
  min-height: 44px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.order-summary .v-expansion-panel-content.tab-content .v-list-item:last-child {
  border-bottom: 0px;
}
.order-summary .v-list-item__content {
  max-height: 65px;
}

.order-summary
  .v-expansion-panel-content.tab-content
  ::v-deep
  .v-expansion-panel-content__wrap {
  padding: 0px 12px;
}
.order-summary .smaller-col {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.order-summary .cart-text-bottom {
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}
.payment-model .v-card__text.pm-body {
    max-height: 400px;
    overflow-y: auto;
}
</style>
