<template>
  <div>
    <v-card ref="recieptPrint">
      <slot name="carosal"></slot>
      <v-card-text class="pa-0 ma-0">
        <div ref="printDiv">
          <div class="reciept_details">
            <v-row class="ma-0">
              <v-col class="text-left pt-5 pl-6" cols="10" md="10">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="white" v-bind="attrs" v-on="on">
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="downloadPdf('a4')">
                      <v-list-item-title>Print A4</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="downloadPdf('pos')">
                      <v-list-item-title>Print POS</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn icon color="white" @click="mailReciept"
                  ><v-icon>mdi-email</v-icon></v-btn
                >
              </v-col>
              <v-col class="text-right pt-5 pl-6" cols="2" md="2">
                <v-btn icon color="white" @click="closeReceipt"
                  ><v-icon>mdi-close-thick</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <div class="head text-center pt-8">Customer Details</div>
            <table class="reciept_table">
              <tr>
                <td>Name</td>
                <td>{{ typeof customer.name != "undefined" ? customer.name : "NA"}}</td>
              </tr>
              <tr>
                <td>Mobile No.</td>
                <td>{{ typeof customer.mobile != "undefined"? customer.mobile: "NA" }}
                </td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>
                  {{ typeof customer.email != "undefined" ? customer.email : "NA" }}
                </td>
              </tr>
            </table>

            <v-divider></v-divider>
          </div>
          <div class="reciept_details_body">
            <div class="head pt-4">
              <span v-if="
                    (order.type == 'payment' && order.status != 'Rescheduled') ||
                    (paymentMethods == 'NA' && order.status == 'Cancelled') ||
                    (paymentMethods == 'NA' && order.status == 'Void')"
                >Order Summary</span
              >
              <span v-else-if="order.status == 'Refund'">Tax Credit Note</span>
              <span v-else>Tax Invoice</span>
              <span v-if="
                    ( order.order_status != 'Pending' && order.order_status == 'Rescheduled') ||
                    order.order_status == 'Partial Reschedule' ||
                    order.order_status == 'Partial Cancel' ||
                    order.order_status == 'Partial Refund' ||
                    order.order_status == 'Refund' ||
                    order.order_status == 'Cancelled' ||
                    order.order_status == 'Reservation Cancel'
                  "> ({{ order.order_status }}) </span>

              <span v-if="order.status == 'Void'"> ({{ order.status }}) </span>
            </div>

            <table class="reciept_details_table pa-3">
              <tr>
                <td><div class="reciept_title">Venue</div></td>
                <td>
                  <div class="reciept_right reciept_title">
                    <span v-if="order.type != 'payment' || order.status == 'Rescheduled' || order.paid_at != null">Purchase</span>
                    <span v-else>Invoice</span> Timestamp
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $store.getters.venueInfo.name }}</td>
                <td>
                  <div class="reciept_right">
                    {{ order.paid_at? order.paid_at: order.created_at | timeStamp }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="reciept_title">
                    <span v-if="order.type != 'payment' || order.status == 'Rescheduled' || order.status == 'Void'">Payment Mode :</span>
                  </div>
                </td>
                <td>
                  <div
                    v-if="order.status == 'Paid' && order.rental_facility_booking && order.rental_facility_booking.show_end_time"
                    class="reciept_right reciept_title">
                    Valid From
                  </div>
                  <div class="reciept_right reciept_title" v-else>
                    Booking Date <span v-if="order.facility_booking_details">& Time</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span v-if=" order.type != 'payment' || order.status == 'Rescheduled' || order.status == 'Void'">
                    <span v-if="order.status != 'Refund'">
                      {{ paymentMethods }}
                    </span>
                    <span v-else>
                      {{ order.refunds.length > 0? order.refunds.join(): paymentMethods}}
                    </span>
                  </span>
                </td>
                <td>
                  <div
                    class="reciept_right"
                    v-if="order.status == 'Paid' && order.rental_facility_booking && order.rental_facility_booking.show_end_time"
                  >
                    <span v-if="order.facility_booking_details">
                      {{ order.order_date | dateformat }}
                      {{ order.rental_facility_booking.r_start_time | timeFormat }}
                      Till
                      {{ order.rental_facility_booking.r_end_time | timeFormat}}
                    </span>
                  </div>
                  <div class="reciept_right" v-else>
                    {{ order.order_date | dateformat }}
                    <span v-if="order.facility_booking_details">
                      {{
                        order.facility_booking_details.start_time | timeFormat
                      }}
                      to
                      {{ order.facility_booking_details.end_time | timeFormat }}
                    </span>
                    <span v-if="!order.facility_booking_details && order.facility_reschedule_details">
                      {{ order.facility_reschedule_details.start_time | timeFormat }}
                      to
                      {{ order.facility_reschedule_details.end_time | timeFormat }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr v-if="payments && payments.length > 0">
                <td>
                  <div class="reciept_title">
                    <span v-if="order.type != 'payment' || order.status == 'Rescheduled' || order.status == 'Void'">
                      {{ payments.map( (x) => cardName(x.card_type_id) + (x.card_number? "****" + x.card_number.slice(-4): " ")).join(" ") }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="reciept_title">
                    <span v-if="( order.type == 'payment' && order.status != 'Rescheduled') || order.status == 'Void'">Order Number :</span>
                    <span v-else-if="order.status == 'Refund'">Tax Credit Note Number :</span>
                    <span v-else>Tax Invoice Number:</span>
                  </div>
                </td>
                <td v-if="order.facility_booking_details || order.facility_reschedule_details">
                  <div class="reciept_right reciept_title">Facility</div>
                </td>
              </tr>
              <tr>
                <td>{{ order.order_seq_no ? order.order_seq_no : order.invoice_seq_no }}</td>
                <td v-if="order.facility_booking_details">
                  <div class="reciept_right">{{ order.facility_booking_details ? order.facility_booking_details.facility.name : "NA" }}</div>
                </td>
                <td v-if="!order.facility_booking_details && order.facility_reschedule_details">
                  <div class="reciept_right">{{ order.facility_reschedule_details ? order.facility_reschedule_details.facility.name : "NA" }}</div>
                </td>
              </tr>
            </table>

            <v-divider></v-divider>
            <div class="pa-3">
              <table class="reciept_details_item_table">
                <tr>
                  <th>Description</th>
                  <th>Price</th>
                  <th v-if="products.find((x) => x.product_type_id == 4)">Pro Rate</th>
                  <th>Quantity</th>
                  <th>Total Price</th>
                  <th v-if="order.discount">Discount</th>
                  <th v-if="order.discount">Price After<br />Discount</th>
                  <th v-if="order.trn">VAT Rate</th>
                  <th v-if="order.trn">VAT Amount</th>
                </tr>
                <tr v-for="item in products" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>
                    <span v-if="order.credit">{{ Number(order.credit) | toCurrency }}</span>
                    <span v-else-if="item.discount">{{ Math.abs(Number(item.actual_price / Number(item.quantity)))| toCurrency }}</span>
                    <span v-else>{{ Math.abs(Number( (item.price)/ Number(item.quantity) )) | toCurrency  }}</span>
                  </td>
                  <td v-if="item.product_type_id == 4">
                    <span v-if="item.discount">{{ item.actual_price | toCurrency }}</span>
                    <span v-else>{{ Math.abs(Number(item.price)) | toCurrency }}</span>
                  </td>
                  <td>{{ Math.abs(item.quantity.toFixed(2)) }}</td>
                  <td v-if="item.product_type_id == 4">
                    <span v-if="item.discount">{{ Math.abs(Number(item.actual_price * item.quantity))| toCurrency }}</span>
                    <span v-else>{{ Math.abs(Number(item.price * item.quantity)) | toCurrency }}</span>
                  </td>
                  <td v-else>
                      <span v-if="item.discount">{{ Math.abs(Number(item.actual_price)) | toCurrency }}</span>
                      <span v-else>{{ Math.abs(Number(item.price)) | toCurrency }}</span>
                  </td>
                  <td v-if="order.discount">{{ Math.abs(Number(item.discount_amount || 0)) | toCurrency }}</td>
                  <td v-if="order.discount">{{ Math.abs(Number(item.price)) | toCurrency }}</td>
                  <td v-if="order.trn">{{ item.tax_type }}</td>
                  <td v-if="order.trn && order.status != 'Complementary'">{{ Math.abs(Number(item.tax)) | toCurrency }}</td>
                  <td v-if="order.status == 'Complementary'">{{ Math.abs(Number(item.product_tax * item.quantity)) | toCurrency }}</td>
                </tr>
              </table>
            </div>
            <v-divider></v-divider>
            <table class="reciept_details_total_table pa-3">
              <tr v-if="order.received_amount">
                <td class="space"></td>
                <th>Previous Payment</th>
                <td>{{ Number(parseFloat(order.received_amount).toFixed(2)) | toCurrency }}</td>
              </tr>
              <tr v-if="order.discount">
                <td class="space"></td>
                <td class="space"></td>
                <td>Promotion:</td>
                <td>
                  {{ order.promotion_name }}
                  {{ order.promotion_code ? "(" + order.promotion_code + ")" : "" }}
                </td>
              </tr>
              <tr v-if="order.trn">
                <td class="space"></td>
                <td class="space"></td>
                <td>Sub Total Exclusive TAX</td>
                <td>{{ Number(parseFloat(Math.abs(order.price)).toFixed(2)) | toCurrency }}</td>
              </tr>
              <slot name="special_field"></slot>
              <tr>
                <td><div class="reciept_left reciept_title">Cashier</div></td>
                <td class="space"></td>
                <td v-if="order.trn">Tax Amount</td>
                <td v-if="order.trn">
                  {{ Number(parseFloat(Math.abs(order.tax)).toFixed(2)) | toCurrency }}
                </td>
              </tr>
              <tr>
                <td>
                  <div class="reciept_left">{{ order.cashier ? order.cashier : "NA" }}</div>
                </td>
                <td class="space"></td>
                <th v-if="order.status == 'Refund'">Grand Total Refunded</th>
                <th
                  v-else-if="
                    (order.type == 'payment' && order.status != 'Rescheduled') ||
                    (paymentMethods == 'NA' && order.status == 'Cancelled') ||
                    (paymentMethods == 'NA' && order.status == 'Void')
                  "
                >Grand Total</th>
                <th v-else>Grand Total Paid</th>
                <th>{{ Number(parseFloat(Math.abs(order.total)).toFixed(2)) | toCurrency }}</th>
              </tr>
              <tr v-if="credit && order.status == 'Paid'">
                <td class="space"></td>
                <td class="space"></td>
                <th>Balance Due</th>
                <th>{{ Number(parseFloat(Math.abs(credit.amount)).toFixed(2)) | toCurrency }}</th>
              </tr>
            </table>
            <p v-if="order.status == 'Void' && order.order_notes != null" class="pb-3 pl-4">
              <strong>Void Note:</strong> {{ order.order_notes }}
            </p>
            <p class="pb-3 pl-4" v-else-if="(order.status == 'Unpaid' || order.status == 'Paid' || order.status == 'Complementary') && order.order_notes != null">
              <strong>Order Note:</strong> {{ order.order_notes }}
            </p>
            <p v-else-if="order.status == 'Refund' && order.reason != null" class="pb-3 pl-4">
              <strong>Refund Note:</strong> {{ order.reason }}
            </p>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="payment"></slot>
        <v-btn
          v-if="
            checkWritePermission($modules.sales.refund.slug) &&
            order.status == 'Paid' &&
            order.order_type_id != 8 &&
            ( order.order_status == 'Pending' ||  order.order_status == 'Completed' || order.order_status == 'Partial Reschedule') &&
            order.total > 0 && !order.credit_settlement && order.invoice_generated != 1 && !order.credit_amount"
          class="ma-2 yellow-color"
          text
          @click="refund"
          >Refund</v-btn>

        <!-- TODO: add condition to check permission -->
        <v-btn
          class="ma-2 white--text red-color"
          v-if="
            order.status != 'Cancelled' &&
            order.order_status != 'Cancelled' &&
            order.order_status != 'Refund' &&
            order.order_status != 'Reservation Cancel' &&
            order.status != 'Refund' &&
            order.status != 'Void' &&
            order.status != 'Hidden' &&
            order.order_type_id != 7 &&
            order.invoice_generated != 1 &&
            checkWritePermission($modules.sales.void.slug)
          "
          text
          @click="voidTransaction()"
          >Void</v-btn>
        <v-btn
          v-if="order.type == 'payment' &&
              order.order_status != 'Rescheduled' &&
              order.order_status != 'Partial Reschedule' &&
              order.order_status != 'Reservation Cancel' &&
              order.order_status != 'Partial Cancel' &&
              order.order_status != 'Cancelled' &&
              order.invoice_generated != 1
          "
          class="ma-2 white--text blue-color"
          text
          @click="generateInvoicePay"
          >Pay</v-btn>
        <v-btn
          v-if="order.invoice_generated === 1 && order.order_status != 'Cancelled'"
          class="ma-2 white--text blue-color"
          text
          @click="generateInvoice"
        >Invoice</v-btn>
        <v-btn
          v-else-if="
            order.type == 'payment' &&
            order.order_status != 'Rescheduled' &&
            order.order_status != 'Partial Reschedule' &&
            order.order_status != 'Reservation Cancel' &&
            order.order_status != 'Partial Cancel' &&
            order.order_status != 'Cancelled' &&
            order.order_status != 'Refund' &&
            order.order_status != 'Void' &&
            order.invoice_generated != 1
          "
          class="ma-2 white--text blue-color"
          text
          @click="generateInvoice"
        >Generate Invoice</v-btn>
      </v-card-actions>
    </v-card>
    <refund
      v-if="refund_dialog"
      :show="refund_dialog"
      @close="closeRefund"
      @refunded="refundComplete"
    ></refund>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>

    <confirm-model
      v-bind="confirmInputModel"
      @confirm="confirmActions"
      @close="confirmInputModel.id = null"
    >
      <template v-slot:content>
        <v-textarea
          class="mt-3"
          v-model="voidNote"
          rows="3"
          label="Note"
          outlined
        ></v-textarea>
      </template>
    </confirm-model>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import refund from "./Refund.vue";

export default {
  computed: {
    ...mapGetters({
      customer: "getOrderCustomer",
      products: "getOrderItems",
      order: "getOrderDetails",
      payments: "getOrderPayments",
      paymentMethods: "getOrderPaymentMethods",
      isCreditPayment: "isCreditPayment",
    }),
    credit() {
      let credits = this.payments.filter( (item) => item.payment_method == "Credit Facility");
      if (credits.length > 0) {
        return credits[0];
      } else {
        return null;
      }
    },
  },
  components: {
    refund,
  },
  props: {
    isPrintPdf: { type: Boolean, default: false },
  },

  watch: {
    isPrintPdf: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.printPdf();
          });
        }
      },
    },
  },

  data() {
    return {
      paymentModel: { invoiceId: null, type: "details", orderIds: [] },
      reciept_dialog: false,
      refund_dialog: false,
      confirmModel: {},
      confirmInputModel: {},
      invoiceData: {},
      voidNote: "",
      invoiceId: null,
      invoiceModel: { invoiceId: null, type: "details", orderIds: [] },
    };
  },
  methods: {
    openMainOrderDetails(id) {
      this.$emit("openMainOrderDetails", id);
    },
    closeInvoiceModel() {
      this.invoiceModel.invoiceId = null;
      this.$store.dispatch("loadOrderDetails", this.order.id).then((response) => {
        if (response.status == 200) {
          this.orderDialog = true;
          this.hideLoader();
        }
      });
    },
    generateInvoicePay() {
      if (this.order.invoice_generated === 0) {
        this.showLoader("Generating invoice ...");
        let formData = new FormData();
        formData.append(`order_ids[0]`, this.order.id);
        this.$http.post("venues/invoices", formData).then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status) {
              let data = response.data.data;
              this.$emit("openPayModel", data.id);
            } else {
              this.showError("Unable to generate invoice");
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
      } else {
        this.$emit("openPayModel", this.order.invoice_item.invoice_id);
      }
    },
    openPayInvoice(invoice_id) {
      this.showLoader("Loading..");
      this.$store.dispatch("loadInvoiceDetails", invoice_id)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            let data = response.data.data;
            this.paymentModel.invoiceId = data.id;
            this.payInvoiceData = data;
            this.payInvoiceData.invoiceId = data.id;
            this.payInvoiceData.wallet_redeem_amount = 0;
            console.log(this.paymentModel);
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    generateInvoice() {
      if (this.order.invoice_generated === 1) {
        this.$emit("openInvoice", this.order.invoice_item.invoice_id);
      } else {
        this.showLoader("Generating invoice ...");
        let formData = new FormData();
        formData.append(`order_ids[0]`, this.order.id);
        this.$http
          .post("venues/invoices", formData)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status) {
              let data = response.data.data;
              this.$emit("openInvoice", data.id);
              // this.invoiceData = data;
              // this.invoiceModel.invoiceId = data.id;
              // this.invoiceModel.orderIds = this.selectedOrders;
            } else {
              this.invoiceModel.invoiceId = null;
              this.invoiceModel.orderIds = [];
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
      }
    },
    closeInvoiceDetails() {
      this.invoiceId = null;
    },
    pay() {
      this.$emit("pay");
    },
    closeReceipt() {
      this.refund_dialog = false;
      this.$emit("close");
    },
    refundComplete() {
      this.closeReceipt();
      this.$emit("refund");
    },
    closeRefund() {
      this.refund_dialog = false;
    },
    refund() {
      this.refund_dialog = true;
    },
    downloadPdf(type) {
      this.showLoader("Generating..");
      this.$http
        .get(`venues/orders/pdf/${this.order.id}?type=${type}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.printFile(response);
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    mailReciept() {
      this.showLoader("Mailing..");
      if (this.customer && this.customer.email) {
        let url = "venues/orders/mail/" + this.order.id;
        this.$http
          .get(url)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.showSuccess("Email Send Successfully.");
              this.hideLoader();
              this.$emit("close");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
    },

    cancel() {
      this.confirmModel = {
        id: this.order.id,
        title: "Do you want to cancel this order?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "cancel",
      };
    },

    voidTransaction() {
      this.voidNote = this.order.order_notes;
      this.confirmInputModel = {
        id: this.order.id,
        title:
          "Do you want to void all paid/unpaid transactions associated with this booking?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "void",
      };
    },

    printPdf() {
      this.confirmModel = {
        id: 1,
        title: "Would you like to print the receipt?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "print",
      };
    },

    confirmActions(data) {
      if (data.type == "remove_payment_method") {
        setTimeout(() => {
          this.$store.commit("removePaymentMethod", data.data.index);
        });
      } else if (data.type == "cancel") {
        this.$emit("cancel");
      } else if (data.type == "void") {
        this.$emit("void", { note: this.voidNote });
      } else if (data.type == "print") {
        this.downloadPdf("a4");
      } else if (data.type == "payment_link") {
        console.log("send payment link");
        this.$emit("sendPaymentLink", { order_id: data.id });
      }
      this.confirmModel.id = null;
    },

    cardName(id) {
      if (this.$store.getters.getCardTypes.status == false) {
        this.$store.dispatch("loadCardTypes").then((response) => {
          console.log(response, id);
          let card = response.find((x) => x.id == id);
          if (card) {
            return card.name;
          }
        });
      } else {
        let card = this.$store.getters.getCardTypes.data.find(
          (x) => x.id == id
        );
        if (card) {
          return card.name;
        }
      }
      return " ";
    },

    emailLinkConfirmation() {
      this.confirmModel = {
        id: this.order.id,
        title: "Do you want to send payment link to customer email?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "payment_link",
      };
    },
  },
};
</script>