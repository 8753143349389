<template>
  <div>
    <v-dialog
        v-model="multiInvoiceDialog"
        @input="close"
        :max-width="950"
        class="no-shadow"
        scrollable
        persistent
    >
      <v-card ref="recieptPrint" :max-width="800" style="margin:0 auto;">
        <slot name="carosal"></slot>
        <v-card-text class="pa-0 ma-0">
          <div class="receipt_details">
            <v-row class="ma-0">
              <v-col class="text-left pt-5" cols="10" md="10">
                <div class="invoice-title">
                  <v-btn icon>
                    <SvgIcon>
                      <template v-slot:icon>
                        <ReceiptIcon />
                      </template>
                    </SvgIcon>
                  </v-btn>
                  <span>
                      Batch # {{ getBatchId() }}
                    </span>
                  <span class="display-block">{{ getInvoiceDate() | timeStamp }}</span>
                </div>
              </v-col>
              <v-col class="text-right pt-5" cols="2" md="2">
                <v-btn icon   title="Send Email"   v-if="multiInvoiceData[0] && multiInvoiceData[0].customer"  @click="mailReciept()">
                  <SvgIcon>
                    <template v-slot:icon>
                      <EmailIcon />
                    </template>
                  </SvgIcon>
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on"  title="Print Invoice" class="ml-2 mr-2">
                      <SvgIcon>
                        <template v-slot:icon>
                          <PrintIcon />
                        </template>
                      </SvgIcon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="downloadPdf('pos')">
                      <v-list-item-title>Print POS</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="downloadPdf('a4')">
                      <v-list-item-title>Print A4</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

              </v-col>
            </v-row>
            <v-divider :color="deviderColor()"></v-divider>
          </div>
          <div class="receipt_details_body pa-4">
            <div class="new-venue-info-table" v-if="multiInvoiceData[0] && multiInvoiceData[0].customer">
              <div class="d-flex flex-column border-bottom  pt-2 pb-2">
                <div class="d-flex justify-space-between ">
                  <div class="">
                    <span class="v-title">Customer Name: </span>
                    <span class="v-name d-block">{{ multiInvoiceData[0].customer?multiInvoiceData[0].customer.name:'-' }}</span>
                  </div>
                  <div class="">
                    <span class="v-title">Mobile: </span>
                    <span class="v-name d-block">{{ multiInvoiceData[0].customer && multiInvoiceData[0].customer.customer_contact? multiInvoiceData[0].customer.customer_contact.mobile: "-" }}</span>
                  </div>
                  <div class="">
                    <span class="v-title">Email: </span>
                    <span class="v-name d-block">{{ multiInvoiceData[0].customer && multiInvoiceData[0].customer.customer_contact? multiInvoiceData[0].customer.customer_contact.email: "-" }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(invoiceData,index) in multiInvoiceData" :key="`i_${index}`">
              <div class="new-venue-info-table venue-details pa-2 ">

                <v-row>
                  <v-col cols="4">
                    <div class="pb-1">
                      <span class="v-title">Venue: </span>
                      <span class="v-name">{{ invoiceData.venue_name?invoiceData.venue_name:'-' }}</span>
                    </div>
                  </v-col>
                  <v-col cols="4" class="text-center">
                    <div class="pb-1">
                      <span class="v-title">Channel: </span>
                      <span class="v-name">{{ invoiceData.source?invoiceData.source:'-' }}</span>
                    </div>
                  </v-col>
                  <v-col cols="4" class="text-right">
                    <div class="pb-1">
                                    <span class="v-name" v-if="invoiceData.status && invoiceData.status.name == 'Unpaid'">
                                        <span class="v-title">Invoice: </span> #{{ invoiceData.invoice_seq_no }}
                                    </span>
                      <span class="v-name" v-else-if="invoiceData.status && (invoiceData.status.name == 'Paid' || invoiceData.status.name == 'Complementary')">
                                        <span class="v-title">Tax Invoice: </span>{{ invoiceData.receipt_no }}
                                    </span>
                      <span class="v-name" v-else-if="invoiceData.status && invoiceData.status.name == 'Void'">
                                        <span class="v-title">(Void): </span>{{ invoiceData.receipt_no? invoiceData.receipt_no: invoiceData.invoice_seq_no}}
                                    </span>
                      <span class="v-name" v-else-if="invoiceData.status && invoiceData.status.name == 'Cancelled'">
                                        <span class="v-title">Tax Invoice: </span> {{invoiceData.receipt_no? invoiceData.receipt_no: invoiceData.invoice_seq_no}}
                                    </span>
                      <span class="v-name" v-else-if="invoiceData.status && invoiceData.status.name == 'Refund'"><span class="v-title">Tax Credit Note: </span>{{ invoiceData.receipt_no }}</span>
                      <span class="v-name"
                            v-if="( invoiceData.invoice_status.name != 'Pending' && (invoiceData.invoice_status.name == 'Rescheduled' || invoiceData.invoice_status.name == 'Partial Reschedule')) ||
                                            invoiceData.invoice_status.name == 'Partial Cancel' ||
                                            invoiceData.invoice_status.name == 'Partial Refund' ||
                                            invoiceData.invoice_status.name == 'Refund' ||
                                            invoiceData.invoice_status.name == 'Cancelled' ||
                                            invoiceData.invoice_status.name == 'Reservation Cancel'
                                        ">({{ invoiceData.invoice_status.name }})</span>
                    </div>
                  </v-col>


                </v-row>
              </div>
              <div class="d-flex flex-column pa-0  venue-info-table pb-2">
                <div class="d-flex justify-space-between ">
                  <span class="v-title">Invoice Items:</span>
                </div>
              </div>
              <InvoiceOrderTable
                  @openOrderDetails="openOrderDetails"
                  :orders="invoiceData.items"
                  :isInvoiceHasOrderDiscount="isInvoiceHasOrderDiscount"
              ></InvoiceOrderTable>
              <table class="receipt_details_total_table venue-info-table">
                <tr v-if="invoiceData.promotion_name">
                  <td class="space"></td>
                  <td class="space"></td>

                  <td class="v-title">Promotion:</td>
                  <td>
                    {{ invoiceData.promotion_name }}
                    {{ invoiceData.promotion_code ? "(" + invoiceData.promotion_code + ")" : "" }}
                  </td>
                </tr>
                <tr>
                  <td class="space"></td>
                  <td class="space"></td>
                  <td class="v-title">Total (Inclusive Tax)</td>
                  <td class="v-title">{{ invoiceData.total.toFixed(2) | toCurrency }}</td>
                </tr>
              </table>
            </div>
            <div class="payment-total-div mt-5">
              <div class="d-flex flex-row">
                <div class="d-flex flex-column venue-info-table pa-2">
                  <div class="pt-2 d-flex flex-column" v-if="getOrderPaymentMethods() != 'NA'">
                    <span class="v-title">Payments:</span>
                    <span class="v-name ">{{ getOrderPaymentMethods() }}</span>
                  </div>
                  <div class="pt-2 d-flex flex-column">
                    <span class="v-title">Cashier:</span>
                    <span class="v-name">{{ getCashierName() }}</span>
                  </div>

                </div>
                <div class="d-flex flex-column venue-info-table payment-summary pa-2 border-primary">
                  <div class="pt-2 d-flex">
                    <span class="v-title font-16">Summary:</span>
                  </div>
                  <div class="pt-2 d-flex">
                    <span class="v-name me-auto">Subtotal for all venues: </span>
                    <span class="v-name">{{ subTotal | toCurrency }}</span>
                  </div>
                  <div class="pt-2 pb-2 d-flex">
                    <span class="v-name me-auto">Tax: </span>
                    <span class="v-name"> {{ totalTax | toCurrency }}</span>
                  </div>
                  <div class="pt-2 d-flex" style="border-top:1px solid #DBDBDB;">
                    <span class="v-title me-auto">Grand Total: </span>
                    <span class="v-title"> {{ total | toCurrency }}</span>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="ma-2 text-capitalize" text @click="close">Close</v-btn>
          <!-- <v-menu offset-y :key="`p_`">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2 white--text blue-color"
                text
                v-on="on"
                v-bind="attrs"
                >Email Payment Link
                <v-icon right dark>
                  mdi-menu-down
                </v-icon></v-btn
              >
            </template>
            <v-list  v-if="getCustomerList && getCustomerList.length" >
              <v-list-item style="cursor: pointer;"  v-for="(customer,index) in getCustomerList" :key="index" @click="emailLinkConfirmation(customer.id)">
                <v-list-item-title >{{  customer.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
          <v-spacer></v-spacer>
          <v-btn
              v-if="isShowPayBtn"
              @click="paymentPopup"
              class="ma-2 white--text blue-color"
              text>
            Pay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <MultiPaymentModel :multiInvoiceData="multiInvoiceData" :multiPaymentDialog="multiPaymentDialog" @payed="payed"
                       @close="closeMultiPaymentDialog"/>
  </div>

</template>

<script>
import InvoiceOrderTable from "@/components/Invoice/InvoiceOrderTable.vue";
import MultiPaymentModel from "@/components/Invoice/MultiPaymentModel.vue";
import EmailIcon from "@/assets/images/misc/invoice-email.svg";
import PrintIcon from "@/assets/images/misc/invoice-print.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ReceiptIcon from "@/assets/images/misc/receipt-item.svg";

export default {
  components: {
    ReceiptIcon, SvgIcon, EmailIcon, PrintIcon,
    InvoiceOrderTable,
    MultiPaymentModel
  },
  props: {
    multiInvoiceDialog: {type: Boolean, default: false},
    multiInvoiceData: {type: Array, default: () => []},
  },
  watch: {
    multiInvoiceDialog(val) {
      if (val) {
        this.mapPayments();
      }
    },
  },
  mounted() {
    this.mapPayments();
  },
  data() {
    return {
      confirmInputModel: {},
      payments: [],
      isInvoiceHasOrderDiscount: false,
      multiPaymentDialog: false,
    };
  },
  computed: {
    getCustomerList() {
      const customerList = [];
      if (this.multiInvoiceData) {
        this.multiInvoiceData.forEach((invoiceData) => {
          if (invoiceData.items && invoiceData.items.length > 0) {
            invoiceData.items.forEach(item => {
              if (item.order && item.order.customer) {
                if (item.order.customer.id) {
                  let name = item.order.customer.first_name + ' ' + item.order.customer.last_name;
                  customerList.push({id: item.order.customer.id, name: name});
                }
              }
            });
          }
        })
      }
      return customerList;
    },
    total() {
      return this.multiInvoiceData.reduce((p, c) => p + c.total, 0);
    },
    subTotal() {
      return this.multiInvoiceData.reduce((p, c) => p + c.price, 0);
    },
    totalTax() {
      return this.multiInvoiceData.reduce((p, c) => p + c.tax, 0);
    },
    isShowPayBtn() {
      let status = true;
      this.multiInvoiceData.forEach((invoiceData) => {
        if (invoiceData.status_id === 4) {
          status = false;
        }
      });
      return status;
    },
  },
  methods: {
    getBatchId() {
      return this.multiInvoiceData && this.multiInvoiceData[0] && this.multiInvoiceData[0].batch_id ? this.multiInvoiceData[0].batch_id : 'NA';
    },
    getInvoiceDate() {
      if (this.multiInvoiceData && this.multiInvoiceData[0]) {
        if (this.multiInvoiceData[0].paid_at) {
          return this.multiInvoiceData[0].paid_at;
        } else {
          return this.multiInvoiceData[0].created_at_invoice;
        }
      }
      return 'NA';
    },
    getCashierName() {
      if (this.multiInvoiceData[0]) {
        return this.multiInvoiceData[0].cashier ? this.multiInvoiceData[0].cashier.name : this.multiInvoiceData[0].invoice_by ? this.multiInvoiceData[0].invoice_by.name : ""
      } else {
        return "NA";
      }

    },
    getOrderPaymentMethods() {
      let invoicePayments = this.payments;
      if (invoicePayments.length) {
        var countryDetails = JSON.parse(localStorage.getItem("country")) || {};
        let payments = invoicePayments.filter((item) => {
          return item.payment_method;
        }).map((method) =>
            `${method.payment_method} (${countryDetails.currency_code ? countryDetails.currency_code : "AED"} ${Math.abs(method.amount)})`
        ).join(", ");
        if (payments) return payments;
        return "NA";
      }
      return "NA";
    },
    mapPayments() {

      this.multiInvoiceData.forEach((invoiceData) => {
        invoiceData.payments.forEach((payment) => {
          // Check if the payment with the same payment_method_id already exists
          let existingPayment = this.payments.find(p => p.payment_method_id === payment.payment_method_id);

          if (existingPayment) {
            // If it exists, update the amount by adding the new payment total
            existingPayment.amount += payment.total;
          } else {
            // If it doesn't exist, push the new payment to the array
            this.payments.push({
              id: payment.id,
              card_type_id: payment.card_type_id,
              payment_code: payment.payment_code,
              payment_method_id: payment.payment_method_id,
              amount: payment.total,
              payment_method: payment.payment_method.name,
              card_number: payment.card_number,
            });
          }
        });
      });
    },
    cardName(id) {
      if (this.$store.getters.getCardTypes.status == false) {
        this.$store.dispatch("loadCardTypes").then((response) => {
          let card = response.find((x) => x.id == id);
          if (card) {
            return card.name;
          }
        });
      } else {
        let card = this.$store.getters.getCardTypes.data.find(
            (x) => x.id == id
        );
        if (card) {
          return card.name;
        }
      }
      return " ";
    },
    confirmActions(data) {
      if (data.type == "payment_link") {
        this.$emit("sendPaymentLink", {customer_id: data.id, invoice_id: this.invoiceId});
      }
      this.confirmInputModel.id = null;
    },

    close() {
      this.$emit("close");
    },
    openOrderDetails(id) {
      this.$emit("openMainOrderDetails", id);
    },
    downloadPdf(type) {
      this.showSuccess("Under Development: ", type);
      //
      // this.showLoader("Generating..");
      // this.$http
      // .get(`venues/invoices/pdf/${this.invoiceId}?type=${type}`, {
      //     responseType: "blob",
      // })
      // .then((response) => {
      //     if (response.status == 200) {
      //     this.printFile(response);
      //     this.hideLoader();
      //     }
      // })
      // .catch((error) => {
      //     this.errorChecker(error);
      // });
    },
    mailReciept() {
      this.showSuccess("Under Development Mailing..");
      // if (this.invoiceData.customer) {
      // let url = "venues/invoices/mail/" + this.invoiceId;
      // this.$http
      //     .get(url)
      //     .then((response) => {
      //     if (response.status == 200 && response.data.status == true) {
      //         this.showSuccess("Email Send Successfully.");
      //         this.hideLoader();
      //     }
      //     })
      //     .catch((error) => {
      //     this.errorChecker(error);
      //     });
      // }
    },

    emailLinkConfirmation(customerId) {
      this.confirmInputModel = {
        id: customerId,
        title: "Do you want to send payment link to customer email?",
        description: "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "payment_link",
      };
    },
    deviderColor() {
      if (this.multiInvoiceData[0]) {
        if (this.multiInvoiceData[0].status && (this.multiInvoiceData[0].status.name == 'Paid' || this.multiInvoiceData[0].status.name == 'Complementary')) {
          return "#60A561";
        } else if (this.multiInvoiceData[0].status && this.multiInvoiceData[0].status.name == 'Void') {
          return "#E50000";
        } else if (this.multiInvoiceData[0].status && this.multiInvoiceData[0].status.name == 'Refund') {
          return "#FFA500";
        } else {
          return "#F1F1F1";
        }
      } else {
        return "#F1F1F1";
      }

    },
    paymentPopup() {
      this.multiPaymentDialog = true;
      // this.showSuccess("Multi invoice payment under development");
      // this.$emit("multivenue-payment");
    },
    closeMultiPaymentDialog() {
      this.isInvoiceHasOrderDiscount = false;
      this.payments = [];
      this.$store.dispatch('resetPaymentMethod');
      this.multiPaymentDialog = false;
    },

    payed() {
      this.closeMultiPaymentDialog();
      this.$emit("payed", this.multiInvoiceData[0].id);
    }
  },
};
</script>

<style scoped>
/deep/ .v-dialog {
  box-shadow: none !important;
}

.v-sheet.v-card {
  border-radius: 10px;
}

span.display-block {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-left: 40px;
}

.invoice-title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.venue-info-table {
  width: 100%;
  margin: 0px auto;
}

.receipt_details_total_table {
  text-align: right;
  width: 100%;
}

.receipt_details_total_table tr td {
  padding: 6px;
  color: #112a45;
}

.receipt_details_total_table .space {
  width: 186px;
}

.new-venue-info-table .v-title, .venue-info-table .v-title {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin: 0px;
}

.new-venue-info-table .v-name, .venue-info-table .v-name {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin: 0px;
}

.venue-details {
  margin: 10px 0px;
  background: #f7f7f5;
  border: 1px solid #f7f7f5;
  border-radius: 10px;
}

.border-right-1 {
  border-right: 1px solid #e7e7e7;
}


.receipt_details_body {
  background: #fff;
  padding: 8px;
}

.inv-sm-circle {
  color: #112a45;
  background: rgba(17, 42, 69, 0.1);
}

th.upper-border {
  padding-top: 2px !important;
  padding-right: 6px !important;
  border-top: dashed 1px black !important;
}

.venue-info-table.payment-summary {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}
</style>
  