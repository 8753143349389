<template>
  <v-autocomplete
    outlined
    background-color="#fff"
    :items="voucherEntries"
    :loading="isVoucherLoading"
    :search-input.sync="search"
    label="Voucher Code"
    placeholder="Voucher Code"
    item-text="voucher_code"
    @change="onVoucherSelect"
    return-object
    v-model="voucher"
  >
    <template v-slot:selection="{ attr, on, item, selected }">
      <span>
        <span>CODE: {{ item.voucher_code }}</span>
        <v-chip
          v-bind="attr"
          :input-value="selected"
          color="blue-grey"
          class="white--text ml-2"
          v-on="on"
        >
          <span v-if="redeem_balance > 0"
            >Bal. {{ parseFloat(redeem_balance) | toCurrency }}</span
          >
          <span v-else
            >Bal. {{ parseFloat(item.current_balance) | toCurrency }}</span
          >
        </v-chip>
      </span>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        color="teal"
        tile
        width="120"
        class="headline font-weight-light white--text"
      >
        {{ parseFloat(item.current_balance) | toCurrency }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.voucher_code"></v-list-item-title>
        <v-list-item-subtitle v-if="item.expiry_date != null"
          >Ex. {{ item.expiry_date | dateformat }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    redeem_price: null,
  },
  data() {
    return {
      voucherEntries: [],
      isVoucherLoading: false,
      voucher: null,
      search: null,
      redeem_balance: null,
    };
  },
  watch: {
    redeem_price(val) {
      this.redeem_balance = 0;
      if (val == null || val <= 0) return;
      if (this.voucher == null || this.voucher.current_balance <= 0) return;
      if (parseFloat(this.voucher.current_balance) < parseFloat(val)) {
        this.showError("Entered amount is greater than Voucher Balance");
        return;
      }
      this.redeem_balance =
        parseFloat(this.voucher.current_balance) - parseFloat(val);
    },
    search(val) {
      if (val == "" || val == null) return;
      if (
        typeof this.voucher != "undefined" &&
        this.voucher != null &&
        this.voucher.voucher_code != null &&
        this.voucher.voucher_code == val
      )
        return;
      if (this.isVoucherLoading) return;
      this.isVoucherLoading = true;
      this.searchMethod(val);
    },
  },
  methods: {
    onVoucherSelect() {
      this.$emit("selected", this.voucher);
    },
    searchMethod(val) {
      this.$http
        .get("venues/vouchers/search?code=" + val)
        .then((response) => {
          if (response.status == 200) {
            this.isVoucherLoading = false;
            this.voucherEntries = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style></style>
