<template>
  <v-card
    flat
    class="pa-0 mt-4"
    style="border-bottom:1px solid rgba(0, 0, 0, 0.08);"
  >
    <v-row dense>
      <v-col md="6">
        <v-select
          label="Payment Method"
          outlined
          background-color="#fff"
          :readonly="id != null"
          :value="payment_method_id"
          item-text="name"
          item-value="id"
          :items="newPaymentMethods"
          :rules="[(v) => !!v || 'Payment method is required']"
          return-object
          @change="fieldChange('payment_method', $event)"
        ></v-select>
      </v-col>
      <v-col md="6" v-if="payment_method == 'Card'">
        <v-select
          label="Card Type"
          outlined
          background-color="#fff"
          item-text="name"
          item-value="id"
          :value="card_type_id"
          :rules="cardTypeRule()"
          @change="fieldChange('card_type_id', parseInt($event))"
          :items="cardTypes"
        ></v-select>
      </v-col>
      <v-col md="6" v-if="payment_method == 'Card'">
        <v-text-field
          outlined
          background-color="#fff"
          :value="card_holder_name"
          @change="fieldChange('card_holder_name', $event)"
          label="Card Name"
        ></v-text-field
      ></v-col>
      <v-col md="6" v-if="payment_method == 'Card'">
        <v-text-field
          outlined
          background-color="#fff"
          :value="card_number"
          @change="fieldChange('card_number', $event)"
          label="Card Number"
          maxlength="19"
          pattern="[0-9]*"
          v-model="creditCardNumber"
          :rules="cardNumberRule()"
        ></v-text-field>
      </v-col>
      <v-col md="6" v-if="payment_method == 'Card'">
        <v-text-field
          outlined
          background-color="#fff"
          :value="payment_code"
          :rules="authorizationCodeRule()"
          @change="fieldChange('payment_code', $event)"
          label="Authorization Code"
        ></v-text-field
      ></v-col>
      <v-col md="6" v-if="payment_method == 'Voucher' && id == null">
        <search-voucher
          @selected="voucherSelection($event, index)"
        ></search-voucher>
      </v-col>
      <v-col md="6">
        <v-text-field
          :disabled="payment_method == 'Complementary' || payment_method == 'QPoints'"
          outlined
          background-color="#fff"
          @change="fieldChange('amount', parseFloat($event))"
          :rules="paymentRule"
          :value="amount"
          label="Payment Amount"
          :readonly="id != null"
          :prefix="currencyCode"
        >
          <template v-slot:append>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="autofillOrderAmount" v-on="on">
                  mdi-cash-plus
                </v-icon>
              </template>
              Autofill amount
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-card-actions>
      <div>
        <v-icon slot="icon" color="gray" size="32">
          mdi-{{ payment_method == "Card" ? "credit-card-outline" : "cash-multiple" }}
        </v-icon>
        {{ payment_method }} payment {{ amount ? " of " + amount : "" }}
        {{ payment_code ? "(Authorization code: " + payment_code + ")" : "" }}
      </div>
      <v-spacer></v-spacer>
      <v-btn
        title="Remove Payment Method"
        outlined
        color="red"
        v-if="count > 1"
        @click="removePaymentMethod()"
        :icon="true"
      >
        <v-icon small right>mdi-delete-alert-outline</v-icon></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import SearchVoucher from "@/components/Voucher/SearchVoucher";
import { mapGetters } from "vuex";

export default {
  components: {
    "search-voucher": SearchVoucher,
  },
  props: {
    id: { type: Number, default: null },
    payment_method: { type: String, default: "Cash" },
    payment_code: { type: String, default: "Cash" },
    card_number: { type: String, default: null },
    card_holder_name: { type: String, default: null },
    payment_method_id: { type: Number, default: null },
    card_type_id: { type: Number, default: null },
    amount: { type: Number, default: null },
    index: { type: Number, default: null },
    advanceAmount: { type: Number, default: null },
    updateCommitType: { type: String, default: "updatePaymentMethod" },
    totalAmountFromRepeat: { type: Number, default: null },
    payInvoiceData: { type: Object, default: () => {} },
    refundAmount: { type: Number, default: null },
    wallet_redeem_amount: { type: Number, default: 0 },
    posTotal: { type: Number, default: 0 },
    isPosPayment: { type: Boolean, default: false },
    wallet: {type: Object, default: () => {}  },
  },
  data() {
    return {
      voucherAmt: null,
      newPaymentMethods: [],
      creditCardNumber: "",
      creditCardType: null,
    };
  },
  watch: {
    creditCardNumber() {
      if (this.creditCardNumber) {
        let realNumber = this.creditCardNumber.replace(/-/gi, "");
        let dashedNumber = realNumber.match(/.{1,4}/g);
        this.creditCardNumber = dashedNumber.join("-");
      }
    },
  },
  mounted() {
    if (!this.$store.getters.getSalesConfig) {
      this.$store.dispatch("loadVenueSalesConfig");
    }
    if (this.$store.getters.getPaymentMethods.status == false) {
      this.$store
        .dispatch("loadPaymentMethods", "normal")
        .then(() => (this.newPaymentMethods = this.getPaymentMethods()));
    } else {
      this.newPaymentMethods = this.getPaymentMethods();
    }
    if (this.$store.getters.getCardTypes.status == false) {
      this.$store.dispatch("loadCardTypes");
    }
  },
  computed: {
    ...mapGetters({
      customer: "getOrderCustomer",
      order: "getOrderDetails",
      invoice: "getInvoiceDetails",
    }),
    salesConfig() {
      return this.$store.getters.getSalesConfig;
    },
    payments() {
      return this.$store.getters.getInvoicePayments;
    },
    repeatPayments() {
      return this.$store.getters.getRepeatPayments;
    },
    paymentMethods() {
      return this.$store.getters.getPaymentMethods.data;
    },
    cardTypes() {
      return this.$store.getters.getCardTypes.data;
    },
    count() {
      if (this.updateCommitType == "updatePaymentMethod") {
        return this.$store.getters.getInvoicePaymentCount;
      }
      if (this.updateCommitType == "updateRepeatPaymentMethod") {
        return this.$store.getters.getRepeatPaymentCount;
      }
      return this.$store.getters.getCreditPaymentCount;
    },
    paymentRule() {
      const rules = [];
      if (this.payment_method == "Complementary") {
        return rules;
      }
      let rule = (v) => {
        if (!v && v != 0) {
          return "Amount is required";
        }
        return true;
      };
      rules.push(rule);
      if (this.voucherAmt) {
        rule = (v) =>
          (!isNaN(parseFloat(v)) && v >= 0 && v <= this.voucherAmt) ||
          "Amount is higher than voucher amount";
        rules.push(rule);
      }
      return rules;
    },
    paymentTotal() {
      if (this.updateCommitType == "updatePaymentMethod") {
        return this.$store.getters.getInvoicePaymentTotal;
      }
      if (this.updateCommitType == "updateRepeatPaymentMethod") {
        return this.$store.getters.getRepeatPaymentTotal;
      }
      return this.$store.getters.getCreditPaymentTotal;
    },
  },
  methods: {
    getFilteredPaymentMethodIds() {
      if (this.updateCommitType == "updatePaymentMethod") {
        return this.payments
          .map((a) => a.payment_method_id)
          .filter((n) => n && n != 2);
      } else if (this.updateCommitType == "updateRepeatPaymentMethod") {
        return this.repeatPayments
          .map((a) => a.payment_method_id)
          .filter((n) => n && n != 2);
      } else if (this.updateCommitType == "updateCreditPaymentMethod") {
        return this.payments
          .map((a) => a.payment_method_id)
          .filter((n) => n && n != 5);
      } else {
        return [];
      }
    },
    getPaymentMethods() {
      const pm = this.$store.getters.getPaymentMethods.data;
      let filteredIds = this.getFilteredPaymentMethodIds();
      return pm.filter((item) => !filteredIds.includes(item.id));
    },
    autofillOrderAmount() {
      if (this.totalAmountFromRepeat) {
        let balance =
          this.totalAmountFromRepeat -
          this.paymentTotal +
          (this.amount > 0 ? this.amount : 0);
        this.fieldChange("amount", parseFloat(balance).toFixed(2));
      } else if (this.invoice.credit_amount) {
        let balance =
          this.invoice.credit_amount -
          this.paymentTotal +
          (this.amount > 0 ? this.amount : 0);

        this.fieldChange("amount", parseFloat(balance).toFixed(2));
      } else if (this.advanceAmount) {
        this.fieldChange(
          "amount",
          parseFloat(this.advanceAmount - this.paymentTotal).toFixed(2)
        );
      } else if (this.isPosPayment) { 
        let balance = this.posTotal - (this.amount > 0 ? this.amount : 0);
        this.fieldChange("amount", parseFloat(balance).toFixed(2));
      }else {
        let balance =
          this.invoice.total -
          this.payInvoiceData.wallet_redeem_amount -
          this.paymentTotal +
          (this.amount > 0 ? this.amount : 0);
        this.fieldChange("amount", parseFloat(balance).toFixed(2));
      }
      this.$forceUpdate();
    },
    removePaymentMethod() {
      this.$emit("remove", { id: this.id, index: this.index });
    },
    fieldChange(field, data) {
      if (field == "payment_method") {
        this.creditCardNumber = "";
        this.$forceUpdate();
        this.$emit("refresh");
      }
      if (field == "payment_method" && data.name != "Voucher") {
        this.voucherAmt = null;
      }
      /** If payment method is complementary then not allowed other payment methods at the same time */
      if (field == "payment_method" && data.name == "Complementary" && this.$store.getters.getInvoicePayments.length > 1) {
        this.$store.commit("removePaymentMethod",this.$store.getters.getInvoicePayments.length - 1);
        this.showError("Complementary not support in multiple payments");
        return;
      } else if (field == "payment_method" && data.name == "Complementary") {
          this.$store.commit(this.updateCommitType, {
            field: "amount",
            value: null,
            index: this.index,
          });
      }
      /** If payment method is wallet then check the wallet amount exist or not */
      if (field == "payment_method" && data.name == "Wallet") {
        let amount;
        if (typeof this.customer.wallet == "undefined" ||  this.customer.wallet == 0) {
          this.$store.commit(this.updateCommitType, {
            field: "payment_method_id",
            value: 1,
            index: this.index,
          });
          this.$store.commit(this.updateCommitType, {
            value: "Cash",
            field: "payment_method",
            index: this.index,
          });
          this.showError("You don’t have sufficient balance");
          this.$forceUpdate();
          return;
        }
        if (this.isPosPayment) {
          if (this.customer.wallet > this.posTotal) {
            amount = this.posTotal;
          } else {
            amount = this.customer.wallet;
          }
        } else {
          if (this.customer.wallet > this.invoice.total) {
            amount = this.invoice.total;
          } else {
            amount = this.customer.wallet;
          }
        }
        this.$store.commit(this.updateCommitType, {
          field: "amount",
          value: amount,
          index: this.index,
        });
      }else if (field == "payment_method" && data.name == "QPoints") {
        let amount;
        if (typeof this.wallet.points == "undefined" || (this.wallet.points == 0 || this.wallet.points_val == 0)) {
          this.$store.commit(this.updateCommitType, {
            field: "payment_method_id",
            value: 1,
            index: this.index,
          });
          this.$store.commit(this.updateCommitType, {
            value: "Cash",
            field: "payment_method",
            index: this.index,
          });
          this.showError("You don’t have sufficient qpoints");
          this.$forceUpdate();
          return;
        }
        if (this.isPosPayment) {
          if (this.wallet.points_val > this.posTotal) {
            amount = this.posTotal;
          } else {
            amount = parseFloat(this.wallet.points_val.toFixed(2));
          }
        } else {
          if (this.wallet.points_val > this.invoice.total) {
            amount = this.invoice.total;
          } else {
            // amount = this.wallet.points_val;
            amount = parseFloat(this.wallet.points_val.toFixed(2));
          }
        }
       

        this.$store.commit(this.updateCommitType, {
          field: "amount",
          value: amount,
          index: this.index,
        });
      }

      if (field == "payment_method") {
        this.$store.commit(this.updateCommitType, {
          value: parseInt(data.id),
          field: "payment_method_id",
          index: this.index,
        });
        this.$store.commit(this.updateCommitType, {
          value: data.name,
          field: "payment_method",
          index: this.index,
        });
      } else {
        if (field == "amount" && isNaN(data)) {
          data = null;
        } else if (field == "amount") {
          data = parseFloat(data);
        }
        if (field == "card_type_id") {
          this.creditCardType = data;
        }
        if (field == "card_number") {
          data = data.replaceAll("-", "");
        }
        this.$store.commit(this.updateCommitType, {
          field: field,
          value: data,
          index: this.index,
        });
      }
      if (field == "payment_method") {
        setTimeout(() => {
          this.$emit("validate");
        }, 2);
      }
    },

    voucherSelection(event) {
      this.fieldChange("payment_code", event.voucher_code);
      event.voucher_code ? (this.voucherAmt = event.current_balance) : "";
    },
    authorizationCodeRule() {
      const rules = [];
      if (this.salesConfig && this.salesConfig.card_payment === 0) {
        return rules;
      }
      const rule = (v) => !!v || "Authorization Code is required";
      rules.push(rule);
      return rules;
    },
    cardTypeRule() {
      const rules = [];
      if (this.salesConfig && this.salesConfig.card_payment === 0) {
        return rules;
      }
      const rule = (v) => !!v || "Card Type is required";
      rules.push(rule);
      return rules;
    },
    cardNumberRule() {
      const rules = [];
      if (this.salesConfig && this.salesConfig.card_payment === 0) {
        return rules;
      }
      if (
        this.$store.getters.userInfo &&
        this.$store.getters.userInfo.venue_id != 5 &&
        this.$store.getters.userInfo &&
        this.$store.getters.userInfo.venue_id != 6
      ) {
        const rule = (v) => !!v || "Card number is required";
        rules.push(rule);

        rules.push(
          (v) =>
            v.length >=
              (this.creditCardType
                ? this.creditCardType == 6 || this.creditCardType == 7
                  ? 19
                  : this.creditCardType == 8
                  ? 18
                  : 4
                : 4) ||
            `Please enter valid ${
              this.creditCardType
                ? this.creditCardType == 6 || this.creditCardType == 7
                  ? 16
                  : this.creditCardType == 8
                  ? 15
                  : 4
                : 4
            } number`
        );
        rules.push(
          (v) => /^(\d+-?)+\d+$/.test(v) || "Please enter valid number"
        );
      }
      return rules;
    },
  },
};
</script>

<style></style>
