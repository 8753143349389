<template>
  <div>
    <v-dialog
      v-model="showModal"
      width="750"
      @input="$emit('close')"
      persistent
    >
      <v-card-actions class="headline">
        <div class="headline">
          <span class="text-capitalize">Available Wallet Products </span>
        </div>
      </v-card-actions>
      <v-card>
        <v-card-text>
          <v-container>
            <div style="border: 1px solid #00000042">
              <div>
                <v-row
                  class="text-center"
                  style="background: #f2f2f2; margin: 0"
                >
                  <v-col cols="1"
                    ><template>
                      <v-icon class="pt-3 text-black" small
                        >mdi-checkbox-multiple-marked-outline</v-icon
                      >
                    </template></v-col
                  >
                  <v-col cols="8"
                    ><p class="pt-3 text-black"><b>Name</b></p></v-col
                  >
                  <v-col cols="3"
                    ><p class="pt-3 text-black"><b>Quantity</b></p></v-col
                  >
                </v-row>
              </div>

              <div style="width: 100%" v-if="products && products.length > 0">
                <v-row
                  v-for="(product, index) in products"
                  :key="index"
                  class="text-center"
                  style="border-top: 1px solid #00000042; margin: 0"
                >
                  <v-col cols="1"
                    ><v-checkbox
                      dense
                      v-model="selectedProducts"
                      :value="product.id"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="8"
                    ><p class="mb-0 text-black" style="margin-top: 10px">
                      {{ product.name }}
                    </p></v-col
                  >
                  <v-col cols="3">
                    <v-text-field
                      dense
                      hide-details
                      v-if="selectedProducts.includes(product.id)"
                      :disabled="!selectedProducts.includes(product.id)"
                      label="Quantity"
                      outlined
                      type="number"
                      min="1"
                      :key="product.id"
                      :max="product.quantity"
                      v-model="product.required_quantity"
                      v-on:keypress="NumbersOnly"
                      @change="QuantityRule(product)"
                    ></v-text-field>
                    <v-btn outlined v-else>{{ product.quantity }}</v-btn></v-col
                  >
                </v-row>
              </div>

              <div v-else>
                <v-row
                  ><v-col cols="12" class="text-center mt-2"
                    ><h3>No Applicable Products in wallet</h3></v-col
                  ></v-row
                >
              </div>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="closeDialogue"
            >Close</v-btn
          >

          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="redeemProducts"
            >Redeem</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    showModal: { type: Boolean, default: false },
    products: { type: Array, default: () => [] },
    customer_id: { type: Number, default: null },
    invoice_id: { type: Number, default: null },
    totalAmount: { type: Number, default: null },
  },
  data() {
    return {
      selectedProducts: [],
      productsQuantity: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    closeDialogue() {
      this.$emit("close");
    },
    redeemProducts() {
      this.showLoader("Redeeming...");

      let data = this.products.filter((product) =>
        this.selectedProducts.includes(product.id)
      );
      if (data.length <= 0) {
        this.showError("Select something to redeem");
        this.hideLoader();
        return;
      }

      let formData = new FormData();
      formData.append(`customer_id`, this.customer_id);
      formData.append(`invoice_id`, this.invoice_id);
      formData.append(`products`, JSON.stringify(data));

      this.$http
        .post("venues/customers/products-wallet/redeem-wallet", formData)
        .then((response) => {
          if (response.status == 200) {
            this.selectedProducts = [];
            this.hideLoader();
            this.$emit("refreshWallet");
            this.showLoader("Closing...");
            if (this.totalAmount < response.data.data) {
              this.showError(
                "Unable to redeem, Trying to redeem more than invoice value"
              );
              this.hideLoader();
            } else {
              this.showSuccess("Redeemed successfully!");
              this.hideLoader();
              this.$emit(
                "updateProductWalletRedeem",
                response.data.data,
                response.data.products_redeemed
              );
            }
            this.closeDialogue();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    QuantityRule(product) {
      if (product.required_quantity) {
        if (product.required_quantity > product.quantity) {
          this.showError("Not enough quantity in wallet");
          product.required_quantity = product.quantity;
          this.$forceUpdate();
        }
      } else {
        product.required_quantity = product.quantity;
      }
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls__input {
  margin: auto !important;
}
.text-black {
  color: black;
  font-size: 14px !important;
}
</style>
