<template>
  <div>
    <v-dialog
      v-model="paymentDialog"
      :max-width="'60%'"
      @input="close"
      scrollable
      persistent
    >
      <v-card tile class="payment-model">
        <v-card-text>
          <v-row class="pt-6">
            <v-col md="7">
              <v-card outlined>
                <v-card-title class="fw-700 heading-title"
                  ><span>REFUND METHOD</span></v-card-title
                >
                <v-divider></v-divider>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-card outlined tile>
                        <v-btn
                          block
                          style="background-color:#F2F2F2;"
                          class="fw-500 fs-14"
                          >Total {{ parseFloat(refundsTotal()) | toCurrency }} /
                          {{ amount | toCurrency }}</v-btn
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-form ref="form">
                        <v-row>
                          <v-col cols="12" md="12" v-if="refundInvoiceData">
                            <template>
                              <v-card-text
                                v-for="(payment, index) in refund.payments"
                                :key="`p_${index}`"
                              >
                                <v-row>
                                  <v-col cols="6" md="6">
                                    <template>
                                      <v-select
                                        label="Payment Method"
                                        outlined
                                        background-color="#fff"
                                        item-text="name"
                                        item-value="id"
                                        @change="paymentChange($event, index)"
                                        return-object
                                        v-model="payment.payment_method"
                                        :items="paymentMethods"
                                        required
                                        :rules="[
                                          (v) =>
                                            !!v || 'Payment Method is required',
                                        ]"
                                      ></v-select>
                                    </template>
                                  </v-col>
                                  <v-col
                                    md="6"
                                    v-if="
                                      payment.payment_method &&
                                        payment.payment_method.name == 'Card'
                                    "
                                  >
                                    <v-select
                                      label="Card Type"
                                      outlined
                                      background-color="#fff"
                                      item-text="name"
                                      item-value="id"
                                      v-model="payment.card_type_id"
                                      required
                                      :rules="[
                                        (v) => !!v || 'Card Type is required',
                                      ]"
                                      :items="cardTypes"
                                    ></v-select>
                                  </v-col>
                                  <v-col
                                    md="6"
                                    v-if="
                                      payment.payment_method &&
                                        payment.payment_method.name == 'Card'
                                    "
                                  >
                                    <v-text-field
                                      outlined
                                      required
                                      background-color="#fff"
                                      v-model="payment.payment_code"
                                      :rules="[
                                        (v) =>
                                          !!v ||
                                          'Authorization Code is required',
                                      ]"
                                      label="Authorization Code"
                                    ></v-text-field
                                  ></v-col>
                                  <v-col cols="6" md="6">
                                    <template>
                                      <v-text-field
                                        outlined
                                        background-color="#fff"
                                        :value="payment.amount"
                                        v-model="payment.amount"
                                        label="Payment Amount"
                                        :prefix="currencyCode"
                                        append-icon="mdi-cash-plus"
                                        @click:append="autoFillAmount(index)"
                                        required
                                        :rules="[
                                          (v) => {
                                            if (v <= 0)
                                              return 'Price should greater than zero';
                                            if (v && isNaN(v))
                                              return 'Price must be Number';
                                            if (!v) return 'Price is required';
                                            return true;
                                          },
                                        ]"
                                      ></v-text-field>
                                    </template>
                                  </v-col>
                                </v-row>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="warning"
                                    outlined
                                    v-if="index != 0"
                                    @click="refund.payments.splice(index, 1)"
                                  >
                                    Remove
                                    <v-icon small right
                                      >mdi-delete</v-icon
                                    ></v-btn
                                  >
                                </v-card-actions>
                              </v-card-text>
                            </template>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions
                  v-if="
                    !payments.find((x) => x.payment_method == 'Complementary')
                  "
                >
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <!-- <v-btn
                    class="text-blue text-underline"
                    text
                    v-if="isShowAddNewPaymentBtn"
                    @click="addNewPaymentMethod"
                    :prefix="currencyCode"
                    >+ Add New Method</v-btn
                  > -->
                </v-card-actions>
              </v-card>
              <div class="bottom-fix" style="margin-top:60px; bottom:7px">
                <v-row>
                  <v-col cols="12">
                    <v-btn outlined class="" absolute bottom @click="close()"
                      >Close</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col md="5">
              <v-card outlined class="order-summary">
                <v-card-title class="fw-700 heading-title">
                  <span>REFUND REASON</span>
                </v-card-title>
                <div class="cart-text-bottom">
                  <v-row dense>
                    <v-col cols="12" class="pa-6">
                      <v-textarea
                        v-model="refundNote"
                        label="Refund Reason"
                        rows="6"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </div>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-row dense class="pa-2">
                    <v-col cols="12"
                      ><v-btn
                        block
                        class="block white--text blue-color large"
                        @click="refundInvoice"
                        >Refund</v-btn
                      ></v-col
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <confirm-model
          v-bind="confirmModel"
          @confirm="confirmActions"
          @close="confirmModel.id = null"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  watch: {
    invoiceId: {
      immediate: true,
      handler(val) {
        if (val == "" || val == null) {
          this.paymentDialog = false;
        } else {
          this.paymentDialog = true;
        }
      },
    },
  },
  mounted() {
    if (this.$store.getters.getCardTypes.status == false) {
      this.$store.dispatch("loadCardTypes");
    }
    this.$store.dispatch("loadPaymentMethods", "refund");
     if (this.workshopRefundAmount) {
      this.$forceUpdate();
    }
  },
  props: {
    amount: { type: Number, default: null },
    invoiceId: { type: Number, default: null },
    refundInvoiceData: { type: Object, default: () => {} },
    type: { type: String, default: "full" },
    workshopRefundAmount: { type: Number, default: null },
    workshopCustomerAttendanceId: { type: Number, default: null },
    repeatRefundAmount: { type: Number, default: null },
    repeatBookingdata: { type: Array, default: null },
    is_settled: { type: Number, default: 0 },
  },
  destroyed() {
    this.$store.dispatch("loadPaymentMethods", "normal");
  },
  computed: {
    payments() {
      return this.$store.getters.getInvoicePayments;
    },
    paymentTotal() {
      return this.$store.getters.getInvoicePaymentTotal;
    },
    paymentMethods() {
      let p_methods = this.$store.getters.getPaymentMethods.data.filter(
        (ele) => {
          return ele.name !== "Card";
        }
      );
      if (!this.refundInvoiceData.isShowOnlineRefund || this.is_settled != 0) {
        p_methods = p_methods.filter(function(value) {
          return value.id != 17;
        });
      }
      return p_methods;
    },
  },
  data() {
    return {
      confirmModel: {},
      refundNote: null,
      refund: { payments: [{}] },
      isShowAddNewPaymentBtn: true,
    };
  },
  methods: {
    paymentChange(val, index) {
      /** payment_method_id = 10 means card payment
       *  We restrict the same payment methods select multiple time except card
       */
      let fIndex = this.refund.payments.findIndex(
        (element) =>
          element.payment_method_id === val.id &&
          element.payment_method_id !== 10
      );
      if (fIndex >= 0) {
        this.refund.payments[index].payment_method = null;
        this.showError(`${val.name} is already selected`);
        return;
      }
      /** payment_method_id = 17 means Online payment method id and type refund
       *  If we select online payment method for refund we will restrict to select other payment method
       *  Online payment refund only applicable for those order which are created by Mobile or Customer Portal
       */
      if (val.id === 17) {
        this.refund.payments = [{}];
        let refundPaymentMethods = this.$store.getters.getPaymentMethods.data;
        refundPaymentMethods = refundPaymentMethods.filter(function(value) {
          return value.id == 17;
        });
        this.refund.payments[0] = {
          amount: this.total,
          payment_method_id: val.id,
          method: val.name,
          payment_method: refundPaymentMethods[0],
        };
        this.isShowAddNewPaymentBtn = false;
      } else {
        this.isShowAddNewPaymentBtn = true;
        this.refund.payments[index].payment_method_id = val.id;
        this.refund.payments[index].payment_method.name = val.name;
        this.refund.payments[index].method = val.name;
        this.$forceUpdate();
      }
    },
    totalPaymentAmount() {
      if (this.payments && this.payments.length) {
        let totalPayment = this.amount;

        let findComplementary = this.payments.find(
          (x) => x.payment_method == "Complementary"
        );

        if (findComplementary) {
          let totalAfterComplementary = this.payments
            .filter((item) => item.payment_method != "Complementary")
            .reduce((a, b) => a - b.amount, 0);
          totalPayment = totalAfterComplementary;
          return Math.abs(totalPayment);
        }
        return parseFloat(totalPayment).toFixed(2);
      }
      return 0;
    },

    refundsTotal() {
      return this.refund.payments.reduce(
        (a, b) => a + parseFloat(b.amount ? b.amount : 0),
        0
      );
    },

    autoFillAmount(index) {
      this.refund.payments[index].amount = null;
      let amount = this.refund.payments[index].amount;
      let balance =
        this.totalPaymentAmount() -
        this.refundsTotal() +
        (amount > 0 ? amount : 0);
      this.refund.payments[index].amount = balance.toFixed(2);
      this.$forceUpdate();
    },
    refreshFormValidation() {
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.$el.removeAttribute("novalidate");
      this.$refs.form.validate();
      this.$refs.form.$el.setAttribute("validate", "validate");
    },
    removePaymentMethod(data) {
      this.confirmModel = {
        id: data.index,
        title: "Do you want to remove this Payment Method?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "remove_payment_method",
        data: { id: data.id, index: data.index },
      };
    },
    // cancel() {
    //   this.confirmModel = {
    //     id: this.invoiceDetails.id,
    //     title: "Do you want to cancel this Invoice?",
    //     description:
    //       "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
    //     type: "cancel",
    //   };
    // },
    confirmActions(data) {
      if (data.type == "remove_payment_method") {
        setTimeout(() => {
          this.$store.commit("removePaymentMethod", data.data.index);
        });
      }
      //   else if (data.type == "cancel") {
      //     this.$emit("cancel");
      //   }
      this.confirmModel.id = null;
    },
    addNewPaymentMethod() {
      if (this.refundsTotal()) {
        this.refund.payments.push({ amount: 0 });
      }
    },
    close() {
      this.$emit("close");
    },
    refundInvoice() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader("Refund processing....");

      if (this.amount != 0) {
        let totalPayment = this.refund.payments.reduce(
          (a, b) => a + parseFloat(b.amount),
          0
        );
        if (totalPayment == 0) {
          this.hideLoader();
          this.showError("Please enter amount!");
          return;
        }
        if (
          this.totalPaymentAmount() > totalPayment ||
          this.totalPaymentAmount() < totalPayment
        ) {
          this.hideLoader();
          this.showError("Payment amount not matching with order amount!");
          return 0;
        }
      }

      let data = {
        amount: this.amount,
        refund: this.refund,
        reason: this.refundNote,
        refundInvoiceData: this.refundInvoiceData,
      };
      let url = "";
      if (this.repeatRefundAmount && this.repeatBookingdata) {
        url = "venues/facilities/bookings/repeat/cancel-and-refund";
        data.booking_ids = this.repeatBookingdata;
        data.payments = this.refund && this.refund.payments?this.refund.payments:null;
      }else if (this.workshopRefundAmount && this.workshopCustomerAttendanceId) {
        url = "venues/workshops/schedules/booking/refund-class";
        data.workshop_customer_attendance_id = this.workshopCustomerAttendanceId;
        data.payments = this.refund && this.refund.payments?this.refund.payments:null;
      } else {
        if (this.type == "partial") {
          url = `venues/invoices/refund-partial/${this.invoiceId}`;
        } else {
          url = `venues/invoices/refund-full/${this.invoiceId}`;
        }
      }
      this.showLoader("Loading..");
      this.$http
        .post(url, data)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.showSuccess("Invoice Refunded");
            this.$emit("close");
            this.$emit("refund", response.data.data);
          } else {
            this.showError("Unable to refund");
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
  },
};
</script>
