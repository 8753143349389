var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"outlined":"","background-color":"#fff","items":_vm.voucherEntries,"loading":_vm.isVoucherLoading,"search-input":_vm.search,"label":"Voucher Code","placeholder":"Voucher Code","item-text":"voucher_code","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.onVoucherSelect},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('span',[_c('span',[_vm._v("CODE: "+_vm._s(item.voucher_code))]),_c('v-chip',_vm._g(_vm._b({staticClass:"white--text ml-2",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[(_vm.redeem_balance > 0)?_c('span',[_vm._v("Bal. "+_vm._s(_vm._f("toCurrency")(parseFloat(_vm.redeem_balance))))]):_c('span',[_vm._v("Bal. "+_vm._s(_vm._f("toCurrency")(parseFloat(item.current_balance))))])])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"teal","tile":"","width":"120"}},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(parseFloat(item.current_balance)))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.voucher_code)}}),(item.expiry_date != null)?_c('v-list-item-subtitle',[_vm._v("Ex. "+_vm._s(_vm._f("dateformat")(item.expiry_date))+" ")]):_vm._e()],1)]}}]),model:{value:(_vm.voucher),callback:function ($$v) {_vm.voucher=$$v},expression:"voucher"}})}
var staticRenderFns = []

export { render, staticRenderFns }