<template>
  <div>
    <v-dialog
        v-model="invoiceDialogue"
        @input="closeOrderDetails"
        :max-width="950"
        class="no-shadow"
        scrollable
        persistent
    >
      <v-card ref="recieptPrint" :max-width="800" style="margin:0 auto;">
        <slot name="carosal"></slot>
        <v-card-text class="pa-0 ma-0">
          <div class="receipt_details">
            <v-row class="ma-0">
              <v-col class="text-left pt-5" cols="9" md="9">
                <div class="invoice-title">
                  <v-btn icon>
                    <SvgIcon>
                      <template v-slot:icon>
                        <ReceiptIcon />
                      </template>
                    </SvgIcon>
                  </v-btn>
                  <span v-if="invoiceData.status && invoiceData.status.name == 'Unpaid'">
                    Invoice #{{ invoiceData.invoice_seq_no }}
                  </span>
                  <span v-else-if="invoiceData.status && (invoiceData.status.name == 'Paid' || invoiceData.status.name == 'Complementary')">
                    Tax Invoice #{{ invoiceData.receipt_no }}
                  </span>
                  <span v-else-if="invoiceData.status && invoiceData.status.name == 'Void'">
                    (Void)#{{ invoiceData.receipt_no? invoiceData.receipt_no: invoiceData.invoice_seq_no}}
                  </span>
                  <span v-else-if="invoiceData.status && invoiceData.status.name == 'Cancelled'">
                    Tax Invoice #{{invoiceData.receipt_no? invoiceData.receipt_no: invoiceData.invoice_seq_no}}
                  </span>
                  <span v-else-if="invoiceData.status && invoiceData.status.name == 'Refund'">Tax Credit Note #{{ invoiceData.receipt_no }}</span>
                  <span
                      v-if="( invoiceData.invoice_status.name != 'Pending' && (invoiceData.invoice_status.name == 'Rescheduled' || invoiceData.invoice_status.name == 'Partial Reschedule')) ||
                        invoiceData.invoice_status.name == 'Partial Cancel' ||
                        invoiceData.invoice_status.name == 'Partial Refund' ||
                        invoiceData.invoice_status.name == 'Refund' ||
                        invoiceData.invoice_status.name == 'Cancelled' ||
                        invoiceData.invoice_status.name == 'Reservation Cancel'
                    ">({{ invoiceData.invoice_status.name }})</span>
                  <span class="display-block">{{ invoiceData.paid_at? invoiceData.paid_at: invoiceData.created_at_invoice | timeStamp }}</span>
                </div>
              </v-col>
              <v-col class="text-right pt-5" cols="3" md="3">
                <v-btn icon @click="openMultiInvoice(invoiceData.id)"  v-if="invoiceData.batch_id" title="Multi venue invoice">
                  <SvgIcon>
                    <template v-slot:icon>
                      <InvoiceIcon />
                    </template>
                  </SvgIcon>
                </v-btn>
                <v-btn icon title="Send Email"  v-if="invoiceData.customer"  @click="mailReciept()" class="ml-2 mr-2">
                  <SvgIcon>
                    <template v-slot:icon>
                      <EmailIcon />
                    </template>
                  </SvgIcon>
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on"  title="Print Invoice">
                      <SvgIcon>
                        <template v-slot:icon>
                          <PrintIcon />
                        </template>
                      </SvgIcon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="downloadPdf('pos')">
                      <v-list-item-title>Print POS</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="downloadPdf('a4')">
                      <v-list-item-title>Print A4</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-divider :color="deviderColor()"></v-divider>
          </div>
          <div class="receipt_details_body pa-4">
            <div class="new-venue-info-table">
              <div class="d-flex flex-column border-bottom  pt-2 pb-2"  v-if="invoiceData.customer">
                <div class="d-flex justify-space-between ">
                  <div class="">
                    <span class="v-title">Customer Name: </span>
                    <span class="v-name d-block">{{ invoiceData.customer?invoiceData.customer.name:'-' }}</span>
                  </div>
                  <div class="">
                    <span class="v-title">Mobile: </span>
                    <span class="v-name d-block">{{ invoiceData.customer && invoiceData.customer.customer_contact? invoiceData.customer.customer_contact.mobile: "-" }}</span>
                  </div>
                  <div class="">
                    <span class="v-title">Email: </span>
                    <span class="v-name d-block">{{ invoiceData.customer && invoiceData.customer.customer_contact? invoiceData.customer.customer_contact.email: "-" }}</span>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column venue-details pa-3 ">
                <div class="d-flex justify-space-between">
                  <div class="  pb-1">
                    <span class="v-title">Venue Name: </span>
                    <span class="v-name">{{ invoiceData.venue_name?invoiceData.venue_name:$store.getters.venueInfo.name }}</span>
                  </div>
                  <div class="  pb-1">
                    <span class="v-title">Channel: </span>
                    <span class="v-name">{{ getChannel }}</span>
                  </div>

                </div>
                <div class="d-flex justify-space-between pt-1">
                  <div class="  pb-1">
                    <span class="v-title">Cashier: </span>
                    <span class="v-name">{{ invoiceData.cashier? invoiceData.cashier.name: invoiceData.invoice_by? invoiceData.invoice_by.name: "" }}</span>
                  </div>

                  <div class="  pb-1" v-if="getOrderPaymentMethods() !== 'NA'">
                    <span class="v-title">Payment: </span>
                    <span class="v-name">{{ getOrderPaymentMethods() }}</span>
                  </div>

                </div>
              </div>
            </div>
            <div class="d-flex flex-column pa-0  venue-info-table pb-2">
              <div class="d-flex justify-space-between ">
                <span class="v-title">Invoice Items:</span>
              </div>
            </div>

            <InvoiceOrderTable
                @openOrderDetails="openOrderDetails"
                :orders="invoiceData.items"
                :isInvoiceHasOrderDiscount="isInvoiceHasOrderDiscount"
            ></InvoiceOrderTable>
            <table class="receipt_details_total_table">
              <tr v-if="invoiceData.promotion_name">
                <td class="space"></td>
                <td class="space"></td>

                <td>Promotion:</td>
                <td>
                  {{ invoiceData.promotion_name }}
                  {{ invoiceData.promotion_code? "(" + invoiceData.promotion_code + ")": "" }}
                </td>
              </tr>
              <tr v-if="invoiceData.discount">
                <td class="space"></td>
                <td class="space"></td>
                <td>Gross Total:</td>
                <td>{{ Number(invoiceData.discount.actual_total) | toCurrency }}</td>
              </tr>
              <tr v-if="invoiceData.discount">
                <td class="space"></td>
                <td class="space"></td>
                <td>Discounted Amount:</td>
                <td>{{ Number( (parseFloat(invoiceData.discount.actual_total) - parseFloat(invoiceData.total)).toFixed(2) ) | toCurrency }} </td>
              </tr>
              <tr v-if="invoiceData.tax">
                <td class="space"></td>
                <td class="space"></td>
                <td>Sub Total Exclusive Tax</td>
                <td>{{ Number(parseFloat(Math.abs(invoiceData.price)).toFixed(2)) | toCurrency }}</td>
              </tr>
              <slot name="special_field"></slot>
              <tr>
                <td class="space"></td>
                <td class="space"></td>
                <td v-if="invoiceData.tax">Tax Amount</td>
                <td v-if="invoiceData.tax">{{ Number(parseFloat(Math.abs(invoiceData.tax)).toFixed(2))| toCurrency }}</td>
              </tr>

              <tr>
                <td class="space"></td>
                <td class="space"></td>
                <th style="color:#112A45; font-weight:700;" class="upper-border">Grand Total</th>
                <th style="color:#112A45; font-weight:700;" class="upper-border">{{ Number(parseFloat(Math.abs(invoiceData.total)).toFixed(2)) | toCurrency }}</th>
              </tr>
              <tr v-if="credit && invoiceData.status_id == 4">
                <td class="space"></td>
                <td class="space"></td>
                <th style="color:#112A45; font-weight:700;" class="upper-border">Balance Due</th>
                <th style="color:#112A45; font-weight:700;" class="upper-border">{{ Number(parseFloat(Math.abs(credit)).toFixed(2)) | toCurrency }}</th>
              </tr>
            </table>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <div>
          <v-col class="notes_heading pa-3 pt-4" cols="12">
            <div class="border-bottom">
              <p style="line-height: 1px;font-size:14px;">Invoice Notes:
                <v-icon v-if="checkBackfillPermission($modules.sales.logs.slug)" small @click="editNote()" > mdi-pencil </v-icon>
              </p>
              <!-- <p class=" font-normal font-bold blue-text" style="line-height: 1px" v-if="!this.editInvoiceNote">{{invoiceData.invoice_notes}}</p> -->
              <v-text-field
                  v-model="invoiceData.invoice_notes"
                  append-icon="mdi-check"
                  clear-icon="mdi-close-circle"
                  clearable
                  type="text"
                  elevation="0"
                  @click:append="updateNote"
                  @click:clear="editNote()"
                  v-if="this.editInvoiceNote && checkBackfillPermission(
                    $modules.sales.logs.slug
                  )"
              ></v-text-field>
            </div>
          </v-col>
          <v-col class="notes" cols="12" md="12" v-if="!this.editInvoiceNote">
            <span>{{ invoiceData.invoice_notes }}</span>
          </v-col>
          <v-divider></v-divider>
        </div>
        <v-card-actions>
          <v-btn class="ma-2 text-capitalize" text @click="closeOrderDetails">Close</v-btn>
          <v-menu offset-y :key="`p_${invoiceData.id}`">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-if="checkWritePermission($modules.sales.paymentLink.slug) && invoiceData.status_id == 5 && (invoiceData.invoice_status_id == 11 || invoiceData.invoice_status_id == 12)"
                  class="ma-2 white--text blue-color"
                  text
                  v-on="on"
                  v-bind="attrs"
              >Email Payment Link
                <v-icon right dark>
                  mdi-menu-down
                </v-icon></v-btn
              >
            </template>
            <v-list  v-if="getCustomerList && getCustomerList.length" >
              <v-list-item style="cursor: pointer;"  v-for="(customer,index) in getCustomerList" :key="index" @click="emailLinkConfirmation(customer.id)">
                <v-list-item-title >{{  customer.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-2 white--text red-color"
              v-if="
                isSameVenue(invoiceData) &&
                invoiceData.invoice_status &&
                invoiceData.invoice_status_id != 13 &&
                invoiceData.invoice_status_id != 8 &&
                invoiceData.invoice_status_id != 18 &&
                invoiceData.invoice_status_id != 19 &&
                invoiceData.invoice_status_id != 23 &&
                invoiceData.status_id != 8 &&
                invoiceData.status_id != 22 &&
                invoiceData.status_id != 14 &&
                checkWritePermission($modules.sales.void.slug) &&
                invoiceData.is_settled == 0
            "
              text
              @click="voidTransactionModel"
          >Void</v-btn>
          <!-- <v-btn
            v-if="
              invoiceData.status_id == 4 &&
                (invoiceData.invoice_status_id == 11 ||
                  invoiceData.invoice_status_id == 12)
            "
            class="ma-2 outlined-button dark-back white--text"
            text
            @click="cancelInvoiceModel"
            >Cancel
          </v-btn> -->
          <v-btn
              v-if="isSameVenue(invoiceData) && invoiceData.status_id == 5 && (invoiceData.invoice_status_id == 11 || invoiceData.invoice_status_id == 12)"
              class="ma-2 outlined-button"
              @click="revertInvoiceModel"
              text
          >Revert</v-btn>

          <!-- !isPartailRefundBtnShow &&  -->
          <v-btn
              v-if="isSameVenue(invoiceData) && !isPartailRefundBtnShow && activeOrders > 0 &&
                invoiceData.status_id == 4  &&
                (invoiceData.invoice_status_id == 11 ||
                  invoiceData.invoice_status_id == 12 ||
                  invoiceData.invoice_status_id == 19 ||
                  // invoiceData.invoice_status_id == 17 ||
                  invoiceData.invoice_status_id == 18) &&
                invoiceData.total > 0 &&
                !invoiceData.credit_settlement &&
                !invoiceData.settled_invoice &&
                !credit
            "
              class="ma-2 white--text blue-color"
              text
              @click="refundTransactionModel"
          >Refund
          </v-btn>
          <v-menu offset-y v-if="isSameVenue(invoiceData) && checkWritePermission($modules.sales.refund.slug) && activeOrders > 0 && isPartailRefundBtnShow" :key="invoiceData.id">
            <template v-slot:activator="{ on, attrs }" >
              <v-btn
                  v-if="invoiceData.status_id == 4 &&
                    (
                      invoiceData.invoice_status_id == 11 ||
                      invoiceData.invoice_status_id == 12 ||
                      invoiceData.invoice_status_id == 19 ||
                      invoiceData.invoice_status_id == 18
                    ) &&
                    invoiceData.total > 0 &&
                    !invoiceData.credit_settlement &&
                    !credit
                "
                  class="ma-2 white--text blue-color"
                  text
                  v-on="on"
                  v-bind="attrs"
              >Refund
                <v-icon right dark>
                  mdi-menu-down
                </v-icon></v-btn
              >
            </template>
            <v-list>
              <v-list-item @click="refundTransactionModel">
                <v-list-item-title>Full Refund</v-list-item-title>
              </v-list-item>
              <v-list-item @click="refundPartialTransactionModel" v-if="isPartailRefundBtnShow">
                <v-list-item-title>Partial Refund</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
              v-if="invoiceData.status_id == 5 && (invoiceData.invoice_status_id == 11 || invoiceData.invoice_status_id == 12)"
              class="ma-2 white--text blue-color"
              text
              @click="openPayInvoice">
            Pay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-model
        v-bind="confirmInputModel"
        @confirm="confirmActions"
        @close="confirmInputModel.id = null"
    >
      <template v-slot:content v-if="confirmInputModel.type == 'void'">
        <v-textarea
            class="mt-3"
            v-model="voidNote"
            rows="3"
            label="Note"
            outlined
        ></v-textarea>
      </template>
    </confirm-model>
    <MultiInvoiceModel v-if="multiInvoiceDialog" :multiInvoiceDialog="multiInvoiceDialog" :multiInvoiceData="multiInvoiceData" @close="closeMultiInvoice" @payed="openPayedInvoice"/>

  </div>
</template>

<script>
import InvoiceOrderTable from "@/components/Invoice/InvoiceOrderTable.vue";
import MultiInvoiceModel from "@/components/Invoice/MultiInvoiceModel.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ReceiptIcon from "@/assets/images/misc/receipt-item.svg";
import InvoiceIcon from "@/assets/images/misc/invoice-icon.svg";
import EmailIcon from "@/assets/images/misc/invoice-email.svg";
import PrintIcon from "@/assets/images/misc/invoice-print.svg";
export default {
  components: {
    SvgIcon, ReceiptIcon,InvoiceIcon,EmailIcon,PrintIcon,
    MultiInvoiceModel,
    InvoiceOrderTable,
  },
  props: {
    invoiceId: { type: Number, default: null },
    invoiceData: { type: Object, default: null },
  },
  data() {
    return {
      editInvoiceNote:false,
      invoiceDialogue: false,
      invoice: {},
      confirmInputModel: {},
      voidNote: "",
      payments: [],
      isPartailRefundBtnShow: false,
      activeOrders: 0,
      isInvoiceHasOrderDiscount:false,
      multiInvoiceDialog: false,
      multiInvoiceData: [],
    };
  },
  computed: {
    getChannel(){
      if(this.invoiceData && this.invoiceData.source){
        let isPartner = this.invoiceData.is_partner?this.invoiceData.is_partner:0;
        let source = this.invoiceData.source;
        if(source){
          if(source === "B2C"){
            if(isPartner){
              return "Mobile App";
            }
            return "Website";
          }else if(source === 'Partner'){
            return "Q App";
          }
          return source;
        }
      }
      return "";
    },
    orderDetails() {
      return this.$store.getters.getOrderDetails;
    },
    credit() {
      let cr = 0;
      if (this.invoiceData && this.invoiceData.credit_invoice && this.invoiceData.credit_invoice.credit) {
        cr = this.invoiceData.credit_invoice.credit;
      }
      return cr;
      // let credits = this.payments.filter((item) => item.payment_method_id == 5);

      // if (credits.length > 0) {
      //   console.log("credits");

      //   console.log(credits);
      //   return credits[0];
      // } else {
      //   return null;
      // }
    },
    getCustomerList() {
      const customerList = [];
      if (this.invoiceData) {
        if (this.invoiceData.items && this.invoiceData.items.length > 0) {
          this.invoiceData.items.forEach(item => {
            if (item.order && item.order.customer) {
              if (item.order.customer.id) {
                let name = item.order.customer.first_name + ' ' + item.order.customer.last_name;
                customerList.push({ id: item.order.customer.id, name: name });
              }
            }
          });
        }
      }
      return customerList;
    },

    // payments() {
    //   return this.$store.getters.getInvoicePayments;
    // },
  },
  watch: {
    invoiceId: {
      immediate: true,
      handler(val) {
        if (val == "" || val == null) {
          this.invoiceDialogue = false;
        } else {
          this.invoiceDialogue = true;
          if(this.invoiceData && this.invoiceData.orders){
            this.checkActiveOrders(this.invoiceData.orders);
            this.checkInvoiceHasDiscountedOrder(this.invoiceData.items);
          }
          this.mapPayments();
        }
      },
    },
  },
  methods: {
    isSameVenue(invoiceData = null){
      let userVenueId = this.$store.getters.userInfo && this.$store.getters.userInfo.venue_id?this.$store.getters.userInfo.venue_id:null;
      if(invoiceData && invoiceData.venue_id === userVenueId){
        return true;
      }
      return false;
    },
    getOrderPaymentMethods() {
      if (this.payments.length) {
        var countryDetails = JSON.parse(localStorage.getItem("country")) || {};
        let payments = this.payments
            .filter((item) => {
              return item.payment_method;
            })
            .map(
                (method) =>
                    `${method.payment_method} (${
                        countryDetails.currency_code
                            ? countryDetails.currency_code
                            : "AED"
                    } ${Math.abs(method.amount)})`
            )
            .join(", ");
        if (payments) return payments;
        return "NA";
      }
      return "NA";
    },
    mapPayments() {
      this.payments = [];
      this.invoiceData.payments.forEach((payment) => {
        this.payments.push({
          id: payment.id,
          card_type_id: payment.card_type_id,
          payment_code: payment.payment_code,
          payment_method_id: payment.payment_method_id,
          amount: payment.total,
          payment_method: payment.payment_method.name,
          card_number: payment.card_number,
        });
      });
    },
    cardName(id) {
      if (this.$store.getters.getCardTypes.status == false) {
        this.$store.dispatch("loadCardTypes").then((response) => {
          let card = response.find((x) => x.id == id);
          if (card) {
            // console.log(card.name);
            return card.name;
          }
        });
      } else {
        let card = this.$store.getters.getCardTypes.data.find(
            (x) => x.id == id
        );
        if (card) {
          // console.log(card.name);
          return card.name;
        }
      }
      // console.log("none");
      return " ";
    },
    confirmActions(data) {
      if (data.type == "void") {
        this.voidTransaction();
      } else if (data.type == "revert") {
        this.undoInvoice();
      } else if (data.type == "cancel") {
        this.cancelInvoice();
      } else if (data.type == "refund") {
        this.refundInvoice();
      }else if (data.type == "payment_link") {
        // console.log("send payment link");
        this.$emit("sendPaymentLink", { customer_id: data.id,invoice_id:this.invoiceId });
      }
      this.confirmInputModel.id = null;
    },
    voidTransactionModel() {
      this.voidNote = this.invoiceData.invoice_notes;
      this.confirmInputModel = {
        id: this.invoiceId,
        title:
            "Do you want to void all paid/unpaid transactions associated with this invoice?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "void",
      };
    },
    editNote(){
      this.editInvoiceNote  = !this.editInvoiceNote;
    },
    editedNoteClear(){
      this.invoiceData.invoice_notes = null;
    },
    updateNote() {
      let data = {
        notes: this.invoiceData.invoice_notes
      };
      this.showLoader();
      this.$http
          .post("venues/invoices/note/" + this.invoiceData.id, data)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.showSuccess("invoice notes updated!");
              this.editInvoiceNote = false;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      //}
    },
    refundTransactionModel() {
      this.confirmInputModel = {
        id: this.invoiceId,
        title:
            "Do you want to refund all paid transactions associated with this invoice?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "refund",
      };
    },
    refundPartialTransactionModel() {
      this.$emit("openPartialRefundInvoice", this.invoiceData.id);
    },

    refundInvoice() {
      this.$http
          .get(`venues/invoices/refund-full/${this.invoiceId}`)
          .then((response) => {
            if (response.status == 200) {
              this.showSuccess("Refund amount retrieved");
              let data = {
                invoiceId: this.invoiceId,
                price: response.data.data,
                is_settled:response.data.is_settled
              };
              this.$emit("openFullRefundInvoice", data);
              this.$emit("close");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    voidTransaction() {
      this.showLoader("Voiding...");
      this.$http
          .post(`venues/invoices/void/${this.invoiceId}`, { note: this.voidNote })
          .then((response) => {
            if (response.status == 200) {
              this.showSuccess("Void success");
              this.$emit("close");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          })
          .finally(()=>{
            this.hideLoader();
          });
    },
    revertInvoiceModel() {
      this.confirmInputModel = {
        id: this.invoiceId,
        title: "Do you want to revert all items associated with this invoice?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you want to continue ?",
        type: "revert",
      };
    },
    undoInvoice() {
      this.showLoader("Please Wait..");
      this.$http
          .post(`venues/invoices/undo/${this.invoiceId}`)
          .then((response) => {
            if (response.status == 200) {
              this.hideLoader();
              this.showSuccess("Invoice Revert Successfully");
              this.$emit("close");
            } else {
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    closeOrderDetails() {
      this.$emit("close");
    },
    openOrderDetails(id) {
      this.$emit("openMainOrderDetails", id);
    },
    downloadPdf(type) {
      this.showLoader("Generating..");
      this.$http
          .get(`venues/invoices/pdf/${this.invoiceId}?type=${type}`, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status == 200) {
              this.printFile(response);
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    mailReciept() {
      this.showLoader("Mailing..");
      if (this.invoiceData.customer) {
        let url = "venues/invoices/mail/" + this.invoiceId;
        this.$http
            .get(url)
            .then((response) => {
              if (response.status == 200 && response.data.status == true) {
                this.showSuccess("Email Send Successfully.");
                this.hideLoader();
                // this.$emit("close");
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      }
    },

    openPayInvoice() {
      this.$emit("openPayInvoice", this.invoiceData.id);
    },
    cancelInvoiceModel() {
      this.confirmInputModel = {
        id: this.invoiceId,
        title: "Do you want cancel this invoice?",
        description:
            "This will cancel all the booking associated with this invoice. By clicking <b>Yes</b> you can confirm cancel operation",
        type: "cancel",
      };
    },
    cancelInvoice() {
      this.showLoader("Wait..");
      this.$http
          .post(`venues/invoices/cancel/${this.invoiceId}`)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.showSuccess("Invoice Canceled Successfully");
              this.$emit("close");
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    emailLinkConfirmation(customerId) {
      // console.log(this.invoiceId);
      this.confirmInputModel = {
        id: customerId,
        title: "Do you want to send payment link to customer email?",
        description:"By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "payment_link",
      };
    },
    checkActiveOrders(orders){
      if(orders && orders.length > 0){
        let ao = orders.filter(order => order.status_id === 4 && [11, 12, 17, 18].includes(order.order_status_id));
        this.activeOrders = ao.length;
        this.checkIsPartialAllow(ao);
      }
    },
    checkIsPartialAllow(orders){
      if (!orders || orders.length === 0) {
        this.isPartailRefundBtnShow = false;
        return false;
      }
      let res = orders.filter(order => order.status_id === 4 && [11, 12].includes(order.order_status_id));
      if (res.length === 0) {
        this.isPartailRefundBtnShow = false;
        return false;
      }

      if (res.length > 1) {
        this.isPartailRefundBtnShow = true;
        return true;
      }
      // If it's only one order
      let order = res[0];
      if (order.items && order.items.length > 1) {
        this.isPartailRefundBtnShow = true;
        return true;
      }

      // If it's only one order and its booking capacity-based or retail product
      if(order.items){
        for (let item of order.items) {
          if ([6, 9, 10].includes(item.product.product_type_id)) {
            if (item.product.product_type_id === 6 && item.quantity === 1) {
              this.isPartailRefundBtnShow = false;
              return false;
            } else {
              this.isPartailRefundBtnShow = true;
              return true;
            }
          } else {
            this.isPartailRefundBtnShow = false;
            return false;
          }
        }
      }

    },
    checkInvoiceHasDiscountedOrder(invoiceItems){
      let od = false;
      if(invoiceItems && invoiceItems.length > 0){
        invoiceItems.forEach( (item) => {
          if(item && item.order && item.order.discount && (item.order.discount.member_id || item.order.discount.promotion_id || item.order.discount.offer_pkg_id)){
            od = true;
          }
        });
      }
      this.isInvoiceHasOrderDiscount = od;
    },
    deviderColor(){
      if(this.invoiceData.status && (this.invoiceData.status.name == 'Paid' || this.invoiceData.status.name == 'Complementary')){
        return "#60A561";
      }else if(this.invoiceData.status && this.invoiceData.status.name == 'Void'){
        return "#E50000";
      }else if(this.invoiceData.status && this.invoiceData.status.name == 'Refund'){
        return "#FFA500";
      }else{
        return "#F1F1F1";
      }
    },
    openMultiInvoice(invoiceId = null) {
      this.multiInvoiceDialog = false;
      if (!invoiceId) {
        return this.showError("Invoice not found");
      }
      this.showLoader("Loading..");
      this.$http
          .get(`venues/invoices/batch-invoice/type/invoice/${invoiceId}`)
          .then((response) => {
            if (response.status == 200) {
              this.hideLoader();
              let data = response.data.data;
              this.multiInvoiceData = data;
              this.multiInvoiceDialog = true;
            }
          }).catch((error) => {
        this.hideLoader();
        this.errorChecker(error);
      });
    },
    closeMultiInvoice() {
      this.multiInvoiceData = [];
      this.multiInvoiceDialog = false;
    },
    openPayedInvoice(invId){
      this.openMultiInvoice(invId);
      this.closeOrderDetails();
    }
  },
};
</script>

<style scoped>
/deep/ .v-dialog {
  box-shadow: none !important;
}
.v-sheet.v-card {
  border-radius: 10px;
}
span.display-block {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-left: 40px;
}
.invoice-title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.venue-info-table {
  width: 100%;
  margin: 0px auto;
}
.receipt_details_top_header {
  background: linear-gradient(
      0deg,
      rgba(0, 182, 230, 1) 0%,
      rgba(3, 88, 119, 1) 100%
  );
}
.receipt_details_table {
  width: 99%;
  margin: 20px auto;
}

.receipt_details_table tr td {
  width: 50%;
  text-align: left;
  color: #000;
  padding: 3px;
}

.reciept_right {
  text-align: right;
}

.reciept_left {
  text-align: left;
}

.reciept_title {
  font-size: 12px;
  color: #002a89;
}
.receipt_details_total_table {
  text-align: right;
  width: 100%;
}

.receipt_details_total_table tr td {
  padding: 6px;
  color: #112a45;
}

.receipt_details_total_table .space {
  width: 186px;
}
.receipt_details_body.not-customer {
  padding-bottom: 0;
}
.new-venue-info-table .v-title,.venue-info-table .v-title {
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  color: #000;
  margin: 0px;
  padding: 0px;
}

.new-venue-info-table .v-name,.venue-info-table .v-name {
  font-size: 14px;
  padding: 10px;
  font-weight: 400;
  color: #000;
  margin: 0px;
  padding: 0px;
}
.venue-details {
  margin: 10px 0px;
  background: #f7f7f5;
  border: 1px solid #f7f7f5;
  border-radius: 10px;
}
.border-right-1 {border-right: 1px solid #e7e7e7;}

.venue-info-table tr:first-child td {
  padding-bottom: 10px;
}
.receipt_details_body {
  background: #fff;
  padding: 8px;
}
.reciept_details_total_table th {
  color: #112a45;
}

.inv-sm-circle {
  color: #112a45;
  background: rgba(17, 42, 69, 0.1);
}
.notes_heading {
  font-weight: 600;
  color: #112a45;
}

.notes.col-md-12.col-12 {
  padding-left: 20px;
  color: #112a45;
}
th.upper-border {
  padding-top: 2px !important;
  padding-right: 6px !important;
  border-top: dashed 1px black !important;
}
.no-shadow .v-dialog__content {
  box-shadow: none !important;
}

.v-dialog {
  box-shadow: none !important;
}

.v-dialog__content {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
</style>
