<template>
  <div>
    <v-dialog
      v-model="invoiceDialogue"
      :max-width="700"
      @input="closeOrderDetails"
      scrollable
      persistent
    >
      <v-card ref="recieptPrint">
        <v-card-text class="pa-0 ma-0">
          <div class="receipt_details">
            <v-row class="ma-0">
              <v-col class="text-left pt-5" cols="8" md="8">
                <div class="invoice-title">
                  <v-btn icon class="inv-sm-circle">
                    <v-icon>mdi-bookmark-outline</v-icon>
                  </v-btn>
                  Edit Invoice #{{ eInvoiceData.invoice_seq_no }}
                  <span class="display-block"
                    >{{ eInvoiceData.created_at | timeStamp }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
          <div class="receipt_details_body pa-3">
            <table class="pa-3 venue-info-table">
              <tr>
                <td colspan="4">
                  <div class="v-title vl">Invoice Items</div>
                </td>
              </tr>
            </table>

            <InvoiceOrderTable
              @openOrderDetails="openOrderDetails"
              :orders="eInvoiceData.items"
              :isEdit="true"
              @removeOrderFromInvoice="removeOrderFromInvoice"
            ></InvoiceOrderTable>
            <div style="text-align:center">
              <v-btn class="ma-2 sm-btn" size="small" @click="addNewOrderPopUp"
                >+ Add New Order</v-btn
              >
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn class="ma-2 close-btn" text @click="closeOrderDetails"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="eInvoiceData.status_id == 5"
            class="ma-2 white--text blue-color"
            text
            @click="updateInvoice"
            >UPDATE</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-model
      v-bind="confirmInputModel"
      @confirm="confirmActions"
      @close="confirmInputModel.id = null"
    >
    </confirm-model>
  </div>
</template>

<script>
import InvoiceOrderTable from "@/components/Invoice/InvoiceOrderTable.vue";
export default {
  components: {
    InvoiceOrderTable,
  },
  props: {
    invoiceId: { type: Number, default: null },
    eInvoiceData: { type: Object, default: null },
  },
  data() {
    return {
      invoiceDialogue: false,
      invoice: {},
      confirmInputModel: {},
      voidNote: "",
      orderId: null,
      removeOrders: [],
    };
  },
  computed: {
    orderDetails() {
      return this.$store.getters.getOrderDetails;
    },
  },
  watch: {
    invoiceId: {
      immediate: true,
      handler(val) {
        console.log("inv id: " + val);
        if (val == "" || val == null) {
          this.invoiceDialogue = false;
        } else {
          this.invoiceDialogue = true;
          this.getInvoiceDetails();
        }
      },
    },
  },
  methods: {
    confirmActions(data) {
      if (data.type == "remove") {
        this.eInvoiceData.items = this.eInvoiceData.items.filter(
          (order) => order.order_id !== this.orderId
        );
        this.removeOrders.push(this.orderId);
      }
      this.confirmInputModel.id = null;
    },
    removeOrderFromInvoiceModel() {
      this.confirmInputModel = {
        id: this.orderId,
        title:
          "Do you want to remove order#" +
          this.orderId +
          " associated with this invoice?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "remove",
      };
    },
    getInvoiceDetails() {
      this.invoice = {
        discount: 0,
        promotion_name: "",
        promotion_code: "",
        price: 450,
        tax_amount: 15,
        cashier: "Qasim",
        total: 465,
      };
    },

    closeOrderDetails() {
      this.$emit("close");
    },
    openOrderDetails(id) {
      this.$emit("openMainOrderDetails", id);
      // this.closeOrderDetails();
    },
    removeOrderFromInvoice(orderId) {
      this.orderId = orderId;
      this.removeOrderFromInvoiceModel();
    },
    updateInvoice() {
      this.showLoader("Wait..");
      console.log("remove order ids: ", this.removeOrders);
      var formData = new FormData();
      this.removeOrders.forEach((id, index) => {
        formData.append(`order_ids[${index}]`, id);
      });
      formData.append(`invoice_id`, this.invoiceId);
      formData.append(`action_type`, "remove");
      this.$http({
        method: "post",
        data: formData,
        url: `venues/invoices/update/${this.invoiceId}`,
      })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.showSuccess("Invoice Updated");
            if (response.data.data && response.data.data.orders.length > 0) {
              this.$emit("invoiceReload", this.invoiceId);
            } else {
              this.closeOrderDetails();
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },
    addNewOrderPopUp() {
      this.$emit("openOlModel", this.invoiceId);
    },
  },
};
</script>

<style scoped>
.v-sheet.v-card {
  border-radius: 10px;
}
span.display-block {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-left: 40px;
}
.invoice-title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.venue-info-table {
  width: 100%;
  margin: 0px auto;
}
.receipt_details_table {
  width: 99%;
  margin: 20px auto;
}
.receipt_details_table {
  width: 99%;
  margin: 20px auto;
}

.receipt_details_table tr td {
  width: 50%;
  text-align: left;
  color: #000;
  padding: 3px;
}

.reciept_right {
  text-align: right;
}

.reciept_left {
  text-align: left;
}

.reciept_title {
  font-size: 12px;
  color: #002a89;
}

.receipt_details_item_table {
  width: 100%;
  margin: 20px auto;
}

.receipt_details_item_table,
.receipt_details_item_table th,
.receipt_details_item_table td {
  border: 1px solid rgba(163, 163, 163, 0.534) !important;
  border-collapse: collapse;
}

.receipt_details_item_table tr td {
  font-size: 12px;
  text-align: center;
  padding: 3px;
}

.receipt_details_item_table tr th {
  text-align: center;
  padding: 3px;
}

.receipt_details_total_table {
  text-align: right;
  width: 100%;
}

.receipt_details_total_table tr td {
  padding: 6px;
}

.receipt_details_total_table .space {
  width: 186px;
}
.receipt_details_body {
  background: #fff;
  padding: 8px;
}
.receipt_details_total_table th {
  color: #112a45;
}

.v-title {
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  color: #000;
  margin: 0px;
  padding: 0px;
}

.v-name {
  font-size: 14px;
  padding: 10px;
  font-weight: 400;
  color: #000;
  margin: 0px;
  padding: 0px;
}
.venue-info-table tr:first-child td {
  padding-bottom: 10px;
}
.inv-sm-circle {
  color: #112a45;
  background: rgba(17, 42, 69, 0.1);
}
.text-black {
  color: #000;
}
</style>
